.contact-us
	@apply relative
	@media screen and ( max-width: 1280px)
		@apply pt-16
	@screen xl
		margin-top: -130px
	.block-wrap
		@apply m-0 p-0 bg-white overflow-hidden
		background: #FFFFFF
		border-radius: 20px
		box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.15)
		@screen xl
			border-radius: 50px
		>.col
			@apply m-0 p-0
			&:nth-child(2)
				@apply bg-primary-1D4789
	.wrap-form 
		.wpcf7-select
			border: 0
			border-bottom: 1px solid #d9d9d9!important
			border-radius: 0 !important
			padding: 0 !important
		.frm-btnwrap 
			justify-content: flex-start
			.frm-btn 
				input[type='submit']
					color: white
					border-color: $primary !important
					background: $primary !important
	.block-title
		font-size: r(40px)
		line-height: 1.1
		@apply mb-6
		@media screen and ( max-width: 1024px)
			font-size: r(36px)
			@apply mb-5
.block-wrap-form
	@apply p-10
	@screen xl
		padding: r(60px) r(75px) r(75px) r(75px)
	.wpcf7-spinner
		display: none !important
	.wrap-form
		br
			display: none !important
		.form-group
			label
				@apply text-222222  font-bold 
				font-size: r(16px)
			input[type='text'],textarea,input
				border: none !important
				border-bottom: 1px solid #D9D9D9 !important
				border-radius: 0 !important
				padding: 0 !important

			input[type='text'],input[type='tel'],input[type='email']
				height: 25px
			textarea
				height: r(70px)
		.frm-btnwrap
			.frm-btn
				&:hover
					&::after
						color: white
					input[type='submit']
						color: white
						border-color: $primary !important
						background: $primary !important
.block-wrap-address
	@apply p-10
	@screen xl
		padding: r(60px) r(30px) r(60px)  r(40px)
	@apply text-white relative
	&::after
		content: ''
		@apply block absolute pointer-events-none right-0 bottom-0 z-10  transition
		height: r(220px)
		width: r(290px)
		background: url('../img/icon/icon-3.png') 
		background-repeat: no-repeat
		background-size: 100% 100%
		background-position: center
	ul
		padding-left: 0
		list-style-type: none
	li
		strong
			@apply text-white font-bold   block
		span
			@apply text-white
		&:not(last-child)
			@apply mb-4
			@screen xl
				@apply mb-6
.contact-department
	.tab-navigation
		@apply mb-8
		ul
			@apply flex pl-0
			list-style-type: none
			li
				@apply w-full
				&:hover
					span
						@apply underline
				&.active
					a
						@apply bg-primary-ED202E 
						em,span
							@apply text-white
				a
					@apply w-full bg-background-F5F5F5  center-item px-4
					height: 50px
					@media screen and ( max-width: 576px)
						@apply px-6
					span,em
						@apply text-666666
						font-size: r(16px)
					span
						@apply uppercase font-bold ml-3
		@media screen and ( max-width: 576px)
			@apply overflow-x-auto
			ul
				@apply start-item whitespace-nowrap
	.headline
		@apply text-primary-1D4789 font-bold pl-5 start-item items-center bg-background-F5F5F5
		font-size: r(20px)
		height: r(50px)
		border-left: 3px solid #1D4789
		border-bottom: 1px solid #E5E5E5
	.table-wrap
		@apply mb-8
		@media screen and ( max-width: 768px)
			@apply overflow-auto
			table
				@apply whitespace-nowrap
		table
			@apply w-full
			
			thead
				th
					@apply px-5 text-333333 font-bold 
					font-size: r(16px)
					height: 50px
					border: 1px solid #E5E5E5
					&:first-child
						border-right: 0
					&:nth-child(2)
						border-left: 0
					*
						@apply  text-333333 font-bold 
						font-size: r(16px)
			tbody
				tr
					
					td
						@apply bg-white px-5 text-333333
						height: 50px
						font-size: r(14px)
						border: 1px solid #E5E5E5
						*
							font-size: r(14px)
							@apply text-333333
						&:first-child
							@apply text-primary-1D4789
							border-right: 0
							@screen xl
								width: r(285px)
						&:nth-child(2)
							border-left: 0
							@screen xl
								width: r(170px)
						&:nth-child(3)
							@screen xl
								width: r(170px)
						&:nth-child(4)
							@screen xl
								width: r(200px)
						&:nth-child(5)
							@screen xl
								width: r(240px)
					&:nth-child(odd)
						td
							background: #FAFAFA
.dealer-list
	.scroll-bar-wrap
		height: r(605px)
	&.contact-dealer
		@apply pt-0
		.dealer-wrap
			@apply relative
			&::after
				content: ''
				@apply block absolute pointer-events-none left-[20px] top-0 z-10  transition w-full bg-primary-ED202E
				height: 1px
		.dealer-map-wrapper
			@apply relative
			a
				height: r(605px) 
				@media screen and ( max-width: 1024px)
					height:  r(500px)
				@media screen and ( max-width: 576px)
					height: r(400px)
		