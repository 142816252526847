.product-detail-banner
	@apply bg-background-F5F5F5
	.item
		@apply relative
	.container
		@screen sm
			@apply left-1/2 top-1/2 translate-50 z-10 absolute
	.img-wrap
		a
			@media screen and ( max-width: 1280px)
				+img-ratio(490/1920)
			@screen xl
				@apply center-item
				height: r(490px)
				width: auto
				img
					width: 100%
					height: 100%
					object-fit: cover
	.title
		font-size: r(28px)
		line-height: 1
		font-family: 'UTM Avo'
		@apply text-primary-1D4789 font-bold block mb-5
		@screen lg
			font-size: r(40px)
	.caption
		@apply font-bold
		font-size: r(16px)
		@screen lg
			font-size: r(20px)
	.btn-video
		@apply bg-primary-1D4789 justify-start mt-8
		padding: 0 8px
		height: 50px
		border-radius: 30px
		width: 160px
		@screen sm
			@apply bg-white 
		@screen lg
			padding: 0 10px
		.icon
			height: 40px
			width: 40px
			@apply center-item bg-primary-1D4789 rounded-full
			@screen lg
				height: 50px
				width: 50px
			em
				font-size: 20px
				@apply text-white
				@screen lg
					font-size: 25px
		span
			@apply text-white text-[16px] font-bold uppercase ml-5
			@screen sm
				@apply text-primary-1D4789 
		@screen lg
			height: 60px
	.txt
		@apply text-center py-8
		@screen sm
			width: r(400px) 
		@screen xl
			margin-left: 7%
.product-information 
	.table-responsive
		@media screen and ( max-width: 1024px)
			@apply overflow-x-auto
			table
				@apply whitespace-nowrap
	table
		@apply w-full
		tr
			&:first-child
				td
					@apply font-bold  px-2 py-3  text-center
			td
				border: 1px solid #E5E5E5
				@apply  py-3 text-333333 px-2 font-medium text-center
				height: 50px
				font-size: r(16px)
				font-weight: 500 !important
				*
					@apply text-333333  block text-center
					font-weight: 500 !important
					
				&:first-child
					@apply font-bold text-222222  pr-5
	.tab-navigation 
		@apply relative z-50 mb-7
		@screen lg
			margin-top: -30px 
		box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08)
		ul
			list-style-type: none
			@apply gap-item p-0
			li
				@apply w-full
				&:hover
					a
						@apply underline text-primary-ED202E
				&.active
					a
						@apply bg-primary-ED202E text-white
			a
				height: r(80px)
				@apply text-999999 uppercase font-bold center-item bg-white w-full
				font-size: r(16px)
		@media screen and ( max-width: 576px)
			@apply overflow-x-auto
			ul
				@apply whitespace-nowrap
				a
					@apply px-4
	.product-catalog-contact 
		@apply pt-8
		.wrap-center
			@media screen and ( max-width: 768px)
				@apply col-item
		.btn
			minwidth: r(250px) 
			@media screen and ( max-width: 768px)
				@apply my-4
	.headline
		@apply text-center
		text-transform: none !important
		*
			text-transform: none !important
		&::after
			left: 50% !important
			transform: translateX(-50%)
	.block-wrap
		@apply py-5
		border-top: 1px solid #CCCCCC
		border-bottom: 1px solid #CCCCCC
	.row-custom
		@apply relative  my-0
		margin: 0 -20px
		@screen lg
			column-count: 2
		@screen lg
			&::after
				content: ''
				@apply block absolute pointer-events-none left-1/2 translate-x-50 bottom-0 z-10  transition bg-background-CCC h-full
				width: 1px
		.item-col
			box-sizing: border-box
			@apply my-0
			break-inside: avoid
			padding: 0 20px
			&:last-child
				.item
					border-bottom: 0
	.item
		@apply py-8 start-item w-full
		break-inside: avoid
		border-bottom: 1px solid #CCCCCC
		@media screen and ( max-width: 576px)
			@apply col-item
		.icon
			@apply center-item p-2 bg-primary-1D4789
			width: 60px
			height: 50px
			min-width: 60px
			transform: skew(-10deg)
			img
				width: 100%
				height: 100%
				object-fit: contain
				transform: skew(10deg)
		.txt
			@apply ml-5 text-333333 font-normal w-full
			font-size: r(16px)
			@media screen and ( max-width: 576px)
				@apply ml-0 mt-5
			p
				@apply mb-4
			*
				@apply  text-333333 font-normal
				font-size: r(16px)
			ul
				list-style-type: disc
				padding-left: 20px
				li
					font-size: r(16px)
					@apply text-333333 mb-1
					*
						font-size: r(16px)
						@apply text-333333
			img
				@apply w-full
			@screen xl
				@apply ml-6
			h3,h4
				@apply text-333333 font-bold block mb-4
				font-size: r(20px)
				@media screen and ( max-width: 576px)
					@apply text-center
.product-contact-form
	@apply bg-background-F5F5F5 py-14
	@screen xl
		padding: r(60px) 0 r(80px)
	.zone-desc
		font-size: r(20px)
		@apply mb-6
	.wrap-form
		.row
			margin-bottom: 20px
.product-chart
	@apply bg-background-E5E5E5 py-10
	.item
		@apply col-item
		@screen lg
			@apply start-item flex-row
		.icon
			@apply center-item
			height: 60px
			width: auto
			i,em
				font-size: 60px
				@apply text-666666
			img
				width: 100%
				height: 100%
				object-fit: contain
	.txt
		@apply  text-center
		@screen lg 
			@apply ml-10 text-left
	.headline
		@apply text-center my-4
		@screen lg
			@apply mb-1 text-left mt-0
.product-other
	.swiper-slide
		@apply h-auto