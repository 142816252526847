.research-detail
	.headline
		font-family: 'UTM Avo'
		font-size: r(40px)
	.table-wrap
		@apply mb-8
		@screen xl
			@apply mb-12
	table
		@apply w-full
		td
			@apply text-333333 py-3
			font-size: 1rem
			border-bottom: 1px solid #E5E5E5
			@screen xl
				font-size: r(16px)
	.img-src
		a
			+img-ratio(690/1230)
.research-other
	padding-bottom: r(80px)
	.block-title
		font-size: r(30px)