.block-title
	font-family: 'UTM Avo'
	@apply text-primary-1D4789 font-bold 
	font-size: r(36px)
	@media (min-width: 1024px)
		font-size: r(43px)
	@media (min-width: 1280px)
		font-size: r(48px)
		line-height: r(70px)
	&.white
		@apply text-white
	&.dark
		@apply text-222222
	&.t-30
		font-size: r(30px)
.headline
	@apply text-333333 font-bold transition
	font-size: 1rem
	*
		@apply text-333333 font-bold transition
		font-size: 1rem
	&.line
		@apply uppercase relative  pb-4 mb-6
		@screen xl
			@apply pb-6 mb-8
		&::after
			content: ''
			@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition bg-primary-ED202E
			height: 2px
			width: 80px
	&.normal
		@apply font-normal
		*
			@apply font-normal
	&.blue-1
		@apply text-primary-1D4789
		*
			@apply text-primary-1D4789
		&:hover
			@apply text-primary-ED202E
			*
				@apply text-primary-ED202E
	&.white
		@apply text-white
		*
			@apply text-white
		&:hover
			@apply text-primary-ED202E
			*
				@apply text-primary-ED202E
	&.t-18
		font-size: 1.125rem
		*
			font-size: 1.125rem
		@media screen and ( min-width: 1280px)
			font-size: r(18px)
			*
				font-size: r(18px)
	&.t-20
		font-size: 1.25rem
		*
			font-size: 1.25rem
		@media screen and ( min-width: 1280px)
			font-size: r(20px)
			*
				font-size: r(20px)
	&.t-22
		font-size: 1.375rem
		*
			font-size: 1.375rem
		@media screen and ( min-width: 1280px)
			font-size: r(22px)
			*
				font-size: r(22px)
	&.t-24
		font-size: 1.5rem
		*
			font-size: 1.5rem
		@media screen and ( min-width: 1280px)
			font-size: r(24px)
			*
				font-size: r(24px)