.global-breadcrumb
	@apply bg-background-F5F5F5
	@media screen and ( max-width: 576px)
		@apply py-2
	.rank-math-breadcrumb
		min-height: 50px

		>
			p
				@apply flex items-center flex-wrap  h-full
				justify-content: flex-start
				> *
					@apply flex justify-start
		span
			&.separator
				font-size: 0
				@apply mx-10px flex items-center
				&::before
					content: "|"
					@apply text-999999 font-light text-base leading-4 font-Awesome6
		a,span
			padding: r(10.5px) 0
			max-height: 40px
			@apply font-normal text-[14px] leading-4 text-999999 flex items-center
			&:first-child
				font-size: 0
				&::before
					content: "\f015"
					@apply font-Awesome6 font-light text-base leading-4 text-999999
		& + li
			@apply ml-15px pl-15px relative
			&::before
				content: "|"
				@apply font-Awesome6 absolute top-1/2 -translate-y-1/2 left-0 text-999999 text-base
