.fb-like
	height: 20px
	span
		height: 20px !important
	iframe
		height: 20px !important
		width: 126px !important
.input-group-text
	@apply hidden
.button
	transition: .3s all ease-in-out
	user-select: none
	*
		@media (min-width: 576px)
			white-space: nowrap

	.btn
		user-select: none
		cursor: pointer
		width: fit-content
		transition: .3s all ease-in-out
		position: relative
		display: flex
		justify-content: center
		align-items: center
		padding: r(7px) r(19.5px)
		border-radius: r(90px)
		border: r(1px) solid transparent
		height: r(50px)
		@apply text-white text-base transition-all
		&.btn-header
			height: r(30px)
			width: r(153px)
			@apply text-primary-1D4789 border-primary-1D4789 text-sm
			i
				@apply mr-10px text-base
			&:hover
				@apply bg-primary-ED202E border-transparent text-white
		&.has-input-submit
			@apply relative
			span.wpcf7-spinner
				left: calc( 100% + 30/1920*100rem )
				@apply absolute left-full top-1/2 -translate-y-1/2
			&::before
				content: attr(data-title)
				@apply normal-case
			input
				font-size: 0
				@apply absolute top-0 left-0 w-full h-full
.nav-arrow-prev,.nav-arrow-next
	@apply   z-10 transition  absolute top-1/2 translate-y-50    cursor-pointer center-item  rounded-full transition
	width: 30px
	height: 50px
	line-height: 50px
	@media screen and ( max-width:  1024px)
		@apply static mx-6
		transform: none
		width: 28px
		height: 32px
		line-height: 32px
	&:hover
		em
			@apply text-primary-ED202E
	em
		@apply  inline-block transition text-999999
		font-size: r(48px)
		height: r(48px)
		line-height: r(48px)
		@media screen and ( max-width: 1280px)
			font-size: 32px
			height: 32px
			line-height: 32px
.swiper-nav-wrap
	@media screen and ( max-width: 1024px)
		@apply center-item pt-6 mb-0
	&.white
		background: none
		.nav-arrow-next,.nav-arrow-prev
			em
				@apply text-white
			&:hover
				em
					@apply text-white
	&.grey
		background: none
		.nav-arrow-next,.nav-arrow-prev
			em
				@apply text-333333
	&.small
		.nav-arrow-next,.nav-arrow-prev
			em
				@apply text-333333
				font-size: 30px
				height: 30px
				line-height: 30px
				@media screen and ( max-width: 1024px)
					font-size: 24px
					height: 24px
					line-height: 24px
.nav-arrow-next
	right: -70px
	@media screen and ( min-width: 1024px) and ( max-width: 1440px)
		right: -40px
.nav-arrow-prev
	left:  -70px
	@media screen  and ( min-width: 1024px) and ( max-width: 1440px)
		left: -40px
.button-wrapper
	@apply flex gap-10px
.wrap-gap,.wrap-center,.wrap-left,.wrap-right,.ajax-center-wrap
	@apply flex items-center
	a
		&:not(:last-child)
			@apply mr-2
.wrap-center,.ajax-center-wrap
	@apply  justify-center
.wrap-left
	@apply justify-start
.wrap-right
	@apply justify-end
.wrap-gap
	@apply justify-between
.ajax-center-wrap
	@apply mt-8
	&.disble-btn
		@apply opacity-0 pointer-events-none
.btn
	span,em
		transition: .4s all  ease-in-out
	&.btn-default
		@apply bg-primary-1D4789 text-white h-[40px]  rounded-[26px] px-12
		@screen xl
			@apply h-[50px]
		span
			@apply text-[14px] text-white font-bold uppercase
			@screen xl
				@apply text-[16px]
		em
			@apply inline-block ml-2
			font-size: 14px
			height: 14px
			line-height: 14px
			@screen xl
				font-size: 16px
				height: 16px
				line-height: 16px
		&:hover
			@apply bg-primary-ED202E
			em
				transform: translateX(4px)
	&.btn-primary
		@apply h-[40px] bg-primary-1D4789 px-6
		border-radius: 26px
		border: 1px solid $blue-1
		span,em
			@apply text-white
			transition: .4s all  ease-in-out
		span
			font-size: r(16px)
			@apply font-bold uppercase ml-2 inline-block
		@screen xl
			@apply h-[50px] px-10
		&:hover
			@apply bg-white
			span,em
				@apply text-primary-1D4789
		&.white
			@apply bg-white
			span,em
				@apply text-primary-1D4789
			&:hover
				@apply bg-primary-1D4789
				span,em
					@apply text-white