.ovh
	overflow: hidden

#fixed-tool
	position: fixed
	right: calc(15/1920*100*1rem)
	transition: .3s all ease-in-out
	bottom: 5vh
	z-index: 50
	@apply pointer-events-none opacity-0
	&.active
		bottom: 10vh
		@apply pointer-events-auto opacity-100
	a
		display: flex
		justify-content: center
		align-items: center
		width: calc(40/1920*100*1rem)
		height: calc(40/1920*100*1rem)
		border-radius: 1000%
		cursor: pointer
		transition: .3s all ease-in-out
		@apply bg-primary-1D4789
		& + a
			margin-top: calc(10/1920*100*1rem)
		@media (min-width: 576px)
			width: calc(50/1920*100*1rem)
			height: calc(50/1920*100*1rem)
		em
			font-weight: 300
			font-size: r(24px)
			color: #fff
			transition: .3s all ease-in-out
		&:first-child
			background: rgba(#FFFFFF ,.5 )
			em
				color: #666666

		&:hover
			@apply bg-primary-ED202E
			em
				color: #fff

.desktop-show
	@apply hidden xl:block
.mobile-show
	@apply block xl:hidden

.search-overlay
	z-index: 1030
	top: var(--header-height)
	height: 40vh
	@apply bg-secondary-0C3E8A shadow-lg opacity-0 pointer-events-none transition-all fixed left-0 w-full flex items-center justify-center
	@media (min-width: 1280px)
		height: 50vh
	&.active
		@apply opacity-100 pointer-events-auto
		.searchbox
			transform: translateY(0%)
			@apply delay-100 opacity-100
		.close
			@apply opacity-100
	.close
		z-index: 2
		bottom: 100%
		width: r(40px)
		height: r(40px)
		@apply flex justify-center pointer-events-none absolute items-center opacity-0 transition-all duration-1000
		i
			font-size: r(30px)
			@apply text-white justify-center
	.searchbox
		transform: translateY(100%)
		@apply flex h-13 transition-all relative duration-1000 opacity-0
		input
			@apply bg-transparent border-b border-white w-[calc(100%-calc(56/1920*100*1rem))] text-3xl text-white  rounded-none placeholder:text-base placeholder:text-white font-light p-5
		button
			@apply pl-4 h-full transition-all
			em
				@apply w-13 text-xl text-white font-light border border-white rounded-sm flex items-center justify-center rounded-sm h-full transition-all
			&:hover,&:active
				em
					@apply bg-secondary-005A79
.tabslet-content
	@apply hidden

#overlay
	@apply fixed top-0 left-0 w-full h-full bg-transparent transition-all pointer-events-none xl:hidden
	&.active
		@apply bg-black/50 pointer-events-auto

.list-item-added
	list-style: disc
	padding-left: 15px
	@media (min-width: 1280px)
		padding-left: r(17px)
	li
		&::marker
			@apply text-222222

.icon-80
	width: r(60px)
	height: r(60px)
	@media (min-width: 1280px)
		width: r(80px)
		height: r(80px)
	i
		font-weight: 300
		font-size: r(25px)
		line-height: r(25px)
		@apply text-white
		@media (min-width: 1280px)
			font-size: r(32px)
			line-height: r(32px)

body
	&:not(.homepage)
		.form-group
			input[type="text"],input[type="tel"],input[type="email"],input[type="number"],input[type="date"],textarea,select
				background: #FFFFFF
				border: r(1px) solid #CEDEF8
				border-radius: r(5px)
				padding: r(7px) r(20px)
				padding: r(12.5px) r(20px)
				@apply text-base leading-18px placeholder:text-base w-full block

.wp-pagination
	ul
		@apply flex items-center justify-center list-none gap-10px
	li
		flex: 0 0 r(44px)
		height: r(44px)
		@apply font-bold text-lg leading-5  text-primary-1D4789 transition-all rounded-full border border-secondary-0C3E8A
		a
			@apply flex items-center justify-center w-full h-full
		&.active,&:hover
			@apply bg-primary-1D4789 border-transparent text-white

.loading-spinner
	top: 50%
	transform: translateY(-50%)
	@apply absolute w-full h-full flex items-center transition-all opacity-0 pointer-events-none z-20
	&::after
		content: ""
		@apply absolute w-10 h-10 rounded-full border-4 animate-spin border-gray-100 border-t-primary-ED202E left-1/2
	&.active
		@apply opacity-100

.no-post-found
	@apply flex items-center text-red-500 text-center justify-center
	i
		font-size: r(20px)
		@apply mr-10px
.top-zone-nav
	ul
		list-style-type: none 
		@apply pl-0 center-item
		li
			&.active
				a
					@apply bg-primary-ED202E text-white
			a
				@apply text-666666 font-bold uppercase bg-background-F5F5F5 px-6 center-item
				height: 50px
				font-size: 1rem
				@screen xl
					@apply px-8 text-[16px] 