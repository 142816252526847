.product-zone-nav
	@apply py-10
	@screen xl
		@apply pt-8  pb-10
	.toggle-nav
		@apply hidden
		@media screen and ( max-width: 576px)
			@apply gap-item px-5 py-3 bg-secondary-005A79
			height: 40px
			span
				@apply text-white font-bold uppercase
			em
				@apply text-white 
	
	.top-zone-nav
		ul
			@apply flex-wrap
			li
				@apply mx-[5px] mb-[10px]
		@media screen and ( max-width: 576px)
			border-radius: 15px
			@apply overflow-hidden
			ul
				@apply flex-col
				li
					@apply  w-full m-0
					a
						height: 36px
						font-size: r(16px)
						@apply justify-start  normal-case
	.top-zone-nav
		ul
			@media screen and ( max-width: 576px)
				@apply hidden
.product-banner
	@media screen and ( max-width: 1024px)
		@apply mb-10
	.swiper
		@apply p-2
	.item
		@apply overflow-hidden
		border-radius: 20px
		box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08)
	.img-wrap
		@apply relative
		a
			+img-ratio(410/1230)
	
	.txt
		@apply p-8
		@screen md
			@apply absolute top-1/2 translate-y-50 w-full h-full col-item px-7 right-0 pl-14
			align-items: flex-start
			max-width: 330px
	.headline
		@apply  mb-5 font-bold
		font-size: r(24px)
.product-list
	@apply pt-0 pb-16
	@screen xl
		@apply pt-[40px] pb-[80px]
.product-figure
	border: 1px solid #E5E5E5
	padding: r(30px) r(15px)
	transition: .4s all  ease-in-out
	@apply h-full
	.img-wrap
		@apply relative
	.brand
		@apply center-item mb-4
		height: 15px
		width: auto
		@screen xl
			@apply mb-6
		img
			width: 100%
			height: 100%
			object-fit: contain
	.img-src
		a
			+img-ratio(240/240,contain)
			
	.txt
		@apply text-center pt-5
		@screen xl
			@apply pt-6
	.caption
		@apply text-666666 mt-2
		*
			@apply text-666666
	&:hover
		border-color: $primary