.section
	padding: 35px 0
	@media(min-width: 768px)
		padding: 40px 0
	@media(min-width: 1024px)
		padding: 45px 0
	@media(min-width: 1280px)
		padding: r(60px) 0
.four-swiper,.trinity-swiper,.double-swiper,.single-swiper,.four-swiper-no-gap
	@apply relative
.fixed-side-nav
	@apply fixed right-[60px] opacity-0 pointer-events-none bottom-[60px]
	transition: .4s all  ease-in-out
	z-index: 99 
	@media screen and ( max-width: 1280px)
		@apply right-[15px] opacity-100
		bottom: 50px
	&.active
		@apply opacity-100 pointer-events-auto
	ul
		list-style-type: none
		@apply col-item pl-0
		li
			@apply mb-[10px]
			&:first-child
				em
					font-size: r(24px)
			&:last-child
				a
					@apply bg-primary-ED202E
					em
						@apply text-white
			a
				@apply center-item bg-white rounded-full overflow-hidden
				box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1)
				height: r(50px)
				width: r(50px)
				@screen xl
					height: r(60px)
					width: r(60px)
				em
					@apply text-primary-1D4789
					font-size: r(28px)
					@media screen and ( max-width: 576px)
						font-size: r(24px)
					@screen xl
						font-size: r(32px)
.project-template-default,.product-template-default,.post-template-default
	.fixed-social-nav
		@apply opacity-100 pointer-events-auto
.fixed-social-nav
	@apply fixed right-[30px] opacity-100 pointer-events-auto top-1/2 opacity-0 pointer-events-none translate-y-1/2
	transition: .4s all  ease-in-out
	z-index: 99 
	@media screen and ( max-width: 1280px)
		left: unset
		bottom: 190px
		@apply right-[15px]
	ul
		list-style-type: none
		@apply col-item pl-0
		li
			@apply mb-[10px]
			a
				@apply center-item bg-white rounded-full overflow-hidden transition
				border: 1px solid #999999
				height: r(40px)
				width: r(40px)
				&:hover
					@apply bg-999999
					em
						@apply text-white
				@screen xl
					height: r(50px)
					width: r(50px)
				em
					@apply text-999999 transition
					font-size: r(20px)
					@screen xl
						font-size: r(24px)
.contact-modal-form
	max-width: 990px
	.block-wrap-form
		padding: 0 !important
	.carousel__button
		width: 40px !important
		height: 40px !important
		top: 0 !important
		svg
			@apply text-999999
.contact-us-page
	.page-banner 
		a
			height: r(760px)