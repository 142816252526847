.home-led
	background: #EFF4F7
	.four-swiper-no-gap
		@apply mt-8
		@screen xl
			@apply mt-12
	.product-item
		@apply px-3 py-6
		&:hover
			@apply bg-white
			box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08)