.project-swiper-wrap
	@screen xl
		padding-left: r(180px)
	.swiper-slide
		@apply h-auto
.project-complete
	background: rgba(0, 0, 0, 0.03)
.project-on-going
	@apply bg-background-F5F5F5
.project-swiper
	.wrap-gap
		@media screen and ( max-width: 1024px)
			@apply col-item
			.block-title
				@apply mb-0
			.btn-primary
				@apply my-8
	.progress-wrap
		@apply center-item mt-8
	.first-index,.last-index
		@apply text-black font-bold
	.first-index
		@apply mr-3
	.last-index
		@apply ml-3
	.btn-primary
		span
			@apply mx-0
		em
			@apply ml-2
	.swiper-pagination
		position: static !important
	.swiper-pagination-progressbar
		&.swiper-pagination-horizontal
			height: 5px !important
		.swiper-pagination-progressbar-fill
			background: #ED202E !important
.project-figure
	@apply bg-white overflow-hidden  h-full
	border-radius: 10px
	padding: r(10px)
	.img-src 
		a
			@apply  overflow-hidden 
			border-radius: 10px
			+img-ratio(270/450)
	.txt
		@apply p-4
		@screen xl
			padding: r(25px) r(20px) r(30px)
	ul
		list-style-type: none
		@apply pl-0
		li
			@apply start-item mb-1
			@media screen and ( max-width: 576px)
				@apply col-item
				align-items: flex-start
			strong,span
				@apply text-222222
			strong
				min-width: r(130px)
				@media screen and ( max-width: 576px)
					@apply mb-1
	.headline
		@apply mb-2
		a
			@apply text-primary-1D4789  transition leading-tight 
			font-size: r(24px)
		&:hover
			a
				@apply text-primary-ED202E