.caption
	@apply text-333333 font-normal
	font-size: 1rem
	strong
		@apply font-bold
	*
		@apply text-333333 font-normal
		font-size: 1rem
	&.blue-1
		@apply text-primary-1D4789
		*
			@apply text-primary-1D4789
	&.white
		@apply text-white
		*
			@apply text-white
	&.t-14
		font-size: .875rem
		*
			font-size: .875rem
		@screen xl
			font-size: r(14px)
			*
				font-size: r(14px)
	&.t-18
		font-size: 1.125rem
		*
			font-size: 1.125rem
		@screen xl
			font-size: r(18px)
			*
				font-size: r(18px)
	&.t-20
		font-size: 1.25rem
		*
			font-size: 1.25rem
		@screen xl
			font-size: r(20px)
			*
				font-size: r(20px)
	&.t-22
		font-size: 1.375rem
		*
			font-size: 1.375rem
		@screen xl
			font-size: r(22px)
			*
				font-size: r(22px)
	&.t-24
		font-size: 1.5rem
		*
			font-size: 1.5rem
		@screen xl
			font-size: r(24px)
			*
				font-size: r(24px)
.zone-desc
	@apply text-333333
	font-size: r(24px)
	strong
		@apply font-bold
	*
		@apply text-333333
		font-size: r(24px)
	&.blue-1
		@apply text-primary-1D4789
		*
			@apply text-primary-1D4789
	&.grey-3
		@apply text-666666
		*
			@apply text-666666
time
	@apply text-999999 text-[14px] font-normal text-left block
