.research-technology
	.top-zone-nav
		@apply mb-8
		@screen xl
			@apply mb-14
		@media screen and ( max-width: 576px)
			ul
				li
					a
						@apply text-[13px] px-4
	.txt-wrap
		@apply mb-10
		@screen xl
			@apply mb-14
.swiper-research-main
	.img-wrap
		a
			@media screen and ( max-width: 1279.1px)
				+img-ratio(580/1020)
			@screen xl
				@apply center-item
				height: r(580px)
				width: auto
				img
					width: 100%
					height: 100%
					object-fit: cover
.swiper-research-thumb
	@apply relative
	@media screen and ( max-width: 1279.1px)
		@apply px-10
	.swiper-slide
		.img-wrap
			border: 1px solid transparent
			transition: .3s all  ease-in-out
		&.swiper-slide-thumb-active
			.img-wrap
				border-color: $primary
	.nav-prev,.nav-next
		@apply absolute 
		@media screen and ( max-width: 1279.1px)
			@apply top-1/2 translate-y-50
		@screen xl
			@apply left-1/2 translate-x-50
		em
			font-size: 48px
			@apply text-999999 
			width: 30px
			@media screen and ( max-width: 1279.1px)
				transform: rotate(-90deg)
				font-size: 32px
				height: 32px
				line-height: 32px
	.nav-prev
		@media screen and ( min-width: 1024px ) and ( max-width: 1279.1px)
			@apply right-full
		@media screen and ( max-width: 1024px )
			left: 0
		@screen xl
			@apply bottom-full
	.nav-next
		@media screen and ( min-width: 1024px ) and ( max-width: 1279.1px)
			@apply left-full
		@media screen and ( max-width: 1024px )
			right: 0
		@screen xl
			@apply top-full
	.swiper
		@screen xl
			height: r(580px)
	.img-wrap
		a
			@media screen and ( max-width: 1279.1px)
				+img-ratio(100/180)
			@screen xl
				@apply center-item
				height: r(100px)
				width: auto
				img
					width: 100%
					height: 100%
					object-fit: cover
.research-machine
	padding-bottom: r(80px)
	.zone-title
		@apply mb-10
		@screen xl
			@apply mb-12
	.row
		@apply justify-start
.research-item
	@apply bg-background-F5F5F5  h-full
	.img-src
		a
			+img-ratio(220/390)
	.txt
		@apply p-4 start-item
		@screen sm
			@apply px-5 