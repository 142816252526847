.project-detail
	padding: r(80px) 0
	.block-title
		@apply relative pb-5 mb-2
		&::after
			content: ''
			@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition bg-primary-ED202E
			height: 2px
			width: 80px
	.table-wrap
		table
			tr
				td
					border-bottom: 1px solid #E5E5E5
					@apply  py-3 text-333333
					height: 50px
					font-size: r(16px)
					&:first-child
						@apply font-bold text-222222  pr-5
						min-width: r(175px)
	.block-wrap
		@apply mt-10
		@screen xl
			@apply mt-[60px]