.home-cable
	background: #EFF4F6
	.four-swiper-no-gap
		@apply mt-8
		@screen xl
			@apply mt-12
	.product-item
		@apply px-3 py-6
		&:hover
			@apply bg-white
			box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08)
	.block-title
		span
			@apply text-primary-ED202E
.product-item
	@apply transition
	.img-1
		@apply relative z-10 center-item
		a
			@apply center-item rounded-full overflow-hidden
			width: r(280px)
			height: r(280px)
			img
				width: 100%
				height: 100%
				object-fit: contain
	.img-2
		@apply relative  z-20 center-item
		margin-top: -90px
		a
			@apply center-item rounded-full
			width: r(190px)
			height: r(185px)
			img
				width: 100%
				height: 100%
				object-fit: contain
	&:hover
		.headline
			@apply text-primary-ED202E
			*
				@apply text-primary-ED202E
	.headline
		@apply mt-4 text-center
		@screen xl
			@apply mt-6