.row 
	@apply flex flex-wrap justify-start
.col
	flex: 0 0 auto
	@apply flex flex-col
	padding: 0 15px
	.col
		margin-bottom: 0
.row
	margin: 0 -15px -30px -15px
	> *
		margin-bottom: 30px
.custom-select
	width: 100%
	select
		-webkit-appearance: none
		-moz-appearance: none
		background: url('../img/icon/chevron-down.png') no-repeat calc(100% - 15px),#f5f5f5 !important
		background-position: 95% 50%

.tab-item
	@apply hidden
	&.active
		@apply block