.home-news-partner-bg
	background: url('../img/bg/index-bg-4.png') ,#F5F5F5
	background-repeat: no-repeat
	background-size: contain
	background-position:  bottom
.home-news
	@apply pb-0
	.wrap-center
		@apply pt-8
		@screen xl
			@apply pt-10
.news-item
	border-radius: 10px
	@apply overflow-hidden bg-white
	&.big
		box-shadow: 0px 4px 10px 0px #0000000D
		.txt
			@apply p-[30px] pb-16
			@media screen and ( max-width: 576px)
				@apply px-4 pb-10
		.headline
			&:hover
				@apply text-primary-ED202E
				*
					@apply text-primary-ED202E
			@media screen and ( max-width: 576px)
				font-size: r(24px)
				*
					font-size: r(24px)
		.img-src
			a
				@media screen and ( max-width:1280px )
					+img-ratio(350/600)
				@screen xl
					@apply center-item
					height: 350px
					width: auto
					img
						width: 100%
						height: 100%
						object-fit: cover
	&.side
		box-shadow: 0px 4px 10px 0px #0000000D
		@apply flex
		&:not(:last-child)
			@apply mb-[10px]
			@media screen and ( max-width: 576px)
				@apply mb-8
		@media screen and ( max-width: 576px)
			@apply flex-col
		.txt
			@apply p-5
		time
			@media screen and ( max-width: 576px)
				@apply mb-1
		.img-src
			@screen sm
				width: r(290px)
				min-width: r(290px)
				a
					@apply center-item
					height: 170px
					width: auto
					img
						width: 100%
						height: 100%
						object-fit: cover
			@media screen and ( max-width: 576px)
				a
					+img-ratio(170/290)
			
		.headline
			@apply font-normal
			*
				@apply font-normal
				+line(3)
			&:hover
				@apply text-primary-ED202E
				*
					@apply text-primary-ED202E
			@media screen and ( max-width: 576px)
				font-size: r(20px)
				*
					font-size: r(20px)