.home-dealer
	.row
		@screen lg
			background: url('../img/bg/map-2.png')
			background-repeat: no-repeat
			background-size: 100% 100%
			background-position: center
		.col
			&:first-child
				@media screen and ( max-width: 1024px)
					background: url('../img/bg/map-2.png')
					background-repeat: no-repeat
					background-size: 100% 100%
					background-position: center
	.dealer-map-wrapper
		padding: 0
		a
			@apply center-item overflow-hidden
			height: 500px
			text-align: center
			width: 100%
			border-radius: 20px
			@media screen and ( max-width: 1024px)
				height: 400px
			@media screen and ( max-width: 576px)
				height: 300px
			iframe
				@apply inline-block w-full h-full
	.image-map-wrapper
		@apply center-item
		@screen lg
			@apply pl-16 start-item
		img
			opacity: 1 !important
.tooltipster-sidetip 
	.tooltipster-arrow
		display: none !important
	.tooltipster-content
		padding: 0 !important
	.tooltipster-box
		background: #F5F5F5 !important
		border: 2px solid #FFFFFF !important
		box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1) !important
		border-radius: 20px !important
		@apply p-5
	ul
		list-style-type: none
		padding-left: 0
		li
			@apply mb-2 start-item
			&:last-child
				@apply mb-2
		em
			@apply inline-block mt-1 text-primary-1D4789 mr-[10px]
			font-size: 16px
			height: 16px
			line-height: 16px
		span
			@apply text-[14px] text-333333 leading-normal
	.title
		@apply text-primary-1D4789 font-semibold block text-left pb-2 mb-3
		border-bottom: 1px solid #E0E0E0