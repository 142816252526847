.home-certificate
	@apply bg-background-F7F9FA
	.row
		@apply justify-between
		.col
			@media screen and ( max-width: 1024px)
				&:first-child
					order: 2
				&:nth-child(2)
					order: 1
	.block-title
		@media screen and ( max-width: 1024px)
			@apply text-center
	.zone-desc
		@apply text-[16px]
		@media screen and ( max-width: 1024px)
			order: 2
			@apply pt-8
		@screen xl
			font-size: 16px
			*
				font-size: 16px
			p
				font-size: 16px
	.nav-arrow-next
		right: -40px
	.nav-arrow-prev
		left: -40px
	.block-wrap
		@screen xl
			@apply pr-16
	.swiper-certificate-thumb
		@apply mt-8 relative
		@media screen and ( max-width: 1024px)
			@apply mt-0
		@screen xl
			@apply mt-12 
		.swiper
			@screen xl
				height: 420px
			@media screen and ( min-width: 1440.1px)
				height: 580px
		.swiper-slide
			@screen xl
				height: 190px
			@media screen and ( min-width: 1440.1px)
				height: 270px
		.item
			@apply py-4 px-3 bg-white overflow-hidden
			border: 1px solid #E0E0E0
			border-radius: 20px
		.img-src
			a
				@media screen and ( max-width: 1280px)
					+img-ratio(235/165,contain)
				@screen xl
					@apply center-item
					height: 155px
					width: auto
					img
						width: 100%
						height: 100%
						object-fit: contain
				@media screen and ( min-width: 1440.1px)
					height: 235px
	.swiper-certificate-main
		@apply p-3
		.swiper
			@apply p-3
		.item
			@apply py-8 px-3 bg-white overflow-hidden
			border: 1px solid #E0E0E0
			border-radius: 20px
			box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.1)
			@screen xl
				@apply px-4 py-12
		.img-src
			a
				@media screen and ( max-width: 1280px)
					+img-ratio(590/454,contain)
				@screen xl
					@apply center-item
					height: 390px
					width: auto
					img
						width: 100%
						height: 100%
						object-fit: contain
				@media screen and ( min-width: 1440.1px)
					height: 590px