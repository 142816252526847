.home-partner
	.nav-arrow-next
		right: -40px
	.nav-arrow-prev
		left: -40px
	.block-title
		@apply uppercase
.partner-item
	a
		@apply center-item bg-white py-5
		border: 1px solid #E5E5E5
		height: r(120px)
		width: auto
		img
			width: 100%
			height: 100%
			object-fit: contain