@charset "UTF-8";
.row {
  @apply flex flex-wrap justify-start;
}

.col {
  flex: 0 0 auto;
  @apply flex flex-col;
  padding: 0 15px;
}

.col .col {
  margin-bottom: 0;
}

.row {
  margin: 0 -15px -30px -15px;
}

.row > * {
  margin-bottom: 30px;
}

.custom-select {
  width: 100%;
}

.custom-select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("../img/icon/chevron-down.png") no-repeat calc(100% - 15px), #f5f5f5 !important;
  background-position: 95% 50%;
}

.tab-item {
  @apply hidden;
}

.tab-item.active {
  @apply block;
}

body, html {
  font-size: 13px;
}

@media (min-width: 576px) {
  body, html {
    font-size: 14px;
  }
}
@media (min-width: 1024px) {
  body, html {
    font-size: 15px;
  }
}
@media (min-width: 1280px) {
  body, html {
    font-size: 1vw;
  }
}
main {
  max-width: 100vw;
  overflow-x: hidden;
}

::selection {
  color: #fff;
  @apply bg-primary-ED202E;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

abbr[title], abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 0;
  font-style: normal;
  line-height: inherit;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0;
  margin-left: 0;
}

blockquote {
  margin: 0;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  text-decoration: none;
  background-color: transparent;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}

a:not([href]):not([tabindex]) {
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre, code, kbd, samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 0;
  overflow: auto;
}

figure {
  margin: 0;
}

img {
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0;
  padding-bottom: 0;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0;
}

button {
  border-radius: 0;
}

button:focus {
  outline: none;
  outline: calc(5/1920*100*1rem) auto -webkit-focus-ring-color;
}

input, button, select, optgroup, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button, [type=button], [type=reset], [type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled), [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner, [type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio], input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date], input[type=time], input[type=datetime-local], input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

input:focus, button:focus, a:focus, .button-prev:focus, .button-next:focus, textarea:focus, select:focus {
  outline: none;
}

img.noImage {
  padding: 0 !important;
  object-fit: cover !important;
}

.shine {
  overflow: hidden;
  position: relative;
}

.shine:before {
  position: absolute;
  top: 0;
  pointer-events: none;
  left: -75%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.shine:hover:before {
  -webkit-animation: shine 0.75s;
  animation: shine 0.75s;
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}
@keyframes shine {
  100% {
    left: 125%;
  }
}
.circle {
  overflow: hidden;
  position: relative;
}

.circle:before {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  display: block;
  content: "";
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
}

.circle:hover:before {
  animation: circle 0.75s;
}

@-webkit-keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}
@keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}
.zoom-in {
  overflow: hidden;
  transition: 0.45s all ease-in-out;
}

.zoom-in:hover img {
  transform: scale(1.05);
}

.zoom-in img {
  transform: scale(1);
  transition: 0.3s all ease-in-out;
}

.zoom-img {
  overflow: hidden;
}

.zoom-img .img {
  overflow: hidden;
}

.zoom-img:hover img {
  transform: scale(1.05);
}

.zoom-img img {
  transition: 0.3s all ease-in-out;
}

.opacity {
  transition: 0.3s all ease-in-out;
}

.opacity:hover {
  opacity: 0.8;
}

.opacity img {
  opacity: 1;
  transition: 0.3s all ease-in-out;
}

.flash {
  transition: 0.3s all ease-in-out;
}

.flash:hover img {
  opacity: 1;
  animation: flash 1.5s;
}

@-webkit-keyframes flash {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flash {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fb-like {
  height: 20px;
}

.fb-like span {
  height: 20px !important;
}

.fb-like iframe {
  height: 20px !important;
  width: 126px !important;
}

.input-group-text {
  @apply hidden;
}

.button {
  transition: 0.3s all ease-in-out;
  user-select: none;
}

@media (min-width: 576px) {
  .button * {
    white-space: nowrap;
  }
}
.button .btn {
  user-select: none;
  cursor: pointer;
  width: fit-content;
  transition: 0.3s all ease-in-out;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3645833333rem 1.015625rem;
  border-radius: 4.6875rem;
  border: 0.0520833333rem solid transparent;
  height: 2.6041666667rem;
  @apply text-white text-base transition-all;
}

.button .btn.btn-header {
  height: 1.5625rem;
  width: 7.96875rem;
  @apply text-primary-1D4789 border-primary-1D4789 text-sm;
}

.button .btn.btn-header i {
  @apply mr-10px text-base;
}

.button .btn.btn-header:hover {
  @apply bg-primary-ED202E border-transparent text-white;
}

.button .btn.has-input-submit {
  @apply relative;
}

.button .btn.has-input-submit span.wpcf7-spinner {
  left: calc( 100% + 30/1920*100rem );
  @apply absolute left-full top-1/2 -translate-y-1/2;
}

.button .btn.has-input-submit::before {
  content: attr(data-title);
  @apply normal-case;
}

.button .btn.has-input-submit input {
  font-size: 0;
  @apply absolute top-0 left-0 w-full h-full;
}

.nav-arrow-prev, .nav-arrow-next {
  @apply z-10 transition  absolute top-1/2 translate-y-50    cursor-pointer center-item  rounded-full transition;
  width: 30px;
  height: 50px;
  line-height: 50px;
}

@media screen and (max-width: 1024px) {
  .nav-arrow-prev, .nav-arrow-next {
    @apply static mx-6;
    transform: none;
    width: 28px;
    height: 32px;
    line-height: 32px;
  }
}
.nav-arrow-prev:hover em, .nav-arrow-next:hover em {
  @apply text-primary-ED202E;
}

.nav-arrow-prev em, .nav-arrow-next em {
  @apply inline-block transition text-999999;
  font-size: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
}

@media screen and (max-width: 1280px) {
  .nav-arrow-prev em, .nav-arrow-next em {
    font-size: 32px;
    height: 32px;
    line-height: 32px;
  }
}
@media screen and (max-width: 1024px) {
  .swiper-nav-wrap {
    @apply center-item pt-6 mb-0;
  }
}
.swiper-nav-wrap.white {
  background: none;
}

.swiper-nav-wrap.white .nav-arrow-next em, .swiper-nav-wrap.white .nav-arrow-prev em {
  @apply text-white;
}

.swiper-nav-wrap.white .nav-arrow-next:hover em, .swiper-nav-wrap.white .nav-arrow-prev:hover em {
  @apply text-white;
}

.swiper-nav-wrap.grey {
  background: none;
}

.swiper-nav-wrap.grey .nav-arrow-next em, .swiper-nav-wrap.grey .nav-arrow-prev em {
  @apply text-333333;
}

.swiper-nav-wrap.small .nav-arrow-next em, .swiper-nav-wrap.small .nav-arrow-prev em {
  @apply text-333333;
  font-size: 30px;
  height: 30px;
  line-height: 30px;
}

@media screen and (max-width: 1024px) {
  .swiper-nav-wrap.small .nav-arrow-next em, .swiper-nav-wrap.small .nav-arrow-prev em {
    font-size: 24px;
    height: 24px;
    line-height: 24px;
  }
}
.nav-arrow-next {
  right: -70px;
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .nav-arrow-next {
    right: -40px;
  }
}
.nav-arrow-prev {
  left: -70px;
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .nav-arrow-prev {
    left: -40px;
  }
}
.button-wrapper {
  @apply flex gap-10px;
}

.wrap-gap, .wrap-center, .wrap-left, .wrap-right, .ajax-center-wrap {
  @apply flex items-center;
}

.wrap-gap a:not(:last-child), .wrap-center a:not(:last-child), .wrap-left a:not(:last-child), .wrap-right a:not(:last-child), .ajax-center-wrap a:not(:last-child) {
  @apply mr-2;
}

.wrap-center, .ajax-center-wrap {
  @apply justify-center;
}

.wrap-left {
  @apply justify-start;
}

.wrap-right {
  @apply justify-end;
}

.wrap-gap {
  @apply justify-between;
}

.ajax-center-wrap {
  @apply mt-8;
}

.ajax-center-wrap.disble-btn {
  @apply opacity-0 pointer-events-none;
}

.btn span, .btn em {
  transition: 0.4s all ease-in-out;
}

.btn.btn-default {
  @apply bg-primary-1D4789 text-white h-[40px]  rounded-[26px] px-12;
}

@screen xl {
  .btn.btn-default {
    @apply h-[50px];
  }
}
.btn.btn-default span {
  @apply text-[14px] text-white font-bold uppercase;
}

@screen xl {
  .btn.btn-default span {
    @apply text-[16px];
  }
}
.btn.btn-default em {
  @apply inline-block ml-2;
  font-size: 14px;
  height: 14px;
  line-height: 14px;
}

@screen xl {
  .btn.btn-default em {
    font-size: 16px;
    height: 16px;
    line-height: 16px;
  }
}
.btn.btn-default:hover {
  @apply bg-primary-ED202E;
}

.btn.btn-default:hover em {
  transform: translateX(4px);
}

.btn.btn-primary {
  @apply h-[40px] bg-primary-1D4789 px-6;
  border-radius: 26px;
  border: 1px solid #1D4789;
}

.btn.btn-primary span, .btn.btn-primary em {
  @apply text-white;
  transition: 0.4s all ease-in-out;
}

.btn.btn-primary span {
  font-size: 0.8333333333rem;
  @apply font-bold uppercase ml-2 inline-block;
}

@screen xl {
  .btn.btn-primary {
    @apply h-[50px] px-10;
  }
}
.btn.btn-primary:hover {
  @apply bg-white;
}

.btn.btn-primary:hover span, .btn.btn-primary:hover em {
  @apply text-primary-1D4789;
}

.btn.btn-primary.white {
  @apply bg-white;
}

.btn.btn-primary.white span, .btn.btn-primary.white em {
  @apply text-primary-1D4789;
}

.btn.btn-primary.white:hover {
  @apply bg-primary-1D4789;
}

.btn.btn-primary.white:hover span, .btn.btn-primary.white:hover em {
  @apply text-white;
}

.wrap-form {
  @apply relative;
}

.wrap-form .wpcf7-spinner {
  display: none !important;
}

.wrap-form .row {
  box-shadow: none !important;
}

.wrap-form .fa-exclamation-triangle {
  @apply absolute text-[12px] font-light;
  bottom: -25px;
  left: 20px;
  color: #f30000;
}

@media screen and (max-width: 1024px) {
  .wrap-form .fa-exclamation-triangle {
    bottom: -23px;
  }
}
.wrap-form .col {
  @apply mb-4 relative;
}

.wrap-form .col:last-child {
  @apply mb-6;
}

.wrap-form .form-group {
  @apply relative;
  margin-bottom: 20px !important;
}

@media screen and (max-width: 1024px) {
  .wrap-form .form-group {
    margin-bottom: 25px !important;
  }
}
.wrap-form .form-group input[type=text], .wrap-form .form-group textarea, .wrap-form .form-group select {
  height: 50px;
  background: #ffffff;
  border: 1px solid #DEEBFF;
  border-radius: 25px !important;
  @apply text-[14px] text-333333 px-[20px];
}

@media screen and (max-width: 1024px) {
  .wrap-form .form-group input[type=text], .wrap-form .form-group textarea, .wrap-form .form-group select {
    height: 40px;
    border-radius: 15px !important;
    @apply px-5;
  }
}
.wrap-form .form-group input[type=text]::placeholder, .wrap-form .form-group textarea::placeholder, .wrap-form .form-group select::placeholder {
  @apply text-666666 text-[14px] font-normal;
}

@media screen and (max-width: 1024px) {
  .wrap-form .form-group input[type=text]::placeholder, .wrap-form .form-group textarea::placeholder, .wrap-form .form-group select::placeholder {
    @apply text-[12px];
  }
}
.wrap-form .form-group select option {
  @apply text-[16px] text-333333;
}

.wrap-form .form-group textarea {
  @apply p-4;
  height: 6.25rem;
}

.wrap-form .frm-btnwrap {
  @apply center-item w-full mt-8;
}

.wrap-form .frm-btnwrap .frm-btn {
  @apply relative;
}

.wrap-form .frm-btnwrap .frm-btn:hover::after {
  @apply text-primary-1D4789;
}

.wrap-form .frm-btnwrap .frm-btn::after {
  font-family: "Font Awesome 6 Pro";
  content: "";
  @apply block absolute pointer-events-none top-1/2 translate-y-50 right-[45px]  z-10  transition text-white font-light;
  height: 16px;
  width: 20px;
  line-height: 16px;
}

@media screen and (max-width: 1024px) {
  .wrap-form .frm-btnwrap .frm-btn::after {
    right: 20px;
  }
}
.wrap-form .frm-btnwrap .frm-btn input[type=submit] {
  width: 160px;
  height: 45px;
  border-radius: 100px;
  border: 1px solid #1D4789;
  @apply text-white  text-[16px] bg-primary-1D4789 transition uppercase center-item px-8 font-bold pr-12  overflow-hidden;
}

@media screen and (max-width: 1024px) {
  .wrap-form .frm-btnwrap .frm-btn input[type=submit] {
    height: 40px;
    width: 120px;
    @apply px-6 pr-10;
  }
}
.wrap-form .frm-btnwrap .frm-btn input[type=submit]:hover {
  @apply bg-white text-primary-1D4789;
}

.flex-wrap {
  display: flex;
  justify-content: center;
}

.login-page .login-panel {
  margin: 60px 0;
  background: #fff;
  border-radius: 5px;
}

.login-page .login-panel .sitelogo {
  text-align: center;
  margin-bottom: 40px;
  width: 100%;
}

.login-page .login-panel img {
  height: 80px;
}

.login-page .login-panel .headtitle {
  text-align: center;
  font-size: 30px;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.login-page .login-panel .headtitle:after {
  content: "";
  margin: 10px auto;
}

@media (max-width: 1199px) {
  .login-page .login-panel {
    margin: 60px 0;
  }
}
@media (max-width: 543px) {
  .login-page .sitelogo {
    margin-bottom: 30px;
  }
}
.login-form {
  width: 100%;
  margin: 0 auto;
}

.login-form .fa-exclamation-triangle {
  @apply absolute top-full left-0 text-[12px]  pt-1;
}

.login-form .panel-heading {
  display: none;
}

.login-form .title {
  font-size: 1.5rem;
  font-weight: 500;
  text-transform: uppercase;
  border-left: 3px solid #8c0000;
  padding-left: 10px;
  margin-bottom: 30px;
}

.login-form .module-title {
  font-size: 0.875rem;
  color: #666666;
  margin-bottom: 20px;
}

.login-form a {
  color: #8c0000;
}

.login-form .form-group {
  margin-bottom: 20px;
  position: relative;
}

.login-form .form-group label {
  color: #999999;
  text-transform: uppercase;
  font-size: 0rem;
  display: block;
  width: 50px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  border-right: 1px solid #e6e6e6;
  @apply text-[14px];
}

.login-form .form-group label .fa {
  font-size: 1.125rem;
  line-height: 40px;
}

.login-form input {
  width: 350px;
  font-size: 0.875rem;
  border: 1px solid #d9d9d9;
  border-radius: 0;
  padding: 10px 30px 10px 60px;
  -webkit-transition: all, 0.5s;
  transition: all, 0.5s;
}

.login-form input::placeholder {
  font-size: 15px;
  color: #999;
}

.login-form input:active, .login-form input:hover, .login-form input:focus {
  border-color: #999999;
}

@media screen and (max-width: 400px) {
  .login-form input {
    width: 300px;
  }
}
.login-form a {
  display: inline-block;
  color: #000;
  padding: 10px 0;
  margin-right: 15px;
}

.login-form a:hover {
  color: #8c0000;
}

.login-form .forget {
  font-size: 0.875rem;
  color: #999999;
  margin-bottom: 30px;
}

.login-form .forget input[type=checkbox] {
  @apply opacity-0 relative;
}

.login-form .forget input[type=checkbox]:checked ~ label::before {
  content: "󰄲�";
}

.login-form .forget label {
  color: #333333;
  font-weight: 300;
  width: auto;
  height: auto;
  text-align: left;
  padding-left: 30px;
  line-height: 1.5;
  font-size: 0.875rem;
  text-transform: none;
  position: relative;
  -webkit-transition: all, 0.5s;
  transition: all, 0.5s;
}

.login-form .forget label:before {
  font-family: "Material Design Icons";
  content: "󰄱�";
  font-size: 20px;
  display: block;
  line-height: 14px;
  font-size: 16px;
  color: #333333;
  position: absolute;
  top: 2px;
  left: 1px;
  -webkit-transition: all, 0.5s;
  transition: all, 0.5s;
}

.login-form .forget label:hover {
  color: #333333;
}

.login-form .btn-login {
  margin: 0 auto;
  width: 100%;
  text-align: center;
  position: relative;
  color: #fff;
  background: #8c0000;
  display: block;
  border-radius: 5px;
  overflow: hidden;
}

.login-form .btn-login:hover::after {
  transform: translateX(3px);
}

.login-form .btn-login:before {
  background: #c61017;
}

.login-form .btn-login:after {
  transition: 0.3s all ease-in-out;
  font: normal normal normal 24px/1 Material Design Icons;
  content: "󰅂�";
  position: absolute;
  top: 12px;
  right: 15px;
}

.login-form input[type=reset] {
  display: none;
}

.login-form input[type=submit] {
  line-height: 100%;
  cursor: pointer;
  width: 100%;
  color: #fff !important;
  background: #171e26 !important;
  padding: 15px 60px;
  border: none;
  text-transform: uppercase;
  font-size: 1rem;
}

.login-form .go-signup {
  text-align: center;
  margin-top: 15px;
}

.login-form .go-signup a {
  display: block;
  clear: both;
  color: #8c0000;
}

.login-form .go-signup a:hover {
  color: #000;
}

@media (max-width: 543px) {
  .login-form .btn-login {
    width: 100%;
  }
}
.login-bg {
  width: 60%;
  position: relative;
  overflow: hidden;
  padding: 50px;
  border-radius: 5px;
}

.login-bg .login-img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.login-bg .login-img .login-overlay {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  opacity: 0.5;
  z-index: 1;
}

.login-bg img {
  height: 100%;
  width: auto;
  max-width: unset;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.login-bg .login-des {
  position: relative;
  z-index: 1;
  color: #fff;
}

.login-bg .login-des .title {
  font-size: 2.875rem;
  font-weight: 300;
  margin-bottom: 20px;
}

.login-bg .login-des .module-title {
  font-size: 1.625rem;
  font-weight: 200;
  opacity: 0.9;
}

.login-bg .login-des .des {
  margin-top: 180px;
  font-size: 1.25rem;
}

.login-bg .login-des .des span {
  opacity: 0.8;
  font-weight: 200;
  display: inline-block;
  margin-right: 10px;
}

.login-bg .login-des .des a {
  display: inline-block;
  opacity: 1;
  font-weight: 300;
  font-size: 2rem;
  color: #fff;
}

.login-bg .login-des .des a:after {
  content: "";
  font-family: "Font Awesome 6 Pro";
  margin-left: 5px;
}

.login-bg .login-des .des a:hover {
  color: #8c0000;
}

@media (max-width: 991px) {
  .login-bg {
    width: 100%;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }
}
@media (max-width: 767px) {
  .login-bg {
    padding: 30px;
  }
}
@media (max-width: 991px) {
  .login-bg .login-img img {
    height: auto;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .login-bg .login-img img {
    -webkit-transform: scale3d(1.1, 1.1, 1);
    -khtml-transform: scale3d(1.1, 1.1, 1);
    transform: scale3d(1.1, 1.1, 1);
  }
}
@media (max-width: 543px) {
  .login-bg .login-img img {
    width: auto;
    height: 100%;
  }
}
@media (max-width: 767px) {
  .login-bg .login-des .title {
    font-size: 2.25rem;
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .login-bg .login-des .module-title {
    font-size: 1.25rem;
  }
}
@media (max-width: 991px) {
  .login-bg .login-des .des {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .login-bg .login-des .des {
    margin-top: 10px;
  }
}
@media (max-width: 543px) {
  .login-bg .login-des .des a {
    font-size: 1.625rem;
  }
}
.p span {
  color: #eb0c22;
  font-style: italic;
}

#ctl00_mainContent_login1_LoginCtrl_lnkPasswordRecovery, #ctl00_mainContent_login1_LoginCtrl_lnkRegisterExtraLink {
  display: none;
}

.pop-up-modal {
  padding: 0;
  border-radius: 10px;
  @apply w-full;
}

.pop-upmodal-wrapper {
  overflow: hidden;
}

.pop-upmodal-wrapper .row {
  @apply m-0 p-0;
}

.pop-upmodal-wrapper .row .col {
  @apply m-0 p-0;
}

.pop-upmodal-wrapper .container {
  padding: 0 40px;
  @apply flex items-center;
  height: 100%;
}

@screen lg {
  .pop-upmodal-wrapper .container {
    padding: 0 80px;
  }
}
.pop-upmodal-wrapper .sub-text {
  @apply text-[18px] text-333333 mb-2;
}

.pop-upmodal-wrapper .scroll-wrapper {
  @apply text-[16px] text-333333 pt-4;
}

@screen lg {
  .pop-upmodal-wrapper .scroll-wrapper {
    @apply pt-6;
  }
}
.pop-upmodal-wrapper .scroll-wrapper * {
  @apply text-[16px] text-333333 mb-4;
}

@screen lg {
  .pop-upmodal-wrapper .scroll-wrapper * {
    @apply mb-5;
  }
}
.pop-upmodal-wrapper .scroll-wrapper strong {
  @apply font-bold;
}

.pop-upmodal-wrapper .scroll-wrapper iframe, .pop-upmodal-wrapper .scroll-wrapper img {
  @apply block mx-auto my-5;
}

.pop-upmodal-wrapper .btn-left {
  @apply pt-4;
}

@screen lg {
  .pop-upmodal-wrapper .btn-left {
    @apply pt-6;
  }
}
.pop-upmodal-wrapper .text-wrap {
  @apply flex flex-col items-start justify-center;
  height: 100%;
}

.pop-upmodal-wrapper .block-title {
  @apply text-[24px] leading-tight;
}

.pop-upmodal-wrapper .scroll-wrapper {
  height: 450px;
  padding-right: 15px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: red #cccccc;
  @apply leading-relaxed text-justify;
}

.pop-upmodal-wrapper .scroll-wrapper::-webkit-scrollbar {
  background: #cccccc;
  width: 3px;
}

.pop-upmodal-wrapper .scroll-wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #cccccc;
  background-color: #cccccc;
}

.pop-upmodal-wrapper .scroll-wrapper::-webkit-scrollbar-thumb {
  background-color: red;
}

@media screen and (max-width: 1024px) {
  .pop-upmodal-wrapper .scroll-wrapper {
    height: 330px;
  }
}
.section {
  padding: 35px 0;
}

@media (min-width: 768px) {
  .section {
    padding: 40px 0;
  }
}
@media (min-width: 1024px) {
  .section {
    padding: 45px 0;
  }
}
@media (min-width: 1280px) {
  .section {
    padding: 3.125rem 0;
  }
}
.four-swiper, .trinity-swiper, .double-swiper, .single-swiper, .four-swiper-no-gap {
  @apply relative;
}

.fixed-side-nav {
  @apply fixed right-[60px] opacity-0 pointer-events-none bottom-[60px];
  transition: 0.4s all ease-in-out;
  z-index: 99;
}

@media screen and (max-width: 1280px) {
  .fixed-side-nav {
    @apply right-[15px] opacity-100;
    bottom: 50px;
  }
}
.fixed-side-nav.active {
  @apply opacity-100 pointer-events-auto;
}

.fixed-side-nav ul {
  list-style-type: none;
  @apply col-item pl-0;
}

.fixed-side-nav ul li {
  @apply mb-[10px];
}

.fixed-side-nav ul li:first-child em {
  font-size: 1.25rem;
}

.fixed-side-nav ul li:last-child a {
  @apply bg-primary-ED202E;
}

.fixed-side-nav ul li:last-child a em {
  @apply text-white;
}

.fixed-side-nav ul li a {
  @apply center-item bg-white rounded-full overflow-hidden;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  height: 2.6041666667rem;
  width: 2.6041666667rem;
}

@screen xl {
  .fixed-side-nav ul li a {
    height: 3.125rem;
    width: 3.125rem;
  }
}
.fixed-side-nav ul li a em {
  @apply text-primary-1D4789;
  font-size: 1.4583333333rem;
}

@media screen and (max-width: 576px) {
  .fixed-side-nav ul li a em {
    font-size: 1.25rem;
  }
}
@screen xl {
  .fixed-side-nav ul li a em {
    font-size: 1.6666666667rem;
  }
}
.project-template-default .fixed-social-nav, .product-template-default .fixed-social-nav, .post-template-default .fixed-social-nav {
  @apply opacity-100 pointer-events-auto;
}

.fixed-social-nav {
  @apply fixed right-[30px] opacity-100 pointer-events-auto top-1/2 opacity-0 pointer-events-none translate-y-1/2;
  transition: 0.4s all ease-in-out;
  z-index: 99;
}

@media screen and (max-width: 1280px) {
  .fixed-social-nav {
    left: unset;
    bottom: 190px;
    @apply right-[15px];
  }
}
.fixed-social-nav ul {
  list-style-type: none;
  @apply col-item pl-0;
}

.fixed-social-nav ul li {
  @apply mb-[10px];
}

.fixed-social-nav ul li a {
  @apply center-item bg-white rounded-full overflow-hidden transition;
  border: 1px solid #999999;
  height: 2.0833333333rem;
  width: 2.0833333333rem;
}

.fixed-social-nav ul li a:hover {
  @apply bg-999999;
}

.fixed-social-nav ul li a:hover em {
  @apply text-white;
}

@screen xl {
  .fixed-social-nav ul li a {
    height: 2.6041666667rem;
    width: 2.6041666667rem;
  }
}
.fixed-social-nav ul li a em {
  @apply text-999999 transition;
  font-size: 1.0416666667rem;
}

@screen xl {
  .fixed-social-nav ul li a em {
    font-size: 1.25rem;
  }
}
.contact-modal-form {
  max-width: 990px;
}

.contact-modal-form .block-wrap-form {
  padding: 0 !important;
}

.contact-modal-form .carousel__button {
  width: 40px !important;
  height: 40px !important;
  top: 0 !important;
}

.contact-modal-form .carousel__button svg {
  @apply text-999999;
}

.contact-us-page .page-banner a {
  height: 39.5833333333rem;
}

.arrow-button .swiper-button-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.arrow-button .swiper-button-prev {
  left: -calc(3.125rem);
}

@media (min-width: 1440px) {
  .arrow-button .swiper-button-prev {
    left: -2.6041666667rem;
  }
}
.arrow-button .swiper-button-prev::after {
  content: "";
}

.arrow-button .swiper-button-next {
  right: -calc(3.125rem);
}

@media (min-width: 1440px) {
  .arrow-button .swiper-button-next {
    right: -2.6041666667rem;
  }
}
.arrow-button .swiper-button-next::after {
  content: "";
}

.arrow-button .swiper-button-prev, .arrow-button .swiper-button-next {
  transition: 0.3s all ease-in-out;
  margin: 0;
  top: 50%;
  background: #FFFFFF;
  transform: translateY(-50%);
  border: 0.1041666667rem solid #CCCCCC;
  width: 2.6041666667rem;
  height: 2.6041666667rem;
  @apply rounded-full;
}

@media (min-width: 1280px) {
  .arrow-button .swiper-button-prev, .arrow-button .swiper-button-next {
    width: 3.3333333333rem;
    height: 3.3333333333rem;
  }
}
.arrow-button .swiper-button-prev::after, .arrow-button .swiper-button-next::after {
  font-weight: 300;
  color: #CCCCCC;
  font-size: 1.3020833333rem;
  line-height: 1.3020833333rem;
  @apply font-Awesome6;
}

@media (min-width: 1280px) {
  .arrow-button .swiper-button-prev::after, .arrow-button .swiper-button-next::after {
    font-size: 1.6666666667rem;
    line-height: 1.6666666667rem;
  }
}
.arrow-button .swiper-button-prev:hover, .arrow-button .swiper-button-next:hover {
  @apply bg-primary-1D4789 border-primary-1D4789;
}

.arrow-button .swiper-button-prev:hover::after, .arrow-button .swiper-button-next:hover::after {
  color: #fff;
}

.arrow-button.arrow-2 .swiper-button-prev, .arrow-button.arrow-2 .swiper-button-next {
  @apply bg-transparent;
}

@media (min-width: 1280px) {
  .arrow-button.arrow-2 .swiper-button-prev, .arrow-button.arrow-2 .swiper-button-next {
    width: 2.9166666667rem;
    height: 2.9166666667rem;
  }
}
.arrow-button.arrow-2 .swiper-button-prev:hover, .arrow-button.arrow-2 .swiper-button-next:hover {
  border: 0.1041666667rem solid #CCCCCC;
}

@media (max-width: 1279.98px) {
  .arrow-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .arrow-button .swiper-button-prev, .arrow-button .swiper-button-next {
    margin-top: calc(10/1920*100*1rem);
    position: static;
    transform: translateY(0);
  }

  .arrow-button .swiper-button-next {
    margin-left: calc(10/1920*100*1rem);
  }
}
@media (max-width: 1280px) {
  .arrow-button.no-responsive {
    display: contents;
  }
}
.arrow-button.no-responsive .swiper-button-prev, .arrow-button.no-responsive .swiper-button-next {
  position: absolute !important;
  margin: 0 !important;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 1599.98px) {
  .arrow-button.no-responsive .swiper-button-prev {
    left: -calc(2.0833333333rem);
  }
}
@media (max-width: 1366px) {
  .arrow-button.no-responsive .swiper-button-prev {
    left: 0;
  }
}
@media (max-width: 1599.98px) {
  .arrow-button.no-responsive .swiper-button-next {
    right: -calc(2.0833333333rem);
  }
}
@media (max-width: 1366px) {
  .arrow-button.no-responsive .swiper-button-next {
    right: 0;
  }
}
@media (max-width: 1280px) {
  .arrow-button.no-responsive .swiper-button-prev {
    left: calc(10/1920*100*1rem);
  }

  .arrow-button.no-responsive .swiper-button-next {
    right: calc(10/1920*100*1rem);
  }
}
.arrow-button.static-arrow-left {
  @apply flex;
}

.arrow-button.static-arrow-left .swiper-button-prev, .arrow-button.static-arrow-left .swiper-button-next {
  @apply static translate-y-0;
}

.arrow-button.static-arrow-left .swiper-button-next {
  @apply ml-10px;
}

.swiper-pagination {
  bottom: -calc(1.0416666667rem) !important;
  line-height: 0 !important;
}

.swiper-pagination span {
  margin: 0 0.2604166667rem;
  border: 0;
  background-color: transparent;
  opacity: 1;
  transition: 0.3s all ease-in-out;
  width: 0.5208333333rem;
  height: 0.5208333333rem;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 0.2604166667rem;
}

.swiper-pagination span.swiper-pagination-bullet-active {
  width: 2.8125rem;
}

.block-title {
  font-family: "UTM Avo";
  @apply text-primary-1D4789 font-bold;
  font-size: 1.875rem;
}

@media (min-width: 1024px) {
  .block-title {
    font-size: 2.2395833333rem;
  }
}
@media (min-width: 1280px) {
  .block-title {
    font-size: 2.5rem;
    line-height: 3.6458333333rem;
  }
}
.block-title.white {
  @apply text-white;
}

.block-title.dark {
  @apply text-222222;
}

.block-title.t-30 {
  font-size: 1.5625rem;
}

.headline {
  @apply text-333333 font-bold transition;
  font-size: 1rem;
}

.headline * {
  @apply text-333333 font-bold transition;
  font-size: 1rem;
}

.headline.line {
  @apply uppercase relative  pb-4 mb-6;
}

@screen xl {
  .headline.line {
    @apply pb-6 mb-8;
  }
}
.headline.line::after {
  content: "";
  @apply block absolute pointer-events-none left-0 bottom-0 z-10  transition bg-primary-ED202E;
  height: 2px;
  width: 80px;
}

.headline.normal {
  @apply font-normal;
}

.headline.normal * {
  @apply font-normal;
}

.headline.blue-1 {
  @apply text-primary-1D4789;
}

.headline.blue-1 * {
  @apply text-primary-1D4789;
}

.headline.blue-1:hover {
  @apply text-primary-ED202E;
}

.headline.blue-1:hover * {
  @apply text-primary-ED202E;
}

.headline.white {
  @apply text-white;
}

.headline.white * {
  @apply text-white;
}

.headline.white:hover {
  @apply text-primary-ED202E;
}

.headline.white:hover * {
  @apply text-primary-ED202E;
}

.headline.t-18 {
  font-size: 1.125rem;
}

.headline.t-18 * {
  font-size: 1.125rem;
}

@media screen and (min-width: 1280px) {
  .headline.t-18 {
    font-size: 0.9375rem;
  }

  .headline.t-18 * {
    font-size: 0.9375rem;
  }
}
.headline.t-20 {
  font-size: 1.25rem;
}

.headline.t-20 * {
  font-size: 1.25rem;
}

@media screen and (min-width: 1280px) {
  .headline.t-20 {
    font-size: 1.0416666667rem;
  }

  .headline.t-20 * {
    font-size: 1.0416666667rem;
  }
}
.headline.t-22 {
  font-size: 1.375rem;
}

.headline.t-22 * {
  font-size: 1.375rem;
}

@media screen and (min-width: 1280px) {
  .headline.t-22 {
    font-size: 1.1458333333rem;
  }

  .headline.t-22 * {
    font-size: 1.1458333333rem;
  }
}
.headline.t-24 {
  font-size: 1.5rem;
}

.headline.t-24 * {
  font-size: 1.5rem;
}

@media screen and (min-width: 1280px) {
  .headline.t-24 {
    font-size: 1.25rem;
  }

  .headline.t-24 * {
    font-size: 1.25rem;
  }
}
.caption {
  @apply text-333333 font-normal;
  font-size: 1rem;
}

.caption strong {
  @apply font-bold;
}

.caption * {
  @apply text-333333 font-normal;
  font-size: 1rem;
}

.caption.blue-1 {
  @apply text-primary-1D4789;
}

.caption.blue-1 * {
  @apply text-primary-1D4789;
}

.caption.white {
  @apply text-white;
}

.caption.white * {
  @apply text-white;
}

.caption.t-14 {
  font-size: 0.875rem;
}

.caption.t-14 * {
  font-size: 0.875rem;
}

@screen xl {
  .caption.t-14 {
    font-size: 0.7291666667rem;
  }

  .caption.t-14 * {
    font-size: 0.7291666667rem;
  }
}
.caption.t-18 {
  font-size: 1.125rem;
}

.caption.t-18 * {
  font-size: 1.125rem;
}

@screen xl {
  .caption.t-18 {
    font-size: 0.9375rem;
  }

  .caption.t-18 * {
    font-size: 0.9375rem;
  }
}
.caption.t-20 {
  font-size: 1.25rem;
}

.caption.t-20 * {
  font-size: 1.25rem;
}

@screen xl {
  .caption.t-20 {
    font-size: 1.0416666667rem;
  }

  .caption.t-20 * {
    font-size: 1.0416666667rem;
  }
}
.caption.t-22 {
  font-size: 1.375rem;
}

.caption.t-22 * {
  font-size: 1.375rem;
}

@screen xl {
  .caption.t-22 {
    font-size: 1.1458333333rem;
  }

  .caption.t-22 * {
    font-size: 1.1458333333rem;
  }
}
.caption.t-24 {
  font-size: 1.5rem;
}

.caption.t-24 * {
  font-size: 1.5rem;
}

@screen xl {
  .caption.t-24 {
    font-size: 1.25rem;
  }

  .caption.t-24 * {
    font-size: 1.25rem;
  }
}
.zone-desc {
  @apply text-333333;
  font-size: 1.25rem;
}

.zone-desc strong {
  @apply font-bold;
}

.zone-desc * {
  @apply text-333333;
  font-size: 1.25rem;
}

.zone-desc.blue-1 {
  @apply text-primary-1D4789;
}

.zone-desc.blue-1 * {
  @apply text-primary-1D4789;
}

.zone-desc.grey-3 {
  @apply text-666666;
}

.zone-desc.grey-3 * {
  @apply text-666666;
}

time {
  @apply text-999999 text-[14px] font-normal text-left block;
}

.ovh {
  overflow: hidden;
}

#fixed-tool {
  position: fixed;
  right: calc(15/1920*100*1rem);
  transition: 0.3s all ease-in-out;
  bottom: 5vh;
  z-index: 50;
  @apply pointer-events-none opacity-0;
}

#fixed-tool.active {
  bottom: 10vh;
  @apply pointer-events-auto opacity-100;
}

#fixed-tool a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(40/1920*100*1rem);
  height: calc(40/1920*100*1rem);
  border-radius: 1000%;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  @apply bg-primary-1D4789;
}

#fixed-tool a + a {
  margin-top: calc(10/1920*100*1rem);
}

@media (min-width: 576px) {
  #fixed-tool a {
    width: calc(50/1920*100*1rem);
    height: calc(50/1920*100*1rem);
  }
}
#fixed-tool a em {
  font-weight: 300;
  font-size: 1.25rem;
  color: #fff;
  transition: 0.3s all ease-in-out;
}

#fixed-tool a:first-child {
  background: rgba(255, 255, 255, 0.5);
}

#fixed-tool a:first-child em {
  color: #666666;
}

#fixed-tool a:hover {
  @apply bg-primary-ED202E;
}

#fixed-tool a:hover em {
  color: #fff;
}

.desktop-show {
  @apply hidden xl:block;
}

.mobile-show {
  @apply block xl:hidden;
}

.search-overlay {
  z-index: 1030;
  top: var(--header-height);
  height: 40vh;
  @apply bg-secondary-0C3E8A shadow-lg opacity-0 pointer-events-none transition-all fixed left-0 w-full flex items-center justify-center;
}

@media (min-width: 1280px) {
  .search-overlay {
    height: 50vh;
  }
}
.search-overlay.active {
  @apply opacity-100 pointer-events-auto;
}

.search-overlay.active .searchbox {
  transform: translateY(0%);
  @apply delay-100 opacity-100;
}

.search-overlay.active .close {
  @apply opacity-100;
}

.search-overlay .close {
  z-index: 2;
  bottom: 100%;
  width: 2.0833333333rem;
  height: 2.0833333333rem;
  @apply flex justify-center pointer-events-none absolute items-center opacity-0 transition-all duration-1000;
}

.search-overlay .close i {
  font-size: 1.5625rem;
  @apply text-white justify-center;
}

.search-overlay .searchbox {
  transform: translateY(100%);
  @apply flex h-13 transition-all relative duration-1000 opacity-0;
}

.search-overlay .searchbox input {
  @apply bg-transparent border-b border-white w-[calc(100%-calc(56/1920*100*1rem))] text-3xl text-white  rounded-none placeholder:text-base placeholder:text-white font-light p-5;
}

.search-overlay .searchbox button {
  @apply pl-4 h-full transition-all;
}

.search-overlay .searchbox button em {
  @apply w-13 text-xl text-white font-light border border-white rounded-sm flex items-center justify-center rounded-sm h-full transition-all;
}

.search-overlay .searchbox button:hover em, .search-overlay .searchbox button:active em {
  @apply bg-secondary-005A79;
}

.tabslet-content {
  @apply hidden;
}

#overlay {
  @apply fixed top-0 left-0 w-full h-full bg-transparent transition-all pointer-events-none xl:hidden;
}

#overlay.active {
  @apply bg-black/50 pointer-events-auto;
}

.list-item-added {
  list-style: disc;
  padding-left: 15px;
}

@media (min-width: 1280px) {
  .list-item-added {
    padding-left: 0.8854166667rem;
  }
}
.list-item-added li::marker {
  @apply text-222222;
}

.icon-80 {
  width: 3.125rem;
  height: 3.125rem;
}

@media (min-width: 1280px) {
  .icon-80 {
    width: 4.1666666667rem;
    height: 4.1666666667rem;
  }
}
.icon-80 i {
  font-weight: 300;
  font-size: 1.3020833333rem;
  line-height: 1.3020833333rem;
  @apply text-white;
}

@media (min-width: 1280px) {
  .icon-80 i {
    font-size: 1.6666666667rem;
    line-height: 1.6666666667rem;
  }
}
body:not(.homepage) .form-group input[type=text], body:not(.homepage) .form-group input[type=tel], body:not(.homepage) .form-group input[type=email], body:not(.homepage) .form-group input[type=number], body:not(.homepage) .form-group input[type=date], body:not(.homepage) .form-group textarea, body:not(.homepage) .form-group select {
  background: #FFFFFF;
  border: 0.0520833333rem solid #CEDEF8;
  border-radius: 0.2604166667rem;
  padding: 0.3645833333rem 1.0416666667rem;
  padding: 0.6510416667rem 1.0416666667rem;
  @apply text-base leading-18px placeholder:text-base w-full block;
}

.wp-pagination ul {
  @apply flex items-center justify-center list-none gap-10px;
}

.wp-pagination li {
  flex: 0 0 2.2916666667rem;
  height: 2.2916666667rem;
  @apply font-bold text-lg leading-5  text-primary-1D4789 transition-all rounded-full border border-secondary-0C3E8A;
}

.wp-pagination li a {
  @apply flex items-center justify-center w-full h-full;
}

.wp-pagination li.active, .wp-pagination li:hover {
  @apply bg-primary-1D4789 border-transparent text-white;
}

.loading-spinner {
  top: 50%;
  transform: translateY(-50%);
  @apply absolute w-full h-full flex items-center transition-all opacity-0 pointer-events-none z-20;
}

.loading-spinner::after {
  content: "";
  @apply absolute w-10 h-10 rounded-full border-4 animate-spin border-gray-100 border-t-primary-ED202E left-1/2;
}

.loading-spinner.active {
  @apply opacity-100;
}

.no-post-found {
  @apply flex items-center text-red-500 text-center justify-center;
}

.no-post-found i {
  font-size: 1.0416666667rem;
  @apply mr-10px;
}

.top-zone-nav ul {
  list-style-type: none;
  @apply pl-0 center-item;
}

.top-zone-nav ul li.active a {
  @apply bg-primary-ED202E text-white;
}

.top-zone-nav ul li a {
  @apply text-666666 font-bold uppercase bg-background-F5F5F5 px-6 center-item;
  height: 50px;
  font-size: 1rem;
}

@screen xl {
  .top-zone-nav ul li a {
    @apply px-8 text-[16px];
  }
}
.page-banner a {
  @apply center-item;
  height: 29.1666666667rem;
  width: auto;
}

.page-banner a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components {
  *,
*::before,
*::after {
    box-sizing: border-box;
  }

  *::-webkit-scrollbar-track {
    background-color: #999;
  }

  *::-webkit-scrollbar {
    width: 0;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 0;
    border: 0.1041666667rem solid #999;
    @apply bg-primary-1D4789;
  }

  html {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  body {
    line-height: 1.5;
    font-weight: 400;
    color: #111;
    @apply font-body;
  }

  img {
    display: inline;
  }
}
.sticky-nav {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  @apply bg-white;
  border-bottom: 1px slid #e5e5e5;
}

.sticky-nav ul {
  @apply gap-item pl-0 list-none;
}

.sticky-nav li {
  @apply px-4;
}

@media screen and (min-width: 1280px) {
  .sticky-nav li {
    @apply px-1;
  }
}
@media screen and (min-width: 1440px) {
  .sticky-nav li {
    @apply px-4;
  }
}
@media screen and (min-width: 1600px) {
  .sticky-nav li {
    @apply px-6;
  }
}
.sticky-nav li.active a {
  @apply text-primary-ED202E;
}

.sticky-nav li.active a::after {
  @apply opacity-100;
}

.sticky-nav a {
  @apply text-666666 relative center-item relative font-bold uppercase;
  height: 50px;
  font-size: 0.8333333333rem;
}

.sticky-nav a::after {
  content: "";
  @apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full bg-primary-ED202E opacity-0;
  height: 2px;
}

@media screen and (max-width: 1024px) {
  .sticky-nav .container {
    @apply overflow-x-auto;
  }

  .sticky-nav .container ul {
    @apply whitespace-nowrap justify-start;
  }
}
.about-section-wrapper .block-title {
  @apply uppercase;
}

.about-us {
  @apply relative py-14;
}

@screen xl {
  .about-us {
    padding: 4.1666666667rem 0 5.2083333333rem;
  }
}
.about-us .row {
  @apply justify-between;
}

.about-us::after {
  content: "";
  @apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-full opacity-20;
  background: linear-gradient(180deg, rgba(29, 71, 137, 0) 0%, #1D4789 100%);
}

.about-us .caption strong {
  @apply text-primary-1D4789;
}

.about-us .wrap-left {
  @apply mt-8;
}

@screen xl {
  .about-us .wrap-left {
    margin-top: 3.6458333333rem;
  }
}
.about-us .img-src {
  @apply relative;
}

.about-us .img-src a {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 66.6666666667%;
}

.about-us .img-src a img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

.about-us .img-src::after {
  content: "";
  @apply block absolute pointer-events-none left-1/2 top-1/2 translate-50 z-10  transition;
  height: 4.6875rem;
  width: 4.6875rem;
  background: url("../img/icon/play.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

@media screen and (max-width: 576px) {
  .about-us .block-title {
    @apply text-center;
  }
}
@media screen and (max-width: 576px) {
  .about-us .wrap-left {
    @apply justify-center;
  }
}
.about-vision .img-src a {
  @apply center-item;
  height: 30.7291666667rem;
  width: auto;
}

.about-vision .img-src a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-vision .item {
  @apply relative;
  padding: 2.6041666667rem 2.0833333333rem;
}

.about-vision .item::after {
  content: "";
  @apply block absolute pointer-events-none right-0 bottom-0 z-10  transition;
  height: 11.4583333333rem;
  width: 15.1041666667rem;
  background: url("../img/icon/icon-2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

@screen lg {
  .about-vision .item {
    height: 14.5833333333rem;
  }
}
.about-vision .item:first-child {
  @apply bg-primary-ED202E;
  margin-bottom: 30px;
}

.about-vision .item:nth-child(2) {
  @apply bg-primary-1D4789;
}

.about-core {
  @apply pb-16;
}

.about-core .icon {
  @apply center-item mb-4;
  height: 60px;
  width: auto;
  min-width: 60px;
}

@screen lg {
  .about-core .icon {
    @apply mb-0;
  }
}
.about-core .icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.about-core .txt-wrap {
  @apply flex-wrap;
}

.about-core .img-wrap a {
  @apply center-item;
  height: 26.3020833333rem;
  width: auto;
}

.about-core .img-wrap a img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.about-core .item {
  @apply flex col-item mb-6 w-1/2;
}

@screen lg {
  .about-core .item {
    @apply flex-row  mb-0 w-full;
  }
}
.about-core .item .headline {
  @apply mb-1;
  font-size: 1.3541666667rem;
}

.about-core .item .txt {
  @apply mr-5;
  order: 2;
}

@screen lg {
  .about-core .item .txt {
    order: 1;
  }
}
.about-core .col {
  @apply p-0;
}

@screen lg {
  .about-core .col:first-child .txt-wrap {
    @apply col-item  h-full;
    justify-content: space-between;
    padding: 4.1666666667rem 0;
  }
}
.about-core .col:first-child .item {
  @apply text-center;
}

@screen lg {
  .about-core .col:first-child .item {
    @apply text-right flex-row-reverse;
  }
}
.about-core .col:first-child .item:first-child .headline {
  @apply text-primary-1D4789;
}

.about-core .col:first-child .item:nth-child(2) .headline {
  @apply text-primary-ED202E;
}

@screen lg {
  .about-core .col:last-child .txt-wrap {
    @apply col-item  h-full;
    justify-content: space-between;
    padding: 4.1666666667rem 0;
  }
}
.about-core .col:last-child .item {
  @apply text-center;
}

@screen lg {
  .about-core .col:last-child .item {
    @apply text-left;
  }
}
.about-core .col:last-child .item .txt {
  @apply ml-5 mr-0;
}

.about-core .col:last-child .item:first-child .headline {
  color: #E0A00A;
}

.about-core .col:last-child .item:nth-child(2) .headline {
  color: #006F76;
}

.about-history {
  background: url("../img/bg/about-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.about-history .img-src a {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 27.3958333333%;
}

.about-history .img-src a img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

.about-history .swiper-history-year {
  @apply relative mx-auto mt-10;
  width: 10.4166666667rem;
}

.about-history .swiper-history-year::after {
  content: "";
  @apply block absolute pointer-events-none left-1/2 translate-x-50  z-10  transition;
  height: 2px;
  background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000000 51.56%, rgba(0, 0, 0, 0) 100%);
  width: 375px;
  bottom: -15px;
}

.about-history .swiper-history-year .years {
  font-size: 3.125rem;
  font-family: "UTM Avo";
  @apply font-bold text-primary-ED202E leading-none text-center;
}

@screen xl {
  .about-history .swiper-history-year .years {
    font-size: 4.1666666667rem;
  }
}
.about-history .swiper-history-year .swiper-nav-wrap {
  padding: 0 !important;
}

.about-history .swiper-history-year .nav-arrow-prev, .about-history .swiper-history-year .nav-arrow-next {
  height: 60px;
  line-height: 60px;
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%);
}

.about-history .swiper-history-year .nav-arrow-prev em, .about-history .swiper-history-year .nav-arrow-next em {
  font-size: 4.1666666667rem;
  @apply leading-none font-black text-background-CCC;
}

.about-history .swiper-history-txt {
  @apply mt-8 text-center;
}

@screen xl {
  .about-history .swiper-history-txt {
    @apply mt-9;
  }
}
.about-history .swiper-history-txt .caption {
  @apply mt-2;
}

.about-history .swiper-history-pagination {
  @apply mt-8;
}

@screen xl {
  .about-history .swiper-history-pagination {
    @apply mt-10;
  }
}
.about-history .swiper-history-pagination .txt {
  @apply text-999999 font-bold uppercase text-center pb-2;
}

.about-history .swiper-history-pagination .swiper-slide {
  @apply relative;
}

.about-history .swiper-history-pagination .swiper-slide::after {
  content: "";
  @apply block absolute pointer-events-none left-1/2 translate-x-50 bottom-0 z-10  transition opacity-0;
  height: 8px;
  width: 57px;
  background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.83) 0%, rgba(0, 0, 0, 0.1) 55.73%, rgba(0, 0, 0, 0) 100%);
}

.about-history .swiper-history-pagination .swiper-slide.swiper-slide-thumb-active::after {
  @apply opacity-100;
}

.about-history .swiper-history-pagination .swiper-slide.swiper-slide-thumb-active .txt {
  @apply text-primary-ED202E;
}

.about-produce {
  background: url("../img/bg/about-bg-2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@screen lg {
  .about-produce .block-title {
    @apply text-left;
  }
}
.about-produce .caption p {
  @apply my-3;
}

.about-produce ul {
  list-style-type: disc;
  @apply pl-5;
}

.about-produce li {
  @apply mb-2;
  display: list-item;
}

.about-produce li em {
  @apply inline-block mr-2 text-primary-1D4789 text-[14px];
  height: 14px;
  line-height: 14px;
}

.about-produce li span {
  @apply text-[16px] text-primary-1D4789 underline;
}

.catalogue-list .row {
  @apply justify-start;
}

.catalogue-item {
  @apply bg-background-F5F5F5 h-full relative;
}

.catalogue-item .img-src a {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 140.350877193%;
}

.catalogue-item .img-src a img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: contain;
  transition: 0.3s ease-in-out all;
}

.catalogue-item .txt {
  @apply px-5 py-4 start-item;
}

.catalogue-item .headline a {
  @apply text-primary-1D4789;
}

.catalogue-item .headline a:hover {
  @apply text-primary-ED202E;
}

.catalogue-item .text-0 {
  @apply absolute top-0 left-0 right-0  w-full h-full  z-40 opacity-0 pointer-events-auto;
}

.catalogue-item .text-0 a {
  @apply block w-full h-full;
}

.contact-us {
  @apply relative;
}

@media screen and (max-width: 1280px) {
  .contact-us {
    @apply pt-16;
  }
}
@screen xl {
  .contact-us {
    margin-top: -130px;
  }
}
.contact-us .block-wrap {
  @apply m-0 p-0 bg-white overflow-hidden;
  background: #FFFFFF;
  border-radius: 20px;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.15);
}

@screen xl {
  .contact-us .block-wrap {
    border-radius: 50px;
  }
}
.contact-us .block-wrap > .col {
  @apply m-0 p-0;
}

.contact-us .block-wrap > .col:nth-child(2) {
  @apply bg-primary-1D4789;
}

.contact-us .wrap-form .wpcf7-select {
  border: 0;
  border-bottom: 1px solid #d9d9d9 !important;
  border-radius: 0 !important;
  padding: 0 !important;
}

.contact-us .wrap-form .frm-btnwrap {
  justify-content: flex-start;
}

.contact-us .wrap-form .frm-btnwrap .frm-btn input[type=submit] {
  color: white;
  border-color: #ED202E !important;
  background: #ED202E !important;
}

.contact-us .block-title {
  font-size: 2.0833333333rem;
  line-height: 1.1;
  @apply mb-6;
}

@media screen and (max-width: 1024px) {
  .contact-us .block-title {
    font-size: 1.875rem;
    @apply mb-5;
  }
}
.block-wrap-form {
  @apply p-10;
}

@screen xl {
  .block-wrap-form {
    padding: 3.125rem 3.90625rem 3.90625rem 3.90625rem;
  }
}
.block-wrap-form .wpcf7-spinner {
  display: none !important;
}

.block-wrap-form .wrap-form br {
  display: none !important;
}

.block-wrap-form .wrap-form .form-group label {
  @apply text-222222  font-bold;
  font-size: 0.8333333333rem;
}

.block-wrap-form .wrap-form .form-group input[type=text], .block-wrap-form .wrap-form .form-group textarea, .block-wrap-form .wrap-form .form-group input {
  border: none !important;
  border-bottom: 1px solid #D9D9D9 !important;
  border-radius: 0 !important;
  padding: 0 !important;
}

.block-wrap-form .wrap-form .form-group input[type=text], .block-wrap-form .wrap-form .form-group input[type=tel], .block-wrap-form .wrap-form .form-group input[type=email] {
  height: 25px;
}

.block-wrap-form .wrap-form .form-group textarea {
  height: 3.6458333333rem;
}

.block-wrap-form .wrap-form .frm-btnwrap .frm-btn:hover::after {
  color: white;
}

.block-wrap-form .wrap-form .frm-btnwrap .frm-btn:hover input[type=submit] {
  color: white;
  border-color: #ED202E !important;
  background: #ED202E !important;
}

.block-wrap-address {
  @apply p-10;
  @apply text-white relative;
}

@screen xl {
  .block-wrap-address {
    padding: 3.125rem 1.5625rem 3.125rem 2.0833333333rem;
  }
}
.block-wrap-address::after {
  content: "";
  @apply block absolute pointer-events-none right-0 bottom-0 z-10  transition;
  height: 11.4583333333rem;
  width: 15.1041666667rem;
  background: url("../img/icon/icon-3.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

.block-wrap-address ul {
  padding-left: 0;
  list-style-type: none;
}

.block-wrap-address li strong {
  @apply text-white font-bold   block;
}

.block-wrap-address li span {
  @apply text-white;
}

.block-wrap-address li:not(last-child) {
  @apply mb-4;
}

@screen xl {
  .block-wrap-address li:not(last-child) {
    @apply mb-6;
  }
}
.contact-department .tab-navigation {
  @apply mb-8;
}

.contact-department .tab-navigation ul {
  @apply flex pl-0;
  list-style-type: none;
}

.contact-department .tab-navigation ul li {
  @apply w-full;
}

.contact-department .tab-navigation ul li:hover span {
  @apply underline;
}

.contact-department .tab-navigation ul li.active a {
  @apply bg-primary-ED202E;
}

.contact-department .tab-navigation ul li.active a em, .contact-department .tab-navigation ul li.active a span {
  @apply text-white;
}

.contact-department .tab-navigation ul li a {
  @apply w-full bg-background-F5F5F5  center-item px-4;
  height: 50px;
}

@media screen and (max-width: 576px) {
  .contact-department .tab-navigation ul li a {
    @apply px-6;
  }
}
.contact-department .tab-navigation ul li a span, .contact-department .tab-navigation ul li a em {
  @apply text-666666;
  font-size: 0.8333333333rem;
}

.contact-department .tab-navigation ul li a span {
  @apply uppercase font-bold ml-3;
}

@media screen and (max-width: 576px) {
  .contact-department .tab-navigation {
    @apply overflow-x-auto;
  }

  .contact-department .tab-navigation ul {
    @apply start-item whitespace-nowrap;
  }
}
.contact-department .headline {
  @apply text-primary-1D4789 font-bold pl-5 start-item items-center bg-background-F5F5F5;
  font-size: 1.0416666667rem;
  height: 2.6041666667rem;
  border-left: 3px solid #1D4789;
  border-bottom: 1px solid #E5E5E5;
}

.contact-department .table-wrap {
  @apply mb-8;
}

@media screen and (max-width: 768px) {
  .contact-department .table-wrap {
    @apply overflow-auto;
  }

  .contact-department .table-wrap table {
    @apply whitespace-nowrap;
  }
}
.contact-department .table-wrap table {
  @apply w-full;
}

.contact-department .table-wrap table thead th {
  @apply px-5 text-333333 font-bold;
  font-size: 0.8333333333rem;
  height: 50px;
  border: 1px solid #E5E5E5;
}

.contact-department .table-wrap table thead th:first-child {
  border-right: 0;
}

.contact-department .table-wrap table thead th:nth-child(2) {
  border-left: 0;
}

.contact-department .table-wrap table thead th * {
  @apply text-333333 font-bold;
  font-size: 0.8333333333rem;
}

.contact-department .table-wrap table tbody tr td {
  @apply bg-white px-5 text-333333;
  height: 50px;
  font-size: 0.7291666667rem;
  border: 1px solid #E5E5E5;
}

.contact-department .table-wrap table tbody tr td * {
  font-size: 0.7291666667rem;
  @apply text-333333;
}

.contact-department .table-wrap table tbody tr td:first-child {
  @apply text-primary-1D4789;
  border-right: 0;
}

@screen xl {
  .contact-department .table-wrap table tbody tr td:first-child {
    width: 14.84375rem;
  }
}
.contact-department .table-wrap table tbody tr td:nth-child(2) {
  border-left: 0;
}

@screen xl {
  .contact-department .table-wrap table tbody tr td:nth-child(2) {
    width: 8.8541666667rem;
  }
}
@screen xl {
  .contact-department .table-wrap table tbody tr td:nth-child(3) {
    width: 8.8541666667rem;
  }
}
@screen xl {
  .contact-department .table-wrap table tbody tr td:nth-child(4) {
    width: 10.4166666667rem;
  }
}
@screen xl {
  .contact-department .table-wrap table tbody tr td:nth-child(5) {
    width: 12.5rem;
  }
}
.contact-department .table-wrap table tbody tr:nth-child(odd) td {
  background: #FAFAFA;
}

.dealer-list .scroll-bar-wrap {
  height: 31.5104166667rem;
}

.dealer-list.contact-dealer {
  @apply pt-0;
}

.dealer-list.contact-dealer .dealer-wrap {
  @apply relative;
}

.dealer-list.contact-dealer .dealer-wrap::after {
  content: "";
  @apply block absolute pointer-events-none left-[20px] top-0 z-10  transition w-full bg-primary-ED202E;
  height: 1px;
}

.dealer-list.contact-dealer .dealer-map-wrapper {
  @apply relative;
}

.dealer-list.contact-dealer .dealer-map-wrapper a {
  height: 31.5104166667rem;
}

@media screen and (max-width: 1024px) {
  .dealer-list.contact-dealer .dealer-map-wrapper a {
    height: 26.0416666667rem;
  }
}
@media screen and (max-width: 576px) {
  .dealer-list.contact-dealer .dealer-map-wrapper a {
    height: 20.8333333333rem;
  }
}
.dealer-list .sub-title {
  @apply text-333333 font-bold uppercase whitespace-nowrap center-item mr-4;
  font-size: 1.0416666667rem;
}

.dealer-list .wrapper-form {
  @apply flex w-full;
}

.dealer-list .wrapper-form .form-group {
  height: 40px;
  @apply w-full;
}

.dealer-list .wrapper-form .form-group:first-child {
  @apply mr-4;
}

.dealer-list .wrapper-form .custom-select select {
  border-radius: 0;
  border: none;
}

.dealer-list .block-form-wrap {
  @apply flex mb-5;
}

.dealer-list .corner {
  @apply absolute right-0 z-10;
  width: 70px;
  height: 70px;
}

.dealer-list .corner:first-child {
  @apply top-0;
  border-top: 5px solid #1D4789;
  border-right: 5px solid #1D4789;
}

.dealer-list .corner:nth-child(2) {
  @apply bottom-0;
  border-bottom: 5px solid #1D4789;
  border-right: 5px solid #1D4789;
}

.dealer-list .dealer-map-wrapper {
  padding: 20px 20px 20px 0;
  position: relative;
}

.dealer-list .dealer-map-wrapper a {
  @apply center-item overflow-hidden;
  height: 37.2395833333rem;
  text-align: center;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .dealer-list .dealer-map-wrapper a {
    height: 26.0416666667rem;
  }
}
@media screen and (max-width: 576px) {
  .dealer-list .dealer-map-wrapper a {
    height: 20.8333333333rem;
  }
}
.dealer-list .dealer-map-wrapper a iframe {
  @apply inline-block w-full h-full;
}

@media screen and (max-width: 576px) {
  .dealer-list .scroll-bar-wrap {
    height: 13.0208333333rem;
  }
}
.dealer-list .toggle-item.active .title span, .dealer-list .toggle-item.active .title em {
  @apply text-primary-ED202E;
}

.dealer-list .toggle-item.active .title em::before {
  content: "";
}

.dealer-list .toggle-item.active .article {
  @apply block;
}

.scroll-bar-wrap {
  direction: rtl;
  height: 33.8541666667rem;
  padding-left: 25px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #A0C4F4 #CCCCCC;
}

.scroll-bar-wrap::-webkit-scrollbar {
  background: #CCCCCC;
  width: 7px;
  border: none !important;
}

.scroll-bar-wrap::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 7px #CCCCCC;
  background-color: #CCCCCC;
  border: none !important;
}

.scroll-bar-wrap::-webkit-scrollbar-thumb {
  border: none !important;
  background: #1D4789;
}

@media screen and (max-width: 1024px) {
  .scroll-bar-wrap {
    height: 26.0416666667rem;
  }
}
.page-template-page-contact .toggle-item .title span, .page-template-page-contact .toggle-item .title em {
  @apply text-primary-ED202E;
}

.page-template-page-contact .toggle-item .title em::before {
  content: "";
}

.page-template-page-contact .toggle-item .article {
  @apply block;
}

.toggle-item {
  border-bottom: 1px solid #CCCCCC;
  direction: ltr;
}

.toggle-item .title {
  @apply flex justify-between items-center py-5;
}

@screen xl {
  .toggle-item .title {
    @apply py-6;
  }
}
.toggle-item .title span {
  @apply text-333333 font-bold;
  font-size: 1.0416666667rem;
}

.toggle-item .title em {
  @apply text-primary-1D4789;
  font-size: 16px;
  line-height: 1;
}

.toggle-item .article {
  @apply hidden pb-5;
}

.toggle-item .article ul {
  list-style-type: none;
  padding-left: 0;
}

.toggle-item .article li {
  @apply flex justify-start mb-1;
  align-items: flex-start;
}

.toggle-item .article em {
  @apply text-primary-ED202E  mr-4 inline-block;
  padding-top: 8px;
  font-size: 16px;
  line-height: 1;
}

.toggle-item .article span {
  @apply text-333333 font-normal;
}

.toggle-item.active .title span, .toggle-item.active .title em {
  @apply text-primary-ED202E;
}

.toggle-item.active .title em::before {
  content: "";
}

.toggle-item-2 {
  border-bottom: 1px solid #CCCCCC;
  direction: ltr;
}

.toggle-item-2 .title {
  @apply flex justify-between items-center py-5;
}

@screen xl {
  .toggle-item-2 .title {
    @apply py-6;
  }
}
.toggle-item-2 .title span {
  @apply text-primary-ED202E font-bold;
  font-size: 1.0416666667rem;
}

.toggle-item-2 .title em {
  @apply text-primary-ED202E;
  font-size: 16px;
  line-height: 1;
}

.toggle-item-2 .title em::before {
  content: "";
}

.toggle-item-2 .article {
  @apply pb-5;
}

.toggle-item-2 .article ul {
  list-style-type: none;
  padding-left: 0;
}

.toggle-item-2 .article li {
  @apply start-item mb-1 items-center;
}

.toggle-item-2 .article em {
  @apply text-primary-ED202E  mr-4 inline-block;
  font-size: 16px;
  line-height: 1;
}

.toggle-item-2 .article span {
  @apply text-333333 font-normal;
}

.about-process .top-zone-nav {
  @apply mb-10;
}

@screen xl {
  .about-process .top-zone-nav {
    @apply mb-[70px];
  }
}
@screen lg {
  .about-process .block-wrap .row {
    @apply m-0 p-0 justify-between relative;
  }

  .about-process .block-wrap .row:not(:last-child) {
    @apply pb-10;
  }

  @screen xl {
    .about-process .block-wrap .row:not(:last-child) {
      @apply pb-[60px];
    }
  }
  .about-process .block-wrap .row::after {
    content: "";
    width: 1px;
    @apply block absolute pointer-events-none top-0 z-10 transition h-full bg-background-E5E5E5 left-1/2 translate-x-50;
  }

  .about-process .block-wrap .row:nth-child(even) {
    @apply flex-row-reverse;
  }

  .about-process .block-wrap .row:nth-child(even) .number::after {
    left: unset;
    @apply right-full;
  }
}
@screen lg {
  .about-process .block-wrap .col {
    @apply m-0 p-0;
  }
}
.about-process .number {
  @apply bg-primary-ED202E  rounded-full text-white font-bold center-item  mx-auto;
  font-size: 0.8333333333rem;
  height: 25px;
  width: 25px;
}

@screen lg {
  .about-process .number {
    @apply absolute left-1/2 translate-x-50 z-30;
  }

  .about-process .number::after {
    content: "";
    @apply block absolute pointer-events-none left-full top-1/2 translate-y-50 z-10  transition bg-background-E5E5E5;
    height: 1px;
    width: 3.6458333333rem;
  }
}
.about-process .headline {
  @apply text-center my-4;
}

@screen lg {
  .about-process .headline {
    @apply text-left  mt-0;
  }
}
.about-process .img-wrap a {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 57.1428571429%;
}

.about-process .img-wrap a img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

.about-process .txt-wrap {
  @apply pb-8;
}

@screen lg {
  .about-process .txt-wrap {
    @apply pb-0;
  }
}
.about-process .caption {
  font-size: 0.8333333333rem;
  @apply text-333333;
}

.about-process .caption * {
  font-size: 0.8333333333rem;
  @apply text-333333;
}

.about-process .caption ul li {
  @apply mb-3;
}

.about-process .caption ul ol, .about-process .caption ul ul {
  list-style-type: circle;
  @apply pl-5;
}

.search-page {
  background-color: #f0f0f2;
}

.search-page .search-form form {
  @apply relative;
}

.search-page .search-form form input {
  padding: 0.5208333333rem 1.0416666667rem;
  border: thin solid;
  padding-right: 2.34375rem;
  @apply w-full bg-transparent transition-all border-primary-ED202E rounded-lg;
}

.search-page .search-form form input:not(:placeholder-shown) {
  @apply bg-primary-ED202E/[.15] border-transparent;
}

.search-page .search-form form button {
  width: 2.0833333333rem;
  @apply h-full absolute top-0 right-0 flex items-center justify-center;
}

.search-page .search-query {
  @apply my-15px;
}

.search-page .found-nothing {
  background-image: url(../img/nothing.png);
  height: 30vh;
  @apply bg-no-repeat bg-center;
}

.search-page .found-nothing-title {
  @apply text-xl uppercase relative font-bold  text-primary-1D4789;
}

#main-banner .img a {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 46.875%;
}

#main-banner .img a img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

#main-banner .container {
  left: 50%;
  transform: translateX(-50%);
  bottom: 2.0833333333rem;
  @apply absolute;
}

@media (min-width: 1280px) {
  #main-banner .container {
    bottom: 7.2916666667rem;
  }
}
#main-banner .container .content {
  padding: 0.5208333333rem 1.0416666667rem;
  @apply relative w-fit;
}

@media (min-width: 1280px) {
  #main-banner .container .content {
    padding: 1.1458333333rem 1.0416666667rem 1.4583333333rem 2.3958333333rem;
  }
}
#main-banner .container .content::before {
  content: "";
  z-index: -1;
  @apply bg-primary-1D4789/[.60] absolute w-full h-full top-0 left-0;
}

@media (min-width: 1280px) {
  #main-banner .block-title {
    line-height: 3.125rem;
  }
}
@media (max-width: 575.98px) {
  #main-banner .block-title {
    font-size: 1.0416666667rem;
    line-height: inherit;
  }
}
#main-banner .swiper-pagination {
  bottom: 10px !important;
}

@media (min-width: 1280px) {
  #main-banner .swiper-pagination {
    bottom: 2.0833333333rem !important;
  }
}
.global-breadcrumb {
  @apply bg-background-F5F5F5;
}

@media screen and (max-width: 576px) {
  .global-breadcrumb {
    @apply py-2;
  }
}
.global-breadcrumb .rank-math-breadcrumb {
  min-height: 50px;
}

.global-breadcrumb .rank-math-breadcrumb > p {
  @apply flex items-center flex-wrap  h-full;
  justify-content: flex-start;
}

.global-breadcrumb .rank-math-breadcrumb > p > * {
  @apply flex justify-start;
}

.global-breadcrumb .rank-math-breadcrumb span.separator {
  font-size: 0;
  @apply mx-10px flex items-center;
}

.global-breadcrumb .rank-math-breadcrumb span.separator::before {
  content: "|";
  @apply text-999999 font-light text-base leading-4 font-Awesome6;
}

.global-breadcrumb .rank-math-breadcrumb a, .global-breadcrumb .rank-math-breadcrumb span {
  padding: 0.546875rem 0;
  max-height: 40px;
  @apply font-normal text-[14px] leading-4 text-999999 flex items-center;
}

.global-breadcrumb .rank-math-breadcrumb a:first-child, .global-breadcrumb .rank-math-breadcrumb span:first-child {
  font-size: 0;
}

.global-breadcrumb .rank-math-breadcrumb a:first-child::before, .global-breadcrumb .rank-math-breadcrumb span:first-child::before {
  content: "";
  @apply font-Awesome6 font-light text-base leading-4 text-999999;
}

.global-breadcrumb .rank-math-breadcrumb + li {
  @apply ml-15px pl-15px relative;
}

.global-breadcrumb .rank-math-breadcrumb + li::before {
  content: "|";
  @apply font-Awesome6 absolute top-1/2 -translate-y-1/2 left-0 text-999999 text-base;
}

footer {
  @apply text-white relative;
}

footer::after {
  content: "";
  @apply block absolute pointer-events-none left-[56px] bottom-0 z-10  transition;
  height: 320px;
  width: 330px;
  background: url("../img/bg/logo-bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

footer .box-info .title {
  @apply font-extrabold text-base leading-24px mb-15px uppercase;
}

footer .box-info .ctn {
  @apply font-normal text-sm leading-27px;
}

footer .box-info .icon i {
  @apply font-light text-base leading-4 opacity-80 mr-15px;
}

footer .box-info .item {
  @apply flex items-baseline;
}

footer .box-nav nav > ul > li > a {
  @apply font-extrabold text-base leading-24px mb-15px block;
}

footer .box-nav nav > ul > li > ul > li {
  @apply transition-all;
}

footer .box-nav nav > ul > li > ul > li.active, footer .box-nav nav > ul > li > ul > li:hover, footer .box-nav nav > ul > li > ul > li[class*=current-] {
  @apply text-primary-ED202E;
}

footer .box-nav nav > ul > li > ul > li > a {
  @apply font-normal text-sm leading-26px;
}

footer .social-icons {
  @apply flex items-center justify-center sm:justify-start;
}

footer .social-icons a {
  width: 2.5rem;
  height: 2.5rem;
  @apply flex items-center justify-center transition-all;
}

footer .social-icons a:hover {
  @apply shadow-xl text-primary-ED202E rounded-full;
}

footer .social-icons a + a {
  @apply ml-10px;
}

footer .social-icons a i {
  @apply text-2xl;
}

footer .social-icons a img {
  height: 30px;
  width: auto;
}

footer .bct img {
  width: 7.03125rem;
  height: 2.65625rem;
}

footer .footer-icon img {
  width: 10.7291666667rem;
  height: 2.3958333333rem;
}

footer .copyright {
  @apply font-normal text-xs leading-18px text-center sm:text-left;
}

footer .footer-link {
  @apply font-normal text-xs leading-18px flex sm:justify-end justify-center;
}

footer .footer-link ul {
  @apply flex items-center;
}

footer .footer-link ul li {
  @apply transition-all;
}

footer .footer-link ul li.active, footer .footer-link ul li:hover, footer .footer-link ul li[class*=current-] {
  @apply text-primary-ED202E;
}

footer .footer-link ul li + li {
  @apply ml-5px pl-5px relative;
}

footer .footer-link ul li + li::before {
  content: "";
  height: 0.9375rem;
  width: 0.0520833333rem;
  @apply absolute left-0 top-1/2 -translate-y-1/2 bg-white;
}

footer .grid.top {
  @apply pt-14 pb-10;
}

footer .grid.middle {
  @apply py-3;
}

@media (max-width: 575.98px) {
  footer .grid.middle {
    text-align: center;
  }
}
footer .grid.bottom {
  @apply pt-15px pb-5;
}

footer .container > .grid + .grid {
  border-top: 0.0520833333rem solid rgba(255, 255, 255, 0.15);
}

header {
  z-index: 150;
  @apply fixed left-0 top-0 w-full shadow-lg bg-white;
}

header .button-wrapper {
  @apply xl:gap-5 sm:gap-10px gap-0;
}

@media (min-width: 1280px) {
  header .button-wrapper .button-2 .btn.btn-header {
    @apply bg-primary-ED202E text-white border-transparent;
  }

  header .button-wrapper .button-2 .btn.btn-header:hover {
    @apply bg-primary-1D4789;
  }
}
@media screen and (max-width: 576px) {
  header .button-wrapper .button {
    @apply w-1/2 mx-4;
  }
}
@media screen and (min-width: 576px) and (max-width: 1279.98px) {
  header .button-wrapper .button .btn.btn-header {
    font-size: 0;
    width: 32px;
    height: 32px;
    @apply bg-transparent border-none;
  }

  header .button-wrapper .button .btn.btn-header i {
    @apply mr-0 text-xl text-primary-1D4789;
  }
}
@media screen and (min-width: 576px) and (max-width: 1279.98px) and (max-width: 576px) {
  header .button-wrapper .button .btn.btn-header i {
    @apply text-[24px] text-white;
  }
}
@media screen and (min-width: 576px) and (max-width: 1279.98px) {
  header .button-wrapper .button .btn.btn-header:hover {
    @apply border-primary-1D4789;
  }

  header .button-wrapper .button .btn.btn-header:hover i {
    @apply text-primary-1D4789;
  }
}
@media (max-width: 575px) {
  header .button-wrapper .button .btn.btn-header {
    width: auto;
    border-color: white;
    color: white;
    height: 2.0833333333rem;
  }

  header .button-wrapper .button .btn.btn-header:hover {
    @apply border-primary-1D4789;
  }
}
header .top {
  padding-top: 1.0416666667rem;
  padding-bottom: 1.0416666667rem;
  @apply flex items-center justify-between;
}

@media (min-width: 1280px) {
  header .top {
    padding-top: 0.5208333333rem;
    padding-bottom: 0;
  }
}
@media (max-width: 1279.98px) {
  header .top .left {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    @apply absolute;
  }
}
header .top .left .desktop-show {
  width: 26.1458333333rem;
  height: 2.34375rem;
}

header .top .left .mobile-show {
  height: 50px;
  @apply object-contain;
}

header .top .right {
  @apply flex items-center;
}

header .top .search {
  width: 25px;
  height: 25px;
  @apply xl:px-15px xl:ml-5 sm:ml-10px sm:mr-10px xl:mr-15px flex items-center justify-center;
}

@media (min-width: 576px) {
  header .top .search {
    width: 32px;
    height: 32px;
  }
}
@media (min-width: 1280px) {
  header .top .search {
    width: auto;
    height: 1.0416666667rem;
    border-left: thin solid #ccc;
    border-right: thin solid #ccc;
  }
}
header .top .search .search-icon {
  @apply flex;
}

header .top .search i {
  @apply text-lg sm:text-xl xl:text-base font-normal text-primary-1D4789;
}

@media screen and (max-width: 576px) {
  header .top .search i {
    @apply text-[18px];
  }
}
header .language {
  width: auto;
}

header .language .wpml-ls-legacy-dropdown-click {
  width: auto !important;
}

header .language .wpml-ls-legacy-dropdown-click a:hover {
  background: none !important;
}

header .language .wpml-ls-legacy-dropdown-click .wpml-ls-item-toggle {
  padding: 0 !important;
}

header .language .wpml-ls-legacy-dropdown-click .wpml-ls-item-toggle::after {
  @apply hidden;
}

header .language .wpml-ls-current-language:hover .wpml-ls-sub-menu .wpml-ls-item {
  opacity: 1 !important;
  pointer-events: auto !important;
  background: white;
}

header .language .wpml-ls-current-language:hover .wpml-ls-sub-menu .wpml-ls-item .wpml-ls-display::after {
  display: none;
}

header .language ul {
  @apply relative text-primary-1D4789 text-lg sm:text-xl xl:text-sm leading-18px;
}

header .language ul a {
  @apply flex items-center justify-center;
  border: none !important;
  color: #1D4789 !important;
}

header .language ul a span {
  @apply flex items-center justify-center w-full;
  color: #1D4789 !important;
}

header .language ul > li {
  @apply transition-all;
}

header .language ul > li.wpml-ls-current-language .wpml-ls-display::after {
  @apply hidden;
}

header .language ul > li.wpml-ls-current-language span::after {
  content: "";
  @apply font-Awesome6 ml-5px xl:ml-10px text-xs sm:text-base;
  color: #1D4789 !important;
}

header .language ul > li:not(.wpml-ls-current-language) {
  padding: 0.2604166667rem;
  @apply absolute top-full left-1/2 -translate-x-1/2 opacity-0 pointer-events-none w-full;
}

header .bottom {
  margin-top: 0.2604166667rem;
}

header .bottom nav a {
  @apply transition-all;
}

header .bottom nav > ul {
  @apply flex items-center justify-end;
}

header .bottom nav > ul > li {
  padding-bottom: 0.5989583333rem;
}

header .bottom nav > ul > li.active > a, header .bottom nav > ul > li:hover > a, header .bottom nav > ul > li[class*=current-] > a {
  @apply text-primary-1D4789;
}

header .bottom nav > ul > li + li {
  @apply ml-10;
}

header .bottom nav > ul > li > a {
  @apply font-bold text-sm leading-18px uppercase text-333333;
}

header .burger {
  @apply relative;
}

header .burger i {
  @apply mr-0 text-2xl text-primary-1D4789 transition-all duration-500;
}

@media screen and (max-width: 576px) {
  header .burger i {
    @apply text-[24px];
  }
}
header .burger i:nth-of-type(2) {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(1.1);
  @apply absolute opacity-0;
}

header .burger.active i:nth-of-type(1) {
  @apply opacity-0 scale-75;
}

header .burger.active i:nth-of-type(2) {
  transform: translate(-50%, -50%) scale(1);
  @apply opacity-100;
}

header .nav-mobile {
  top: var(--header-height, 0);
  height: calc( 100vh - var(--header-height));
  transition: all 0.75s cubic-bezier(0.85, 0, 0.15, 1);
  max-width: 320px;
  left: -100%;
  background: linear-gradient(135deg, #1D4789 0%, #0059E0 99.62%);
  @apply fixed w-full overflow-y-auto;
  height: 100vh;
}

header .nav-mobile .button-wrapper {
  padding-bottom: 30px;
}

header .nav-mobile.active {
  border-radius: 0;
  @apply left-0;
}

header .nav-mobile.active nav li {
  @apply opacity-100 scale-100;
}

header .nav-mobile .nav-mobile-wrapper {
  padding: 1.5625rem 0.78125rem;
  height: 310px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: red #cccccc;
  @apply leading-relaxed text-justify;
}

header .nav-mobile .nav-mobile-wrapper::-webkit-scrollbar {
  background: #cccccc;
  width: 3px;
}

header .nav-mobile .nav-mobile-wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #cccccc;
  background-color: #cccccc;
}

header .nav-mobile .nav-mobile-wrapper::-webkit-scrollbar-thumb {
  background-color: red;
}

header .nav-mobile nav a {
  @apply transition-all;
}

header .nav-mobile nav > ul {
  @apply flex flex-col;
}

header .nav-mobile nav > ul > li {
  padding-bottom: 0.5989583333rem;
  @apply opacity-0 scale-90 transition-all duration-1500;
}

header .nav-mobile nav > ul > li.active > a, header .nav-mobile nav > ul > li:hover > a, header .nav-mobile nav > ul > li[class*=current-] > a {
  @apply text-primary-ED202E;
}

header .nav-mobile nav > ul > li > a {
  @apply text-lg leading-18px uppercase text-white font-bold;
}

@media screen and (max-width: 1279.2px) {
  .header-site-nav .title {
    @apply wrap-gap w-full;
  }
}
.header-site-nav .title > a {
  @apply font-bold text-sm leading-18px uppercase text-333333;
}

@media screen and (max-width: 1279.1px) {
  .header-site-nav .title > a {
    @apply text-lg leading-18px uppercase text-white;
  }
}
.header-site-nav .title em {
  @apply text-white end-item;
  width: 40px;
  height: 20px;
}

@screen xl {
  .header-site-nav .title em {
    @apply hidden;
  }
}
.header-site-nav .drop-down {
  @apply relative;
}

@media screen and (max-width: 1279.1px) {
  .header-site-nav .drop-down {
    @apply mb-3;
  }
}
@screen xl {
  .header-site-nav .drop-down.active .title > a, .header-site-nav .drop-down:hover .title > a, .header-site-nav .drop-down[class*=current-] .title > a {
    @apply text-primary-1D4789;
  }
}
@screen xl {
  .header-site-nav .drop-down:hover .mega-menu-wrap {
    @apply opacity-100 pointer-events-auto;
  }
}
.header-site-nav .drop-down .headline {
  @apply uppercase;
}

@screen xl {
  .header-site-nav .drop-down .mega-menu-wrap {
    @apply absolute left-0  h-auto  bg-white opacity-0 pointer-events-none;
    width: max-content;
    top: 100%;
    border-top: 1px solid #CCCCCC;
  }
}
@media screen and (max-width: 1279.1px) {
  .header-site-nav .drop-down .mega-menu-wrap {
    @apply hidden pl-6;
  }
}
.header-site-nav .drop-down .mega-menu-wrap .title a {
  @apply w-full;
  text-transform: inherit !important;
  font-weight: 400 !important;
}

.header-site-nav .drop-down .mega-menu-wrap .title em {
  @apply hidden;
}

.header-site-nav .drop-down .mega-menu-wrap li:hover .mega-menu-wrap {
  @apply opacity-100 pointer-events-auto;
}

.header-site-nav .drop-down .mega-menu-wrap .mega-menu-wrap {
  left: 100%;
  top: 0;
  border-top: 0;
  @apply opacity-0 pointer-events-none;
}

.header-site-nav .drop-down .mega-menu-wrap a {
  border-bottom: 1px solid #E5E5E5;
  @apply start-item  py-2 pr-12 items-center text-sm;
  height: 40px;
}

@screen xl {
  .header-site-nav .drop-down .mega-menu-wrap a {
    height: 50px;
    @apply px-5;
  }

  .header-site-nav .drop-down .mega-menu-wrap a:hover {
    @apply text-primary-ED202E;
  }
}
@media screen and (max-width: 1279.1px) {
  .header-site-nav .drop-down .mega-menu-wrap a {
    @apply text-white text-left;
  }
}
@screen xl {
  .header-site-nav .drop-mega.active .title > a, .header-site-nav .drop-mega:hover .title > a, .header-site-nav .drop-mega[class*=current-] .title > a {
    @apply text-primary-1D4789;
  }
}
@screen xl {
  .header-site-nav .drop-mega:hover .mega-menu-wrap {
    @apply opacity-100 pointer-events-auto;
  }
}
.header-site-nav .drop-mega .headline {
  @apply uppercase;
}

@screen xl {
  .header-site-nav .drop-mega .mega-menu-wrap {
    @apply fixed left-0 bottom-0 w-full  bg-white py-8 opacity-0 transition pointer-events-none;
    border-top: 1px solid #CCCCCC;
    border-bottom: 1px solid #CCCCCC;
    top: var(--header-height);
    height: fit-content;
  }
}
@media screen and (max-width: 1279.1px) {
  .header-site-nav .drop-mega .mega-menu-wrap {
    @apply hidden pt-4;
  }

  .header-site-nav .drop-mega .mega-menu-wrap ul {
    @apply pl-4;
  }
}
.header-site-nav .drop-mega .mega-menu-wrap .container {
  @apply flex;
}

@media screen and (max-width: 1279.1px) {
  .header-site-nav .drop-mega .mega-menu-wrap .container {
    @apply flex-col p-0;
  }
}
.header-site-nav .drop-mega .mega-menu-wrap .container > ul {
  @apply w-full flex flex-wrap;
  justify-content: flex-start;
}

.header-site-nav .drop-mega .mega-menu-wrap .container > ul > li {
  @apply w-full;
}

@media screen and (max-width: 1280px) {
  .header-site-nav .drop-mega .mega-menu-wrap .container > ul > li {
    @apply mb-4;
  }
}
@screen xl {
  .header-site-nav .drop-mega .mega-menu-wrap .container > ul > li {
    width: 33.333333%;
  }
}
@media screen and (max-width: 1279.1px) {
  .header-site-nav .drop-mega .mega-menu-wrap .row {
    @apply justify-start py-6;
  }

  .header-site-nav .drop-mega .mega-menu-wrap .row .col {
    @apply mb-5;
  }
}
.header-site-nav .drop-mega .mega-menu-wrap .nav-col-menu li {
  @apply mb-2;
}

.header-site-nav .drop-mega .mega-menu-wrap .nav-col-menu li:hover a {
  @apply text-primary-ED202E;
}

.header-site-nav .drop-mega .mega-menu-wrap .nav-col-menu li a {
  @apply text-white;
  font-size: 0.8333333333rem;
}

@screen xl {
  .header-site-nav .drop-mega .mega-menu-wrap .nav-col-menu li a {
    @apply text-333333;
  }
}
.header-site-nav .drop-mega .mega-menu-wrap .img-wrap {
  margin-top: auto;
}

@media screen and (max-width: 1279.1px) {
  .header-site-nav .drop-mega .mega-menu-wrap .img-wrap {
    @apply hidden;
  }
}
.header-site-nav .drop-mega .mega-menu-wrap .img-wrap a {
  @apply center-item;
  height: 280px;
  width: auto;
}

.header-site-nav .drop-mega .mega-menu-wrap .img-wrap a img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.header-site-nav .drop-mega .mega-menu-wrap .headline {
  @apply mb-3 text-white normal-case;
  font-size: 0.9375rem;
}

.header-site-nav .drop-mega .mega-menu-wrap .headline a {
  @apply text-white;
  font-size: 0.9375rem;
}

@screen xl {
  .header-site-nav .drop-mega .mega-menu-wrap .headline {
    font-size: 1.0416666667rem;
    @apply mb-4 text-primary-1D4789;
  }

  .header-site-nav .drop-mega .mega-menu-wrap .headline a {
    font-size: 1.0416666667rem;
    @apply mb-4 text-primary-1D4789;
  }
}
main {
  margin-top: var(--header-height);
}

.index-1::before {
  content: "";
  background: linear-gradient(180deg, rgba(29, 71, 137, 0) 0%, #1D4789 100%);
  opacity: 0.2;
  z-index: -1;
  @apply absolute w-full h-full top-0 left-0 pointer-events-none;
}

.index-1 .ctn {
  max-width: 56.25rem;
  @apply text-base font-normal leading-24px text-center mx-auto;
}

.index-1 .ctn strong {
  @apply text-primary-1D4789;
}

.index-1 .list {
  @apply text-center grid grid-cols-4 mt-10 gap-5;
}

@media screen and (max-width: 576px) {
  .index-1 .list {
    @apply grid-cols-2;
  }
}
.index-1 .img-wrap {
  width: 7.8125rem;
  height: 7.8125rem;
  @apply relative flex items-center justify-center flex-col text-white mx-auto rounded-full overflow-hidden bg-primary-ED202E transition;
}

.index-1 .img-wrap img {
  @apply transition;
}

.index-1 .img-wrap::before {
  content: "";
  background-image: url(../img/icon/icon-1.png);
  background-size: contain;
  z-index: 2;
  @apply bg-no-repeat w-full h-full top-0 left-0 absolute;
}

.index-1 .img-wrap:hover {
  @apply bg-white;
}

.index-1 .img-wrap:hover img {
  filter: brightness(0.3);
}

.index-1 .img-wrap:hover .number, .index-1 .img-wrap:hover .unit {
  @apply text-primary-ED202E;
}

.index-1 .img-wrap:hover::before {
  filter: brightness(0.6);
}

.index-1 .title {
  @apply mt-15px;
}

.index-1 .value {
  @apply flex items-center;
}

.index-1 .value span {
  line-height: 2.6041666667rem;
}

.index-1 .value .number {
  font-size: 2.0833333333rem;
  letter-spacing: -0.003125rem;
  @apply font-extrabold transition;
}

.index-1 .value .unit {
  font-size: 1.875rem;
  margin-top: -0.78125rem;
  @apply font-bold transition;
}

.index-1 .wrap-center {
  @apply pt-8;
}

@screen xl {
  .index-1 .wrap-center {
    @apply py-12;
  }
}
.home-cable {
  background: #EFF4F6;
}

.home-cable .four-swiper-no-gap {
  @apply mt-8;
}

@screen xl {
  .home-cable .four-swiper-no-gap {
    @apply mt-12;
  }
}
.home-cable .product-item {
  @apply px-3 py-6;
}

.home-cable .product-item:hover {
  @apply bg-white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
}

.home-cable .block-title span {
  @apply text-primary-ED202E;
}

.product-item {
  @apply transition;
}

.product-item .img-1 {
  @apply relative z-10 center-item;
}

.product-item .img-1 a {
  @apply center-item rounded-full overflow-hidden;
  width: 14.5833333333rem;
  height: 14.5833333333rem;
}

.product-item .img-1 a img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.product-item .img-2 {
  @apply relative  z-20 center-item;
  margin-top: -90px;
}

.product-item .img-2 a {
  @apply center-item rounded-full;
  width: 9.8958333333rem;
  height: 9.6354166667rem;
}

.product-item .img-2 a img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.product-item:hover .headline {
  @apply text-primary-ED202E;
}

.product-item:hover .headline * {
  @apply text-primary-ED202E;
}

.product-item .headline {
  @apply mt-4 text-center;
}

@screen xl {
  .product-item .headline {
    @apply mt-6;
  }
}
.home-certificate {
  @apply bg-background-F7F9FA;
}

.home-certificate .row {
  @apply justify-between;
}

@media screen and (max-width: 1024px) {
  .home-certificate .row .col:first-child {
    order: 2;
  }

  .home-certificate .row .col:nth-child(2) {
    order: 1;
  }
}
@media screen and (max-width: 1024px) {
  .home-certificate .block-title {
    @apply text-center;
  }
}
.home-certificate .zone-desc {
  @apply text-[16px];
}

@media screen and (max-width: 1024px) {
  .home-certificate .zone-desc {
    order: 2;
    @apply pt-8;
  }
}
@screen xl {
  .home-certificate .zone-desc {
    font-size: 16px;
  }

  .home-certificate .zone-desc * {
    font-size: 16px;
  }

  .home-certificate .zone-desc p {
    font-size: 16px;
  }
}
.home-certificate .nav-arrow-next {
  right: -40px;
}

.home-certificate .nav-arrow-prev {
  left: -40px;
}

@screen xl {
  .home-certificate .block-wrap {
    @apply pr-16;
  }
}
.home-certificate .swiper-certificate-thumb {
  @apply mt-8 relative;
}

@media screen and (max-width: 1024px) {
  .home-certificate .swiper-certificate-thumb {
    @apply mt-0;
  }
}
@screen xl {
  .home-certificate .swiper-certificate-thumb {
    @apply mt-12;
  }
}
@screen xl {
  .home-certificate .swiper-certificate-thumb .swiper {
    height: 420px;
  }
}
@media screen and (min-width: 1440.1px) {
  .home-certificate .swiper-certificate-thumb .swiper {
    height: 580px;
  }
}
@screen xl {
  .home-certificate .swiper-certificate-thumb .swiper-slide {
    height: 190px;
  }
}
@media screen and (min-width: 1440.1px) {
  .home-certificate .swiper-certificate-thumb .swiper-slide {
    height: 270px;
  }
}
.home-certificate .swiper-certificate-thumb .item {
  @apply py-4 px-3 bg-white overflow-hidden;
  border: 1px solid #E0E0E0;
  border-radius: 20px;
}

@media screen and (max-width: 1280px) {
  .home-certificate .swiper-certificate-thumb .img-src a {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 142.4242424242%;
  }

  .home-certificate .swiper-certificate-thumb .img-src a img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: contain;
    transition: 0.3s ease-in-out all;
  }
}
@screen xl {
  .home-certificate .swiper-certificate-thumb .img-src a {
    @apply center-item;
    height: 155px;
    width: auto;
  }

  .home-certificate .swiper-certificate-thumb .img-src a img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
@media screen and (min-width: 1440.1px) {
  .home-certificate .swiper-certificate-thumb .img-src a {
    height: 235px;
  }
}
.home-certificate .swiper-certificate-main {
  @apply p-3;
}

.home-certificate .swiper-certificate-main .swiper {
  @apply p-3;
}

.home-certificate .swiper-certificate-main .item {
  @apply py-8 px-3 bg-white overflow-hidden;
  border: 1px solid #E0E0E0;
  border-radius: 20px;
  box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.1);
}

@screen xl {
  .home-certificate .swiper-certificate-main .item {
    @apply px-4 py-12;
  }
}
@media screen and (max-width: 1280px) {
  .home-certificate .swiper-certificate-main .img-src a {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 129.9559471366%;
  }

  .home-certificate .swiper-certificate-main .img-src a img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: contain;
    transition: 0.3s ease-in-out all;
  }
}
@screen xl {
  .home-certificate .swiper-certificate-main .img-src a {
    @apply center-item;
    height: 390px;
    width: auto;
  }

  .home-certificate .swiper-certificate-main .img-src a img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
@media screen and (min-width: 1440.1px) {
  .home-certificate .swiper-certificate-main .img-src a {
    height: 590px;
  }
}
.home-led {
  background: #EFF4F7;
}

.home-led .four-swiper-no-gap {
  @apply mt-8;
}

@screen xl {
  .home-led .four-swiper-no-gap {
    @apply mt-12;
  }
}
.home-led .product-item {
  @apply px-3 py-6;
}

.home-led .product-item:hover {
  @apply bg-white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
}

@screen lg {
  .home-dealer .row {
    background: url("../img/bg/map-2.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
  }
}
@media screen and (max-width: 1024px) {
  .home-dealer .row .col:first-child {
    background: url("../img/bg/map-2.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
  }
}
.home-dealer .dealer-map-wrapper {
  padding: 0;
}

.home-dealer .dealer-map-wrapper a {
  @apply center-item overflow-hidden;
  height: 500px;
  text-align: center;
  width: 100%;
  border-radius: 20px;
}

@media screen and (max-width: 1024px) {
  .home-dealer .dealer-map-wrapper a {
    height: 400px;
  }
}
@media screen and (max-width: 576px) {
  .home-dealer .dealer-map-wrapper a {
    height: 300px;
  }
}
.home-dealer .dealer-map-wrapper a iframe {
  @apply inline-block w-full h-full;
}

.home-dealer .image-map-wrapper {
  @apply center-item;
}

@screen lg {
  .home-dealer .image-map-wrapper {
    @apply pl-16 start-item;
  }
}
.home-dealer .image-map-wrapper img {
  opacity: 1 !important;
}

.tooltipster-sidetip .tooltipster-arrow {
  display: none !important;
}

.tooltipster-sidetip .tooltipster-content {
  padding: 0 !important;
}

.tooltipster-sidetip .tooltipster-box {
  background: #F5F5F5 !important;
  border: 2px solid #FFFFFF !important;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1) !important;
  border-radius: 20px !important;
  @apply p-5;
}

.tooltipster-sidetip ul {
  list-style-type: none;
  padding-left: 0;
}

.tooltipster-sidetip ul li {
  @apply mb-2 start-item;
}

.tooltipster-sidetip ul li:last-child {
  @apply mb-2;
}

.tooltipster-sidetip ul em {
  @apply inline-block mt-1 text-primary-1D4789 mr-[10px];
  font-size: 16px;
  height: 16px;
  line-height: 16px;
}

.tooltipster-sidetip ul span {
  @apply text-[14px] text-333333 leading-normal;
}

.tooltipster-sidetip .title {
  @apply text-primary-1D4789 font-semibold block text-left pb-2 mb-3;
  border-bottom: 1px solid #E0E0E0;
}

.home-news-partner-bg {
  background: url("../img/bg/index-bg-4.png"), #F5F5F5;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
}

.home-news {
  @apply pb-0;
}

.home-news .wrap-center {
  @apply pt-8;
}

@screen xl {
  .home-news .wrap-center {
    @apply pt-10;
  }
}
.news-item {
  border-radius: 10px;
  @apply overflow-hidden bg-white;
}

.news-item.big {
  box-shadow: 0px 4px 10px 0px #0000000D;
}

.news-item.big .txt {
  @apply p-[30px] pb-16;
}

@media screen and (max-width: 576px) {
  .news-item.big .txt {
    @apply px-4 pb-10;
  }
}
.news-item.big .headline:hover {
  @apply text-primary-ED202E;
}

.news-item.big .headline:hover * {
  @apply text-primary-ED202E;
}

@media screen and (max-width: 576px) {
  .news-item.big .headline {
    font-size: 1.25rem;
  }

  .news-item.big .headline * {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 1280px) {
  .news-item.big .img-src a {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 58.3333333333%;
  }

  .news-item.big .img-src a img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    transition: 0.3s ease-in-out all;
  }
}
@screen xl {
  .news-item.big .img-src a {
    @apply center-item;
    height: 350px;
    width: auto;
  }

  .news-item.big .img-src a img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.news-item.side {
  box-shadow: 0px 4px 10px 0px #0000000D;
  @apply flex;
}

.news-item.side:not(:last-child) {
  @apply mb-[10px];
}

@media screen and (max-width: 576px) {
  .news-item.side:not(:last-child) {
    @apply mb-8;
  }
}
@media screen and (max-width: 576px) {
  .news-item.side {
    @apply flex-col;
  }
}
.news-item.side .txt {
  @apply p-5;
}

@media screen and (max-width: 576px) {
  .news-item.side time {
    @apply mb-1;
  }
}
@screen sm {
  .news-item.side .img-src {
    width: 15.1041666667rem;
    min-width: 15.1041666667rem;
  }

  .news-item.side .img-src a {
    @apply center-item;
    height: 170px;
    width: auto;
  }

  .news-item.side .img-src a img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
@media screen and (max-width: 576px) {
  .news-item.side .img-src a {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 58.6206896552%;
  }

  .news-item.side .img-src a img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    transition: 0.3s ease-in-out all;
  }
}
.news-item.side .headline {
  @apply font-normal;
}

.news-item.side .headline * {
  @apply font-normal;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.news-item.side .headline:hover {
  @apply text-primary-ED202E;
}

.news-item.side .headline:hover * {
  @apply text-primary-ED202E;
}

@media screen and (max-width: 576px) {
  .news-item.side .headline {
    font-size: 1.0416666667rem;
  }

  .news-item.side .headline * {
    font-size: 1.0416666667rem;
  }
}
.home-project {
  @apply bg-primary-1D4789 relative;
}

@media screen and (max-width: 1280px) {
  .home-project .img-src a {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 54.9019607843%;
  }

  .home-project .img-src a img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    transition: 0.3s ease-in-out all;
  }
}
@media screen and (min-width: 1280.1px) {
  .home-project .img-src a {
    @apply center-item;
    height: 460px;
    width: auto;
  }

  .home-project .img-src a img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
@media screen and (min-width: 1440px) {
  .home-project .img-src a {
    height: 560px;
  }
}
.home-project .img-wrap {
  @apply relative;
}

.home-project .title {
  @apply text-white uppercase font-bold absolute right-0 bottom-0 p-4 z-20;
}

@screen sm {
  .home-project .title {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
  }
}
.home-project .txt {
  @apply pt-5  transition;
}

@screen xl {
  .home-project .txt {
    @apply pt-6;
  }
}
.home-project .swiper-slide {
  @apply relative;
}

@media screen and (min-width: 1280.1px) {
  .home-project .swiper-slide .img-wrap::after {
    content: "";
    @apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-full bg-black opacity-40;
  }
}
@media screen and (min-width: 1280.1px) and (min-width: 1280.1px) {
  .home-project .swiper-slide.swiper-slide-active {
    width: 820px !important;
  }
}
@media screen and (min-width: 1280.1px) and (min-width: 1440.1px) {
  .home-project .swiper-slide.swiper-slide-active {
    width: 1020px !important;
  }
}
@media screen and (min-width: 1280.1px) {
  .home-project .swiper-slide.swiper-slide-active .img-wrap::after {
    @apply opacity-0;
  }
}
@media screen and (min-width: 1280.1px) {
  .home-project .swiper-slide:not(.swiper-slide-active) {
    width: 260px !important;
  }

  .home-project .swiper-slide:not(.swiper-slide-active) .txt {
    @apply opacity-0;
  }
}
.home-project .item {
  @apply transition;
}

.home-project .nav-arrow-next, .home-project .nav-arrow-prev {
  @apply static;
  transform: none !important;
  left: unset !important;
  right: unset !important;
  top: unset !important;
}

.home-project .swiper-nav-wrap {
  @apply center-item pt-12;
}

@media screen and (min-width: 1280.1px) {
  .home-project .swiper-nav-wrap {
    @apply absolute pt-0;
    bottom: 50px;
    right: 14.0625rem;
  }
}
.home-project .home-project-swiper {
  @apply relative;
}

@media screen and (min-width: 1280.1px) {
  .home-project .home-project-swiper .swiper {
    @apply overflow-visible;
  }
}
.home-project .caption {
  height: 60px !important;
  @apply overflow-hidden;
}

.home-partner .nav-arrow-next {
  right: -40px;
}

.home-partner .nav-arrow-prev {
  left: -40px;
}

.home-partner .block-title {
  @apply uppercase;
}

.partner-item a {
  @apply center-item bg-white py-5;
  border: 1px solid #E5E5E5;
  height: 6.25rem;
  width: auto;
}

.partner-item a img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.home-service {
  @apply relative;
}

.home-service::after {
  content: "";
  @apply block absolute pointer-events-none  bottom-0 z-10  transition;
  height: 25.78125rem;
  width: 24.7916666667rem;
  background: url("../img/bg/logo-bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  left: 1.5625rem;
}

.home-service .block-wrap {
  @apply relative;
}

@media screen and (max-width: 1280px) {
  .home-service .container {
    @apply bg-primary-1D4789;
  }
}
@screen xl {
  .home-service .container {
    @apply absolute top-1/2 left-1/2 translate-50;
  }
}
.home-service .container .row {
  @apply my-0;
}

.home-service .container .row .col {
  @apply my-0;
}

.home-service .container-fluid {
  @apply px-4;
}

.home-service .container-fluid .row {
  @apply m-0 p-0 w-full;
}

.home-service .container-fluid .row .col {
  @apply m-0 p-0;
}

.home-service .container-fluid .row .col:first-child {
  @apply bg-primary-1D4789;
}

.home-service .container-fluid .row .col:nth-child(2) {
  @apply bg-white;
}

.home-service .icon {
  @apply center-item my-6 pb-6;
  height: 60px;
  width: auto;
  min-width: 60px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

@screen xl {
  .home-service .icon {
    @apply justify-start pb-6  my-0;
  }
}
.home-service .icon img {
  width: auto;
  height: 100%;
  object-fit: contain;
}

.home-service .number-wrap {
  @apply mt-8 center-item pb-8;
}

@screen xl {
  .home-service .number-wrap {
    @apply mt-10 justify-start pb-0;
  }
}
.home-service .number-item {
  @apply relative;
}

.home-service .percent {
  @apply absolute bottom-0 right-[50px] text-white text-[24px];
}

@screen xl {
  .home-service .percent {
    font-size: 1.6666666667rem;
  }
}
@media screen and (max-width: 1440px) {
  .home-service .percent {
    right: 40px;
  }
}
@media screen and (max-width: 1280px) {
  .home-service .percent {
    right: 15px;
  }
}
.home-service .number {
  @apply pr-4 start-item;
}

@screen xl {
  .home-service .number {
    margin-top: -3.6458333333rem;
  }
}
.home-service .number span {
  @apply text-white font-extrabold  center-item;
  font-size: 4.1666666667rem;
  height: 60px;
  line-height: 1;
}

@screen xl {
  .home-service .number span {
    font-size: 8.3333333333rem;
    height: 120px;
  }
}
.home-service .number span:first-child {
  @apply mt-16;
}

.home-service .number span:nth-child(2) {
  @apply mt-8;
}

.home-service .catalogue {
  @apply pl-4;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}

.home-service .title {
  @apply text-[24px] text-white font-semibold leading-tight mb-4;
}

@screen xl {
  .home-service .title {
    @apply mb-5;
    font-size: 2.5rem;
  }
}
.home-service .btn-catalogue {
  @apply start-item;
}

.home-service .btn-catalogue em, .home-service .btn-catalogue span {
  @apply text-secondary-8998C2;
  font-size: 1.25rem;
}

.home-service .btn-catalogue em {
  @apply mr-4;
}

.chart-wrap {
  @apply py-10 start-item;
}

@screen xl {
  .chart-wrap {
    @apply py-[30px];
  }
}
.chart-wrap .menuB {
  @apply hidden;
}

.chart-wrap .titles {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: bold;
  color: #FFFFFF;
  padding: 3px;
  line-height: 11px;
  background: #005CA9;
}

.chart-wrap img {
  @apply w-full;
}

.chart-wrap .tabs {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 11px;
  color: #005BA8;
  font-weight: bold;
  @apply text-center;
}

.chart-wrap .tabs a {
  color: #222222;
}

.chart-wrap .top {
  @apply hidden;
}

.chart-wrap .top img {
  @apply hidden;
}

.chart-wrap table {
  width: 285px;
}

.chart-wrap table table tr:first-child img {
  @apply hidden;
}

.chart-wrap .item {
  @apply center-item px-6;
}

@media screen and (min-width: 1280.1px) {
  .chart-wrap .item {
    padding: 0 4.1666666667rem;
  }
}
@media screen and (max-width: 576px) {
  .chart-wrap .item {
    @apply col-item;
  }
}
.chart-wrap .ico {
  @apply center-item;
  height: 64px;
  width: auto;
}

.chart-wrap .ico img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.chart-wrap .caption {
  @apply text-[16px] mt-4;
}

@screen md {
  .chart-wrap .caption {
    @apply ml-4 mt-0 text-[20px];
  }
}
@screen xl {
  .chart-wrap .caption {
    max-width: 175px;
    @apply ml-5;
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 1280px) {
  .chart-wrap .img-wrap a {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 52.0833333333%;
  }

  .chart-wrap .img-wrap a img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    transition: 0.3s ease-in-out all;
  }
}
@screen xl {
  .chart-wrap .img-wrap a {
    @apply center-item;
    height: 400px;
    width: auto;
  }
}
@media screen and (min-width: 1440.1px) {
  .chart-wrap .img-wrap a {
    height: 500px;
  }
}
.chart-wrap .img-wrap a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home-choose-us {
  background: url("../img/bg/index-bg-2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.home-choose-us .block-title span {
  @apply text-primary-ED202E;
}

.home-choose-us .item .icon {
  height: 7.8125rem;
  width: 7.8125rem;
  @apply center-item overflow-hidden rounded-full bg-white mx-auto mb-4;
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.09);
}

@screen xl {
  .home-choose-us .item .icon {
    @apply mb-6;
  }
}
.home-choose-us .item .icon a {
  @apply center-item;
  height: 64px;
  width: auto;
}

.home-choose-us .item .icon a img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.home-choose-us .row {
  @apply justify-between px-10;
}

@media screen and (max-width: 576px) {
  .home-choose-us .row {
    @apply justify-center;
  }

  .home-choose-us .row .col:last-child {
    @apply w-full;
  }
}
.home-choose-us .caption {
  max-width: 240px;
  @apply mx-auto;
}

.news-detail .ez-toc-title-container {
  @apply hidden;
}

.news-detail .table-wrapper {
  @apply mb-8;
}

@screen xl {
  .news-detail .table-wrapper {
    @apply mb-12;
  }
}
@media screen and (max-width: 1024px) {
  .news-detail .table-wrapper {
    @apply overflow-x-auto;
  }

  .news-detail .table-wrapper table {
    @apply whitespace-nowrap;
  }
}
.news-detail .table-wrapper table {
  @apply w-full;
}

.news-detail .table-wrapper table thead th {
  @apply text-333333 py-3 px-4;
  font-size: 1rem;
}

.news-detail .table-wrapper table tbody td {
  @apply text-333333 py-3 px-4;
  font-size: 1rem;
  border-bottom: 1px solid #E5E5E5;
}

@screen xl {
  .news-detail .table-wrapper table tbody td {
    font-size: 0.8333333333rem;
  }
}
.news-detail #ez-toc-container {
  @apply w-full block;
  background: #F5F5F5 !important;
  border: none;
  padding: 20px 40px;
}

.news-detail #ez-toc-container .ez-toc-heading-1 {
  @apply text-[16px];
  color: #666 !important;
}

.news-detail #ez-toc-container .ez-toc-heading-3 {
  color: #999 !important;
}

.news-detail #ez-toc-container .ez-toc-heading-level-3 a {
  color: #999 !important;
}

.news-detail .news-detail-toc {
  @apply bg-background-F5F5F5  py-5;
}

.news-detail .news-detail-toc p {
  @apply text-[16px] font-bold;
}

.news-detail .news-detail-toc ol {
  @apply font-semibold;
  counter-reset: section;
  margin: 0;
  padding: 0 0 0 30px;
}

.news-detail .news-detail-toc ol a {
  @apply font-normal text-666666  text-[16px];
}

.news-detail .news-detail-toc ol li {
  padding: 0 30px;
  position: relative;
  counter-increment: numb;
}

.news-detail .news-detail-toc ol li::before {
  counter-increment: section;
  content: counters(section, ".") " ";
  @apply center-item absolute pointer-events-none    z-10 font-normal transition  text-666666;
  left: 5px;
}

.news-detail .news-detail-toc ol li ol {
  padding: 0;
}

.news-detail .news-detail-toc ol li ol li {
  padding: 0 0 0 30px;
}

.news-detail .news-detail-toc ol li ol li::before {
  @apply text-999999;
  left: 0;
  width: 16px;
}

.news-detail .news-detail-toc ol li ol a {
  @apply text-999999;
}

.news-detail .block-wrap time {
  @apply text-222222 mb-5;
  font-size: 0.8333333333rem;
}

.news-detail .briefcontent {
  @apply text-333333 font-bold my-8;
  font-size: 0.9375rem;
}

.news-detail .briefcontent * {
  @apply text-333333 font-bold;
  font-size: 0.9375rem;
}

.news-detail .fullcontent {
  @apply text-333333 font-normal py-8;
  font-size: 19px !important;
}

.news-detail .fullcontent * {
  @apply text-333333 font-normal  mb-2;
  font-size: 19px !important;
}

.news-detail .fullcontent * * {
  font-size: 19px !important;
}

.news-detail .fullcontent p {
  font-size: 19px !important;
}

.news-detail .fullcontent p * {
  font-size: 19px !important;
}

@media screen and (max-width: 576px) {
  .news-detail .fullcontent {
    font-size: 14px !important;
  }

  .news-detail .fullcontent * {
    font-size: 14px !important;
  }
}
.news-detail .fullcontent ul {
  list-style-type: disc;
  padding-left: 20px;
}

.news-detail .fullcontent img, .news-detail .fullcontent iframe {
  @apply block mx-auto my-4;
}

.news-detail .fullcontent strong {
  @apply font-bold;
}

.news-detail .fullcontent h2, .news-detail .fullcontent h3, .news-detail .fullcontent h4 {
  @apply text-primary-1D4789 font-bold my-3;
}

.news-detail .fullcontent h2 *, .news-detail .fullcontent h3 *, .news-detail .fullcontent h4 * {
  @apply text-primary-1D4789 font-bold;
}

.pager {
  padding: 30px 0 0 0 !important;
  list-style-type: none;
  @apply center-item;
}

.pager li {
  margin-left: 10px;
}

.pager li.active span {
  width: 40px;
  height: 40px;
  @apply center-item overflow-hidden rounded-full bg-primary-ED202E  font-normal text-white;
  font-size: 0.9375rem;
}

.pager li a {
  width: 40px;
  height: 40px;
  @apply center-item overflow-hidden rounded-full bg-primary-1D4789  font-normal text-white;
  font-size: 0.9375rem;
}

.pager li a span {
  font-size: 18px;
  line-height: 14px;
  height: 14px;
}

.news-display .block-title {
  @apply uppercase;
}

.news-display .top-zone-nav {
  @apply mb-8;
}

@screen xl {
  .news-display .top-zone-nav {
    @apply mb-10;
  }
}
@media screen and (max-width: 576px) {
  .news-display .top-zone-nav {
    @apply overflow-x-auto;
  }

  .news-display .top-zone-nav ul {
    @apply whitespace-nowrap justify-start;
  }
}
@media screen and (max-width: 768px) {
  .news-display .swiper-pagination {
    position: static !important;
    @apply mt-5;
  }
}
@screen md {
  .news-display .swiper-pagination {
    bottom: 2.6041666667rem !important;
    right: 7.8125rem !important;
    width: fit-content !important;
    left: unset !important;
  }
}
.news-display .swiper-pagination-bullet {
  background: #ccc !important;
  border-radius: 0 !important;
  width: 10px !important;
  height: 5px !important;
}

.news-display .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #ED202E !important;
  width: 50px !important;
}

.news-display .news-wrap-bottom {
  @apply mt-[30px];
}

.news-display .news-wrap-bottom .news-figure:not(:last-child) {
  @apply mb-[30px];
}

@media screen and (max-width: 576px) {
  .news-display .news-wrap-bottom .news-figure:not(:last-child) {
    @apply mb-[20px];
  }
}
.most-view-wrap .sub-title {
  @apply text-333333 font-bold uppercase pb-3;
  border-bottom: 1px solid #E5E5E5;
  font-family: "UTM Avo";
  font-size: 1.5625rem;
}

@media screen and (max-width: 576px) {
  .most-view-wrap .sub-title {
    @apply mb-8;
  }
}
@media screen and (max-width: 576px) {
  .most-view-wrap .news-figure-side {
    @apply flex bg-background-F5F5F5 overflow-hidden  mb-8;
    flex-direction: column;
    border-radius: 10px;
    padding: 10px;
  }

  .most-view-wrap .news-figure-side .img-src {
    flex: 1 0 auto;
    @apply w-full;
  }

  .most-view-wrap .news-figure-side .img-src a {
    @apply w-full;
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 58.3333333333%;
  }

  .most-view-wrap .news-figure-side .img-src a img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    transition: 0.3s ease-in-out all;
  }

  .most-view-wrap .news-figure-side .txt {
    @apply w-full;
    flex: 0 1 auto;
    padding: 0.78125rem 0 0.5208333333rem;
  }

  .most-view-wrap .news-figure-side time {
    @apply mb-2 text-[14px];
  }

  .most-view-wrap .news-figure-side .headline {
    @apply text-222222 font-bold;
    font-size: 1.0416666667rem;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .most-view-wrap .news-figure-side .headline * {
    @apply text-222222 font-bold;
    font-size: 1.0416666667rem;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .most-view-wrap .news-figure-side .headline:hover {
    @apply text-primary-ED202E;
  }

  .most-view-wrap .news-figure-side .headline:hover * {
    @apply text-primary-ED202E;
  }

  .most-view-wrap .news-figure-side .caption {
    @apply text-666666 mt-3;
    font-size: 0.7291666667rem;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .most-view-wrap .news-figure-side .caption * {
    @apply text-666666;
  }
}
.news-figure-side {
  @apply flex py-4 items-center;
  border-bottom: 1px solid #E5E5E5;
}

@screen xl {
  .news-figure-side {
    @apply py-5;
  }
}
.news-figure-side .img-src a {
  @apply center-item overflow-hidden;
  height: 70px;
  width: 120px;
  min-width: 120px;
  border-radius: 5px;
}

.news-figure-side .img-src a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.news-figure-side .headline a {
  font-size: 0.7291666667rem;
  @apply font-normal leading-normal inline-block;
}

.news-figure-side .headline a:hover {
  @apply text-primary-ED202E;
}

.news-figure-side .txt {
  @apply ml-3;
}

.news-figure-side time {
  font-size: 0.625rem;
}

.news-figure-big {
  @apply bg-background-F5F5F5 overflow-hidden flex;
  border-radius: 10px;
  padding: 10px;
}

@media screen and (max-width: 768px) {
  .news-figure-big {
    @apply col-item;
  }
}
.news-figure-big .img-src {
  @apply overflow-hidden w-full;
  border-radius: 10px;
}

@screen md {
  .news-figure-big .img-src {
    width: 26.8229166667rem;
    min-width: 26.8229166667rem;
  }
}
@media screen and (max-width: 1280px) {
  .news-figure-big .img-src a {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 56.3106796117%;
  }

  .news-figure-big .img-src a img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    transition: 0.3s ease-in-out all;
  }
}
@screen xl {
  .news-figure-big .img-src a {
    @apply center-item;
    height: 15.1041666667rem;
    width: auto;
  }

  .news-figure-big .img-src a img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.news-figure-big .txt {
  @apply py-6;
}

@screen md {
  .news-figure-big .txt {
    @apply p-4 px-6;
  }
}
@screen xl {
  .news-figure-big .txt {
    padding: 0.5208333333rem 1.3020833333rem 1.0416666667rem 1.5625rem;
  }
}
.news-figure-big time {
  @apply mb-2;
}

.news-figure-big .headline {
  line-height: 1.4;
  @apply text-222222 line-clamp-4;
}

.news-figure-big .headline * {
  @apply text-222222;
}

.news-figure-big .headline:hover {
  @apply text-primary-ED202E;
}

.news-figure-big .headline:hover * {
  @apply text-primary-ED202E;
}

.news-figure-big .caption {
  @apply text-666666 mt-3;
  font-size: 0.7291666667rem;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.news-figure-big .caption * {
  @apply text-666666;
  font-size: 0.7291666667rem;
}

.news-figure {
  @apply bg-background-F5F5F5 overflow-hidden flex;
  border-radius: 10px;
  padding: 10px;
}

@media screen and (max-width: 576px) {
  .news-figure {
    @apply col-item;
  }
}
.news-figure .img-src {
  @apply overflow-hidden;
  border-radius: 10px;
}

@media screen and (max-width: 576px) {
  .news-figure .img-src {
    @apply w-full;
  }
}
@screen sm {
  .news-figure .img-src {
    width: 19.2708333333rem;
    min-width: 19.2708333333rem;
  }
}
@media screen and (max-width: 1280px) {
  .news-figure .img-src a {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 55.2631578947%;
  }

  .news-figure .img-src a img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    transition: 0.3s ease-in-out all;
  }
}
@screen xl {
  .news-figure .img-src a {
    @apply center-item;
    height: 10.9375rem;
    width: auto;
  }

  .news-figure .img-src a img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.news-figure .txt {
  @apply p-4;
}

@screen xl {
  .news-figure .txt {
    padding: 0.5208333333rem 1.3020833333rem 1.0416666667rem 1.5625rem;
  }
}
.news-figure time {
  @apply mb-2;
}

.news-figure .headline {
  line-height: 1.4;
  @apply text-222222;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.news-figure .headline * {
  @apply text-222222;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.news-figure .headline:hover {
  @apply text-primary-ED202E;
}

.news-figure .headline:hover * {
  @apply text-primary-ED202E;
}

.news-figure .caption {
  @apply text-666666 mt-3;
  font-size: 0.7291666667rem;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.news-figure .caption * {
  @apply text-666666;
  font-size: 0.7291666667rem;
}

.product-detail-banner {
  @apply bg-background-F5F5F5;
}

.product-detail-banner .item {
  @apply relative;
}

@screen sm {
  .product-detail-banner .container {
    @apply left-1/2 top-1/2 translate-50 z-10 absolute;
  }
}
@media screen and (max-width: 1280px) {
  .product-detail-banner .img-wrap a {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 25.5208333333%;
  }

  .product-detail-banner .img-wrap a img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    transition: 0.3s ease-in-out all;
  }
}
@screen xl {
  .product-detail-banner .img-wrap a {
    @apply center-item;
    height: 25.5208333333rem;
    width: auto;
  }

  .product-detail-banner .img-wrap a img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.product-detail-banner .title {
  font-size: 1.4583333333rem;
  line-height: 1;
  font-family: "UTM Avo";
  @apply text-primary-1D4789 font-bold block mb-5;
}

@screen lg {
  .product-detail-banner .title {
    font-size: 2.0833333333rem;
  }
}
.product-detail-banner .caption {
  @apply font-bold;
  font-size: 0.8333333333rem;
}

@screen lg {
  .product-detail-banner .caption {
    font-size: 1.0416666667rem;
  }
}
.product-detail-banner .btn-video {
  @apply bg-primary-1D4789 justify-start mt-8;
  padding: 0 8px;
  height: 50px;
  border-radius: 30px;
  width: 160px;
}

@screen sm {
  .product-detail-banner .btn-video {
    @apply bg-white;
  }
}
@screen lg {
  .product-detail-banner .btn-video {
    padding: 0 10px;
  }
}
.product-detail-banner .btn-video .icon {
  height: 40px;
  width: 40px;
  @apply center-item bg-primary-1D4789 rounded-full;
}

@screen lg {
  .product-detail-banner .btn-video .icon {
    height: 50px;
    width: 50px;
  }
}
.product-detail-banner .btn-video .icon em {
  font-size: 20px;
  @apply text-white;
}

@screen lg {
  .product-detail-banner .btn-video .icon em {
    font-size: 25px;
  }
}
.product-detail-banner .btn-video span {
  @apply text-white text-[16px] font-bold uppercase ml-5;
}

@screen sm {
  .product-detail-banner .btn-video span {
    @apply text-primary-1D4789;
  }
}
@screen lg {
  .product-detail-banner .btn-video {
    height: 60px;
  }
}
.product-detail-banner .txt {
  @apply text-center py-8;
}

@screen sm {
  .product-detail-banner .txt {
    width: 20.8333333333rem;
  }
}
@screen xl {
  .product-detail-banner .txt {
    margin-left: 7%;
  }
}
@media screen and (max-width: 1024px) {
  .product-information .table-responsive {
    @apply overflow-x-auto;
  }

  .product-information .table-responsive table {
    @apply whitespace-nowrap;
  }
}
.product-information table {
  @apply w-full;
}

.product-information table tr:first-child td {
  @apply font-bold  px-2 py-3  text-center;
}

.product-information table tr td {
  border: 1px solid #E5E5E5;
  @apply py-3 text-333333 px-2 font-medium text-center;
  height: 50px;
  font-size: 0.8333333333rem;
  font-weight: 500 !important;
}

.product-information table tr td * {
  @apply text-333333  block text-center;
  font-weight: 500 !important;
}

.product-information table tr td:first-child {
  @apply font-bold text-222222  pr-5;
}

.product-information .tab-navigation {
  @apply relative z-50 mb-7;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
}

@screen lg {
  .product-information .tab-navigation {
    margin-top: -30px;
  }
}
.product-information .tab-navigation ul {
  list-style-type: none;
  @apply gap-item p-0;
}

.product-information .tab-navigation ul li {
  @apply w-full;
}

.product-information .tab-navigation ul li:hover a {
  @apply underline text-primary-ED202E;
}

.product-information .tab-navigation ul li.active a {
  @apply bg-primary-ED202E text-white;
}

.product-information .tab-navigation ul a {
  height: 4.1666666667rem;
  @apply text-999999 uppercase font-bold center-item bg-white w-full;
  font-size: 0.8333333333rem;
}

@media screen and (max-width: 576px) {
  .product-information .tab-navigation {
    @apply overflow-x-auto;
  }

  .product-information .tab-navigation ul {
    @apply whitespace-nowrap;
  }

  .product-information .tab-navigation ul a {
    @apply px-4;
  }
}
.product-information .product-catalog-contact {
  @apply pt-8;
}

@media screen and (max-width: 768px) {
  .product-information .product-catalog-contact .wrap-center {
    @apply col-item;
  }
}
.product-information .product-catalog-contact .btn {
  minwidth: 13.0208333333rem;
}

@media screen and (max-width: 768px) {
  .product-information .product-catalog-contact .btn {
    @apply my-4;
  }
}
.product-information .headline {
  @apply text-center;
  text-transform: none !important;
}

.product-information .headline * {
  text-transform: none !important;
}

.product-information .headline::after {
  left: 50% !important;
  transform: translateX(-50%);
}

.product-information .block-wrap {
  @apply py-5;
  border-top: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
}

.product-information .row-custom {
  @apply relative  my-0;
  margin: 0 -20px;
}

@screen lg {
  .product-information .row-custom {
    column-count: 2;
  }
}
@screen lg {
  .product-information .row-custom::after {
    content: "";
    @apply block absolute pointer-events-none left-1/2 translate-x-50 bottom-0 z-10  transition bg-background-CCC h-full;
    width: 1px;
  }
}
.product-information .row-custom .item-col {
  box-sizing: border-box;
  @apply my-0;
  break-inside: avoid;
  padding: 0 20px;
}

.product-information .row-custom .item-col:last-child .item {
  border-bottom: 0;
}

.product-information .item {
  @apply py-8 start-item w-full;
  break-inside: avoid;
  border-bottom: 1px solid #CCCCCC;
}

@media screen and (max-width: 576px) {
  .product-information .item {
    @apply col-item;
  }
}
.product-information .item .icon {
  @apply center-item p-2 bg-primary-1D4789;
  width: 60px;
  height: 50px;
  min-width: 60px;
  transform: skew(-10deg);
}

.product-information .item .icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform: skew(10deg);
}

.product-information .item .txt {
  @apply ml-5 text-333333 font-normal w-full;
  font-size: 0.8333333333rem;
}

@media screen and (max-width: 576px) {
  .product-information .item .txt {
    @apply ml-0 mt-5;
  }
}
.product-information .item .txt p {
  @apply mb-4;
}

.product-information .item .txt * {
  @apply text-333333 font-normal;
  font-size: 0.8333333333rem;
}

.product-information .item .txt ul {
  list-style-type: disc;
  padding-left: 20px;
}

.product-information .item .txt ul li {
  font-size: 0.8333333333rem;
  @apply text-333333 mb-1;
}

.product-information .item .txt ul li * {
  font-size: 0.8333333333rem;
  @apply text-333333;
}

.product-information .item .txt img {
  @apply w-full;
}

@screen xl {
  .product-information .item .txt {
    @apply ml-6;
  }
}
.product-information .item .txt h3, .product-information .item .txt h4 {
  @apply text-333333 font-bold block mb-4;
  font-size: 1.0416666667rem;
}

@media screen and (max-width: 576px) {
  .product-information .item .txt h3, .product-information .item .txt h4 {
    @apply text-center;
  }
}
.product-contact-form {
  @apply bg-background-F5F5F5 py-14;
}

@screen xl {
  .product-contact-form {
    padding: 3.125rem 0 4.1666666667rem;
  }
}
.product-contact-form .zone-desc {
  font-size: 1.0416666667rem;
  @apply mb-6;
}

.product-contact-form .wrap-form .row {
  margin-bottom: 20px;
}

.product-chart {
  @apply bg-background-E5E5E5 py-10;
}

.product-chart .item {
  @apply col-item;
}

@screen lg {
  .product-chart .item {
    @apply start-item flex-row;
  }
}
.product-chart .item .icon {
  @apply center-item;
  height: 60px;
  width: auto;
}

.product-chart .item .icon i, .product-chart .item .icon em {
  font-size: 60px;
  @apply text-666666;
}

.product-chart .item .icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.product-chart .txt {
  @apply text-center;
}

@screen lg {
  .product-chart .txt {
    @apply ml-10 text-left;
  }
}
.product-chart .headline {
  @apply text-center my-4;
}

@screen lg {
  .product-chart .headline {
    @apply mb-1 text-left mt-0;
  }
}
.product-other .swiper-slide {
  @apply h-auto;
}

.product-zone-nav {
  @apply py-10;
}

@screen xl {
  .product-zone-nav {
    @apply pt-8  pb-10;
  }
}
.product-zone-nav .toggle-nav {
  @apply hidden;
}

@media screen and (max-width: 576px) {
  .product-zone-nav .toggle-nav {
    @apply gap-item px-5 py-3 bg-secondary-005A79;
    height: 40px;
  }

  .product-zone-nav .toggle-nav span {
    @apply text-white font-bold uppercase;
  }

  .product-zone-nav .toggle-nav em {
    @apply text-white;
  }
}
.product-zone-nav .top-zone-nav ul {
  @apply flex-wrap;
}

.product-zone-nav .top-zone-nav ul li {
  @apply mx-[5px] mb-[10px];
}

@media screen and (max-width: 576px) {
  .product-zone-nav .top-zone-nav {
    border-radius: 15px;
    @apply overflow-hidden;
  }

  .product-zone-nav .top-zone-nav ul {
    @apply flex-col;
  }

  .product-zone-nav .top-zone-nav ul li {
    @apply w-full m-0;
  }

  .product-zone-nav .top-zone-nav ul li a {
    height: 36px;
    font-size: 0.8333333333rem;
    @apply justify-start  normal-case;
  }
}
@media screen and (max-width: 576px) {
  .product-zone-nav .top-zone-nav ul {
    @apply hidden;
  }
}
@media screen and (max-width: 1024px) {
  .product-banner {
    @apply mb-10;
  }
}
.product-banner .swiper {
  @apply p-2;
}

.product-banner .item {
  @apply overflow-hidden;
  border-radius: 20px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
}

.product-banner .img-wrap {
  @apply relative;
}

.product-banner .img-wrap a {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 33.3333333333%;
}

.product-banner .img-wrap a img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

.product-banner .txt {
  @apply p-8;
}

@screen md {
  .product-banner .txt {
    @apply absolute top-1/2 translate-y-50 w-full h-full col-item px-7 right-0 pl-14;
    align-items: flex-start;
    max-width: 330px;
  }
}
.product-banner .headline {
  @apply mb-5 font-bold;
  font-size: 1.25rem;
}

.product-list {
  @apply pt-0 pb-16;
}

@screen xl {
  .product-list {
    @apply pt-[40px] pb-[80px];
  }
}
.product-figure {
  border: 1px solid #E5E5E5;
  padding: 1.5625rem 0.78125rem;
  transition: 0.4s all ease-in-out;
  @apply h-full;
}

.product-figure .img-wrap {
  @apply relative;
}

.product-figure .brand {
  @apply center-item mb-4;
  height: 15px;
  width: auto;
}

@screen xl {
  .product-figure .brand {
    @apply mb-6;
  }
}
.product-figure .brand img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.product-figure .img-src a {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 100%;
}

.product-figure .img-src a img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: contain;
  transition: 0.3s ease-in-out all;
}

.product-figure .txt {
  @apply text-center pt-5;
}

@screen xl {
  .product-figure .txt {
    @apply pt-6;
  }
}
.product-figure .caption {
  @apply text-666666 mt-2;
}

.product-figure .caption * {
  @apply text-666666;
}

.product-figure:hover {
  border-color: #ED202E;
}

@screen xl {
  .project-swiper-wrap {
    padding-left: 9.375rem;
  }
}
.project-swiper-wrap .swiper-slide {
  @apply h-auto;
}

.project-complete {
  background: rgba(0, 0, 0, 0.03);
}

.project-on-going {
  @apply bg-background-F5F5F5;
}

@media screen and (max-width: 1024px) {
  .project-swiper .wrap-gap {
    @apply col-item;
  }

  .project-swiper .wrap-gap .block-title {
    @apply mb-0;
  }

  .project-swiper .wrap-gap .btn-primary {
    @apply my-8;
  }
}
.project-swiper .progress-wrap {
  @apply center-item mt-8;
}

.project-swiper .first-index, .project-swiper .last-index {
  @apply text-black font-bold;
}

.project-swiper .first-index {
  @apply mr-3;
}

.project-swiper .last-index {
  @apply ml-3;
}

.project-swiper .btn-primary span {
  @apply mx-0;
}

.project-swiper .btn-primary em {
  @apply ml-2;
}

.project-swiper .swiper-pagination {
  position: static !important;
}

.project-swiper .swiper-pagination-progressbar.swiper-pagination-horizontal {
  height: 5px !important;
}

.project-swiper .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #ED202E !important;
}

.project-figure {
  @apply bg-white overflow-hidden  h-full;
  border-radius: 10px;
  padding: 0.5208333333rem;
}

.project-figure .img-src a {
  @apply overflow-hidden;
  border-radius: 10px;
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 60%;
}

.project-figure .img-src a img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

.project-figure .txt {
  @apply p-4;
}

@screen xl {
  .project-figure .txt {
    padding: 1.3020833333rem 1.0416666667rem 1.5625rem;
  }
}
.project-figure ul {
  list-style-type: none;
  @apply pl-0;
}

.project-figure ul li {
  @apply start-item mb-1;
}

@media screen and (max-width: 576px) {
  .project-figure ul li {
    @apply col-item;
    align-items: flex-start;
  }
}
.project-figure ul li strong, .project-figure ul li span {
  @apply text-222222;
}

.project-figure ul li strong {
  min-width: 6.7708333333rem;
}

@media screen and (max-width: 576px) {
  .project-figure ul li strong {
    @apply mb-1;
  }
}
.project-figure .headline {
  @apply mb-2;
}

.project-figure .headline a {
  @apply text-primary-1D4789  transition leading-tight;
  font-size: 1.25rem;
}

.project-figure .headline:hover a {
  @apply text-primary-ED202E;
}

.project-display-list {
  @apply bg-background-F5F5F5;
}

.project-display-list .row {
  @apply justify-start;
}

.project-detail {
  padding: 4.1666666667rem 0;
}

.project-detail .block-title {
  @apply relative pb-5 mb-2;
}

.project-detail .block-title::after {
  content: "";
  @apply block absolute pointer-events-none left-0 bottom-0 z-10  transition bg-primary-ED202E;
  height: 2px;
  width: 80px;
}

.project-detail .table-wrap table tr td {
  border-bottom: 1px solid #E5E5E5;
  @apply py-3 text-333333;
  height: 50px;
  font-size: 0.8333333333rem;
}

.project-detail .table-wrap table tr td:first-child {
  @apply font-bold text-222222  pr-5;
  min-width: 9.1145833333rem;
}

.project-detail .block-wrap {
  @apply mt-10;
}

@screen xl {
  .project-detail .block-wrap {
    @apply mt-[60px];
  }
}
.research-detail .headline {
  font-family: "UTM Avo";
  font-size: 2.0833333333rem;
}

.research-detail .table-wrap {
  @apply mb-8;
}

@screen xl {
  .research-detail .table-wrap {
    @apply mb-12;
  }
}
.research-detail table {
  @apply w-full;
}

.research-detail table td {
  @apply text-333333 py-3;
  font-size: 1rem;
  border-bottom: 1px solid #E5E5E5;
}

@screen xl {
  .research-detail table td {
    font-size: 0.8333333333rem;
  }
}
.research-detail .img-src a {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 56.0975609756%;
}

.research-detail .img-src a img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

.research-other {
  padding-bottom: 4.1666666667rem;
}

.research-other .block-title {
  font-size: 1.5625rem;
}

.research-technology .top-zone-nav {
  @apply mb-8;
}

@screen xl {
  .research-technology .top-zone-nav {
    @apply mb-14;
  }
}
@media screen and (max-width: 576px) {
  .research-technology .top-zone-nav ul li a {
    @apply text-[13px] px-4;
  }
}
.research-technology .txt-wrap {
  @apply mb-10;
}

@screen xl {
  .research-technology .txt-wrap {
    @apply mb-14;
  }
}
@media screen and (max-width: 1279.1px) {
  .swiper-research-main .img-wrap a {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 56.862745098%;
  }

  .swiper-research-main .img-wrap a img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    transition: 0.3s ease-in-out all;
  }
}
@screen xl {
  .swiper-research-main .img-wrap a {
    @apply center-item;
    height: 30.2083333333rem;
    width: auto;
  }

  .swiper-research-main .img-wrap a img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.swiper-research-thumb {
  @apply relative;
}

@media screen and (max-width: 1279.1px) {
  .swiper-research-thumb {
    @apply px-10;
  }
}
.swiper-research-thumb .swiper-slide .img-wrap {
  border: 1px solid transparent;
  transition: 0.3s all ease-in-out;
}

.swiper-research-thumb .swiper-slide.swiper-slide-thumb-active .img-wrap {
  border-color: #ED202E;
}

.swiper-research-thumb .nav-prev, .swiper-research-thumb .nav-next {
  @apply absolute;
}

@media screen and (max-width: 1279.1px) {
  .swiper-research-thumb .nav-prev, .swiper-research-thumb .nav-next {
    @apply top-1/2 translate-y-50;
  }
}
@screen xl {
  .swiper-research-thumb .nav-prev, .swiper-research-thumb .nav-next {
    @apply left-1/2 translate-x-50;
  }
}
.swiper-research-thumb .nav-prev em, .swiper-research-thumb .nav-next em {
  font-size: 48px;
  @apply text-999999;
  width: 30px;
}

@media screen and (max-width: 1279.1px) {
  .swiper-research-thumb .nav-prev em, .swiper-research-thumb .nav-next em {
    transform: rotate(-90deg);
    font-size: 32px;
    height: 32px;
    line-height: 32px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279.1px) {
  .swiper-research-thumb .nav-prev {
    @apply right-full;
  }
}
@media screen and (max-width: 1024px) {
  .swiper-research-thumb .nav-prev {
    left: 0;
  }
}
@screen xl {
  .swiper-research-thumb .nav-prev {
    @apply bottom-full;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279.1px) {
  .swiper-research-thumb .nav-next {
    @apply left-full;
  }
}
@media screen and (max-width: 1024px) {
  .swiper-research-thumb .nav-next {
    right: 0;
  }
}
@screen xl {
  .swiper-research-thumb .nav-next {
    @apply top-full;
  }
}
@screen xl {
  .swiper-research-thumb .swiper {
    height: 30.2083333333rem;
  }
}
@media screen and (max-width: 1279.1px) {
  .swiper-research-thumb .img-wrap a {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 55.5555555556%;
  }

  .swiper-research-thumb .img-wrap a img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    transition: 0.3s ease-in-out all;
  }
}
@screen xl {
  .swiper-research-thumb .img-wrap a {
    @apply center-item;
    height: 5.2083333333rem;
    width: auto;
  }

  .swiper-research-thumb .img-wrap a img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.research-machine {
  padding-bottom: 4.1666666667rem;
}

.research-machine .zone-title {
  @apply mb-10;
}

@screen xl {
  .research-machine .zone-title {
    @apply mb-12;
  }
}
.research-machine .row {
  @apply justify-start;
}

.research-item {
  @apply bg-background-F5F5F5  h-full;
}

.research-item .img-src a {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 56.4102564103%;
}

.research-item .img-src a img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

.research-item .txt {
  @apply p-4 start-item;
}

@screen sm {
  .research-item .txt {
    @apply px-5;
  }
}