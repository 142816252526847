.pop-up-modal
	padding: 0
	border-radius: 10px
	@apply w-full

.pop-upmodal-wrapper
	overflow: hidden
	.row
		@apply m-0 p-0
		.col
			@apply m-0 p-0
	.container
		padding: 0 40px
		@apply  flex items-center
		height: 100%
		@screen lg
			padding: 0 80px

	.sub-text
		@apply text-[18px] text-333333 mb-2
	.scroll-wrapper
		@apply text-[16px] text-333333 pt-4
		@screen lg
			@apply pt-6
		*
			@apply text-[16px] text-333333 mb-4
			@screen lg
				@apply mb-5
		strong
			@apply font-bold
		iframe,img
			@apply block mx-auto my-5
	.btn-left
		@apply pt-4
		@screen lg
			@apply pt-6
	.text-wrap
		@apply flex flex-col items-start justify-center
		height: 100%
	.block-title
		@apply text-[24px] leading-tight
	.scroll-wrapper
		height: 450px
		padding-right: 15px
		overflow-y: auto
		scrollbar-width: thin
		scrollbar-color: red #cccccc
		@apply leading-relaxed text-justify
		&::-webkit-scrollbar
			background: #cccccc
			width: 3px
		&::-webkit-scrollbar-track
			-webkit-box-shadow: inset 0 0 6px #cccccc
			background-color: #cccccc
		&::-webkit-scrollbar-thumb
			background-color: red
		@media screen and ( max-width:1024px)
			height: 330px