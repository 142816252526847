.wrap-form
	@apply relative
	.wpcf7-spinner
		display: none !important
	.row
		box-shadow: none !important
	.fa-exclamation-triangle
		@apply absolute text-[12px] font-light 
		bottom: -25px
		left: 20px
		color: #f30000
		@media screen and ( max-width: 1024px)
			bottom: -23px
	.col
		@apply mb-4 relative
		&:last-child
			@apply mb-6
	.form-group
		@apply relative 
		margin-bottom: 20px !important
		@media screen and ( max-width: 1024px)
			margin-bottom: 25px !important
		input[type='text'],textarea,select
			height: 50px
			background: #ffffff
			border: 1px solid #DEEBFF
			border-radius: 25px !important
			@apply text-[14px] text-333333 px-[20px]
			@media screen and ( max-width:1024px )
				height: 40px
				border-radius: 15px !important
				@apply px-5
			&::placeholder
				@apply text-666666 text-[14px] font-normal
				@media screen and ( max-width: 1024px)
					@apply text-[12px]
		select
			option
				@apply text-[16px] text-333333

	.form-group
		textarea
			@apply p-4
			height: r(120px)
	.frm-btnwrap
		@apply center-item w-full mt-8
		.frm-btn
			@apply relative
			&:hover
				&::after
					@apply text-primary-1D4789
			&::after
				font-family: 'Font Awesome 6 Pro'
				content: '\f178'
				@apply block absolute pointer-events-none top-1/2 translate-y-50 right-[45px]  z-10  transition text-white font-light
				height: 16px
				width: 20px
				line-height: 16px
				@media screen and ( max-width: 1024px)
					right: 20px
			input[type='submit']
				width: 160px
				height: 45px
				border-radius: 100px
				border: 1px solid $blue-1
				@apply text-white  text-[16px] bg-primary-1D4789 transition uppercase center-item px-8 font-bold pr-12  overflow-hidden
				@media screen and ( max-width: 1024px) 
					height: 40px
					width: 120px
					@apply px-6 pr-10
				&:hover
					@apply bg-white text-primary-1D4789