#main-banner
	.img
		a
			+img-ratio(900/1920)
	.container
		left: 50%
		transform: translateX(-50%)
		bottom: r(40px)
		@apply absolute
		@media (min-width: 1280px)
			bottom: r(140px)
		.content
			padding: r(10px) r(20px)
			@apply relative w-fit
			@media (min-width: 1280px)
				padding: r(22px) r(20px) r(28px) r(46px)
			&::before
				content: ""
				z-index: -1
				@apply bg-primary-1D4789/[.60] absolute w-full h-full top-0 left-0
	.block-title
		@media (min-width: 1280px)
			line-height: r(60px)
		@media (max-width: 575.98px)
			font-size: r(20px)
			line-height: inherit
	.swiper-pagination
		bottom: 10px !important
		@media (min-width: 1280px)
			bottom: r(40px) !important