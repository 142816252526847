@tailwind base
@tailwind components
@tailwind utilities

@layer components
	*,
	*::before,
	*::after
		box-sizing: border-box

	*::-webkit-scrollbar-track
		background-color: #999

	*::-webkit-scrollbar
		// width: r(10px)
		width: 0

	*::-webkit-scrollbar-thumb
		border-radius: 0
		border: r(2px) solid #999
		@apply bg-primary-1D4789

	html
		-webkit-text-size-adjust: 100%
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0)

	body
		line-height: 1.5
		font-weight: 400
		color: #111
		@apply font-body

	img
		display: inline
