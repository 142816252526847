footer
	@apply text-white relative
	&::after
		content: ''
		@apply block absolute pointer-events-none left-[56px] bottom-0 z-10  transition
		height: 320px
		width: 330px
		background: url('../img/bg/logo-bg.png')
		background-repeat: no-repeat
		background-size: contain
		background-position: center
	.box-info
		.title
			@apply font-extrabold text-base leading-24px mb-15px uppercase
		.ctn
			@apply font-normal text-sm leading-27px
		.icon
			i
				@apply font-light text-base leading-4 opacity-80 mr-15px
		.item
			@apply flex items-baseline
	.box-nav
		nav
			>ul
				> li
					> a
						@apply font-extrabold text-base leading-24px mb-15px block
					> ul
						> li
							@apply transition-all
							&.active,&:hover,&[class*="current-"]
								@apply text-primary-ED202E
							> a
								@apply font-normal text-sm leading-26px
	.social-icons
		@apply flex items-center justify-center sm:justify-start
		a
			width: r(48px)
			height: r(48px)
			@apply flex items-center justify-center transition-all
			&:hover
				@apply shadow-xl text-primary-ED202E rounded-full
			& + a
				@apply ml-10px
			i
				@apply text-2xl
			img
				height: 30px
				width: auto 
	.bct
		img
			width: r(135px)
			height: r(51px)
	.footer-icon
		img
			width: r(206px)
			height: r(46px)
	.copyright
		@apply font-normal text-xs leading-18px text-center sm:text-left
	.footer-link
		@apply font-normal text-xs leading-18px flex sm:justify-end justify-center
		ul
			@apply flex items-center
			li
				@apply transition-all
				&.active,&:hover,&[class*="current-"]
					@apply text-primary-ED202E
				& + li
					@apply ml-5px pl-5px relative
					&::before
						content: ""
						height: r(18px)
						width: r(1px)
						@apply absolute left-0 top-1/2 -translate-y-1/2 bg-white
	.grid
		&.top
			@apply pt-14 pb-10
		&.middle
			@apply py-3
			@media (max-width: 575.98px)
				text-align: center
		&.bottom
			@apply pt-15px pb-5
	.container
		> .grid
			& + .grid
				border-top: r(1px) solid rgba(#fff , .15)
