.home-service
	@apply relative 
	&::after 
		content: ''
		@apply block absolute pointer-events-none  bottom-0 z-10  transition
		height: r(495px)
		width: r(476px)
		background: url('../img/bg/logo-bg.png')
		background-repeat: no-repeat
		background-size: contain
		background-position: center
		left: r(30px)
	.block-wrap
		@apply relative
	.container
		@media screen and ( max-width: 1280px)
			@apply bg-primary-1D4789
		@screen xl
			@apply  absolute top-1/2 left-1/2 translate-50
		.row
			@apply my-0
			.col
				@apply my-0
	.container-fluid
		@apply px-4
		.row
			@apply m-0 p-0 w-full
			.col
				@apply m-0 p-0
				&:first-child
					@apply bg-primary-1D4789
				&:nth-child(2)
					@apply bg-white
					
	.icon
		@apply center-item my-6 pb-6
		height: 60px
		width: auto
		min-width: 60px
		border-bottom: 1px solid rgba(#fff,.2)
		@screen xl
			@apply justify-start pb-6  my-0
		img
			width: auto
			height: 100%
			object-fit: contain
	.number-wrap
		@apply mt-8 center-item pb-8
		@screen xl
			@apply mt-10 justify-start pb-0
	.number-item
		@apply relative

	.percent
		@apply absolute bottom-0 right-[50px] text-white text-[24px]
		@screen xl
			font-size: r(32px)
		@media screen and ( max-width: 1440px)
			right: 40px 
		@media screen and ( max-width: 1280px)
			right: 15px
	.number
		@apply pr-4 start-item
		@screen xl
			margin-top: r(-70px)
		span
			@apply text-white font-extrabold  center-item
			font-size: r(80px)
			height: 60px 
			line-height: 1
			@screen xl
				font-size: r(160px)
				height: 120px 
			&:first-child
				@apply mt-16
			&:nth-child(2)
				@apply mt-8
				
	.catalogue
		@apply pl-4
		border-left: 1px solid rgba(#fff,.2)

	.title
		@apply text-[24px] text-white font-semibold leading-tight mb-4
		@screen xl
			@apply   mb-5
			font-size: r(48px)
	.btn-catalogue
		@apply start-item
		em,span
			@apply text-secondary-8998C2 
			font-size: r(24px)
		em
			@apply mr-4
.chart-wrap
	@apply  py-10 start-item
	@screen xl
		@apply py-[30px]
	.menuB
		@apply hidden
	.titles
		font-family: Verdana, Arial, Helvetica, sans-serif
		font-size: 11px
		font-weight: bold
		color: #FFFFFF
		padding: 3px
		line-height: 11px
		background: #005CA9
	img
		@apply w-full
	.tabs
		font-family: Verdana, Arial, Helvetica, sans-serif
		font-size: 11px
		color: #005BA8
		font-weight: bold
		@apply text-center
		a
			color: #222222
	.top
		@apply hidden
		img
			@apply hidden
	table
		width: 285px
		table
			tr
				&:first-child
					img
						@apply hidden
	.item
		@apply center-item px-6
		@media screen and ( min-width: 1280.1px)
			padding: 0 r(80px)
		@media screen and ( max-width: 576px)
			@apply col-item
	.ico
		@apply center-item
		height: 64px
		width: auto
		img
			width: 100%
			height: 100%
			object-fit: contain
	.caption
		@apply  text-[16px] mt-4
		@screen md
			@apply ml-4 mt-0 text-[20px]
		@screen xl
			max-width: 175px
			@apply ml-5
			font-size: r(24px)
	.img-wrap 
		a
			@media screen and ( max-width: 1280px)
				+img-ratio(500/960)
			@screen xl
				@apply center-item
				height: 400px
				width: auto
			@media screen and ( min-width: 1440.1px)
				height: 500px
			img
				width: 100%
				height: 100%
				object-fit: cover