.home-project
	@apply bg-primary-1D4789 relative
	.img-src
		a
			@media screen and ( max-width: 1280px)
				+img-ratio(560/1020)
			@media screen and ( min-width: 1280.1px)
				@apply center-item
				height: 460px
				width: auto
				img
					width: 100%
					height: 100%
					object-fit: cover
			@media screen and ( min-width: 1440px)
				height: 560px
	.img-wrap
		@apply relative
	.title
		@apply text-white uppercase font-bold absolute right-0 bottom-0 p-4 z-20
		@screen sm
			writing-mode: vertical-lr
			transform: rotate(180deg)
	.txt
		@apply pt-5  transition 
		@screen xl
			@apply pt-6
	.swiper-slide
		@apply relative 
		@media screen and ( min-width: 1280.1px)
			.img-wrap
				&::after
					content: ''
					@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-full bg-black opacity-40
			&.swiper-slide-active
				@media screen and ( min-width: 1280.1px)
					width: 820px !important
				@media screen and ( min-width: 1440.1px)
					width: 1020px !important
				.img-wrap
					&::after
						@apply opacity-0
			&:not(.swiper-slide-active)
				width: 260px !important
				.txt
					@apply opacity-0
	.item
		@apply transition
	.nav-arrow-next,.nav-arrow-prev
		@apply static
		transform: none !important
		left: unset !important
		right: unset !important
		top: unset !important
	.swiper-nav-wrap
		@apply center-item pt-12
		@media screen and ( min-width: 1280.1px)
			@apply absolute pt-0
			bottom: 50px
			right: r(270px)
	.home-project-swiper
		@apply relative
		.swiper
			@media screen and ( min-width: 1280.1px)
				@apply overflow-visible
	.caption
		height: 60px !important
		@apply overflow-hidden