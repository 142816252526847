.about-process
	.top-zone-nav
		@apply mb-10
		@screen xl
			@apply mb-[70px]
	.block-wrap
		.row
			@screen lg
				@apply m-0 p-0 justify-between relative
				&:not(:last-child)
					@apply pb-10
					@screen xl
						@apply pb-[60px]
				&::after
					content: ''
					width: 1px 
					@apply block absolute pointer-events-none top-0 z-10 transition h-full bg-background-E5E5E5 left-1/2 translate-x-50
				&:nth-child(even)
					@apply flex-row-reverse
					.number
						&::after
							left: unset
							@apply right-full 
				
		.col
			@screen lg
				@apply m-0 p-0 
	.number
		@apply bg-primary-ED202E  rounded-full text-white font-bold center-item  mx-auto 
		font-size: r(16px)
		height: 25px
		width: 25px

		@screen lg
			@apply absolute left-1/2 translate-x-50 z-30 
			&::after
				content: ''
				@apply block absolute pointer-events-none left-full top-1/2 translate-y-50 z-10  transition bg-background-E5E5E5 
				height: 1px
				width: r(70px)
	.headline
		@apply  text-center my-4
		@screen lg
			@apply text-left  mt-0
	.img-wrap
		a
			+img-ratio(300/525)
	.txt-wrap
		@apply pb-8
		@screen lg
			@apply pb-0 
	.caption
		font-size: r(16px)
		@apply text-333333
		*
			font-size: r(16px)
			@apply text-333333
		ul
			li
				@apply mb-3
			ol,ul
				list-style-type: circle
				@apply pl-5
