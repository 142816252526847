.home-choose-us
	background: url('../img/bg/index-bg-2.jpg')
	background-repeat: no-repeat
	background-size: cover
	background-position: center
	.block-title
		span
			@apply text-primary-ED202E
	.item
		.icon
			height: r(150px)
			width: r(150px)
			@apply center-item overflow-hidden rounded-full bg-white mx-auto mb-4
			+boxshadow
			@screen xl
				@apply mb-6
			a
				@apply center-item
				height: 64px
				width: auto
				img
					width: 100%
					height: 100%
					object-fit: contain
	.row
		@apply justify-between px-10
		@media screen and ( max-width: 576px)
			@apply justify-center
			.col
				&:last-child
					@apply w-full
	.caption
		max-width: 240px
		@apply mx-auto