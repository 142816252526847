.news-detail
	.ez-toc-title-container
		@apply hidden
	.table-wrapper
		@apply mb-8
		@screen xl
			@apply mb-12
		@media screen and ( max-width: 1024px)
			@apply overflow-x-auto
			table
				@apply whitespace-nowrap
		table
			@apply w-full
			thead
				th
					@apply text-333333 py-3 px-4
					font-size: 1rem
			tbody
				td
					@apply text-333333 py-3 px-4
					font-size: 1rem
					border-bottom: 1px solid #E5E5E5
					@screen xl
						font-size: r(16px)
	#ez-toc-container
		@apply w-full block
		background: #F5F5F5 !important
		border: none
		padding: 20px 40px
		.ez-toc-heading-1
			@apply text-[16px]
			color:#666 !important
		.ez-toc-heading-3
			color:#999 !important
		.ez-toc-heading-level-3 
			a
				color:#999 !important
	.news-detail-toc
		@apply bg-background-F5F5F5  py-5
		p
			@apply text-[16px] font-bold
		ol
			@apply  font-semibold 
			counter-reset: section
			margin: 0 
			padding: 0 0 0 30px
			a
				@apply font-normal text-666666  text-[16px]
			li
				padding: 0 30px
				position: relative
				counter-increment: numb
				&::before
					counter-increment: section
					content: counters(section, ".") " "
					@apply center-item absolute pointer-events-none    z-10 font-normal transition  text-666666
					left: 5px
				ol
					padding: 0
					li
						padding: 0 0 0 30px
						&::before
							@apply  text-999999
							left: 0
							width: 16px
				ol
					a
						@apply text-999999
	
	.block-wrap
		time
			@apply text-222222 mb-5
			font-size: r(16px)
	.briefcontent
		@apply text-333333 font-bold my-8
		font-size: r(18px)
		*
			@apply text-333333 font-bold
			font-size: r(18px)
	.fullcontent
		@apply text-333333 font-normal py-8
		font-size: 19px !important
		*
			@apply text-333333 font-normal  mb-2
			font-size: 19px !important
			*
				font-size: 19px !important
		p
			font-size: 19px !important
			*
				font-size: 19px !important
		@media screen and ( max-width: 576px)
			font-size: 14px !important
			*
				font-size: 14px !important
		ul
			list-style-type: disc
			padding-left: 20px
		img,iframe
			@apply block mx-auto my-4
		strong
			@apply font-bold
		h2,h3,h4
			@apply text-primary-1D4789 font-bold my-3
			*
				@apply text-primary-1D4789 font-bold
.pager 
	padding: 30px 0 0 0 !important
	list-style-type: none
	@apply center-item
	li
		margin-left: 10px
		&.active
			span
				width: 40px
				height: 40px
				@apply center-item overflow-hidden rounded-full bg-primary-ED202E  font-normal text-white
				font-size: r(18px)
		a
			width: 40px
			height: 40px
			@apply center-item overflow-hidden rounded-full bg-primary-1D4789  font-normal text-white
			font-size: r(18px)
			span
				font-size: 18px
				line-height: 14px
				height: 14px