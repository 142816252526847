.dealer-list
	.sub-title
		@apply text-333333 font-bold uppercase whitespace-nowrap center-item mr-4
		font-size: r(20px)
	.wrapper-form
		@apply flex w-full
		.form-group
			height: 40px
			@apply w-full
			&:first-child
				@apply mr-4
		.custom-select
			select
				border-radius: 0
				border: none
	.block-form-wrap
		@apply flex mb-5
	.corner
		@apply absolute right-0 z-10
		width: 70px
		height: 70px
		&:first-child
			@apply top-0
			border-top: 5px solid $blue-1
			border-right: 5px solid $blue-1
		&:nth-child(2)
			@apply bottom-0
			border-bottom: 5px solid $blue-1
			border-right: 5px solid $blue-1
	.dealer-map-wrapper 
		padding: 20px 20px 20px 0
		position: relative
		a
			@apply center-item overflow-hidden
			height: r(715px)
			text-align: center
			width: 100%
			@media screen and ( max-width: 1024px)
				height:  r(500px)
			@media screen and ( max-width: 576px)
				height: r(400px)
			iframe
				@apply inline-block w-full h-full
	.scroll-bar-wrap
		@media screen and ( max-width: 576px)
			height: r(250px)
	.toggle-item
		&.active
			.title
				span,em
					@apply text-primary-ED202E
				em
					&::before
						content: '\f068'
			.article
				@apply block
.scroll-bar-wrap
	direction: rtl
	height: r(650px)
	padding-left: 25px
	overflow-y: auto
	overflow-x: hidden
	scrollbar-width: thin
	scrollbar-color: #A0C4F4 #CCCCCC
	&::-webkit-scrollbar
		background: #CCCCCC
		width: 7px
		border: none !important
	&::-webkit-scrollbar-track
		-webkit-box-shadow: inset 0 0 7px #CCCCCC
		background-color: #CCCCCC
		border: none !important
	&::-webkit-scrollbar-thumb
		border: none !important
		background: #1D4789
	@media screen and ( max-width: 1024px)
		height:  r(500px)
.page-template-page-contact
	.toggle-item
		.title
			span,em
				@apply text-primary-ED202E
			em
				&::before
					content: '\f068'
		.article
			@apply block
.toggle-item
	border-bottom: 1px solid #CCCCCC
	direction: ltr
	.title
		@apply flex justify-between items-center py-5
		@screen xl
			@apply py-6
		span
			@apply text-333333 font-bold
			font-size: r(20px)
		em
			@apply text-primary-1D4789
			font-size: 16px
			line-height: 1
	.article
		@apply hidden pb-5
		ul
			list-style-type: none
			padding-left: 0
		li
			@apply flex justify-start mb-1
			align-items: flex-start
		em
			@apply text-primary-ED202E  mr-4 inline-block 
			padding-top: 8px
			font-size: 16px
			line-height: 1
		span
			@apply text-333333 font-normal
	&.active
		.title
			span,em
				@apply text-primary-ED202E
			em
				&::before
					content: '\f068'
.toggle-item-2
	border-bottom: 1px solid #CCCCCC
	direction: ltr
	.title
		@apply flex justify-between items-center py-5
		@screen xl
			@apply py-6
		span
			@apply text-primary-ED202E font-bold
			font-size: r(20px)
		em
			@apply text-primary-ED202E
			font-size: 16px
			line-height: 1
			&::before
				content: '\f068'
	.article
		@apply pb-5
		ul
			list-style-type: none
			padding-left: 0
		li
			@apply start-item mb-1 items-center
		em
			@apply text-primary-ED202E  mr-4 inline-block
			font-size: 16px
			line-height: 1
		span
			@apply text-333333 font-normal