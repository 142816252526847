.index-1
	&::before
		content: ""
		background: linear-gradient(180deg, rgba(29, 71, 137, 0) 0%, #1D4789 100%)
		opacity: 0.2
		z-index: -1
		@apply absolute w-full h-full top-0 left-0 pointer-events-none
	.ctn
		max-width: r(1080px)
		@apply text-base font-normal leading-24px text-center mx-auto
		strong
			@apply text-primary-1D4789

	.list
		@apply text-center grid grid-cols-4 mt-10 gap-5
		@media screen and ( max-width: 576px)
			@apply grid-cols-2
	.img-wrap
		width: r(150px)
		height: r(150px)
		@apply relative flex items-center justify-center flex-col text-white mx-auto rounded-full overflow-hidden bg-primary-ED202E transition
		img
			@apply transition
		&::before
			content: ""
			background-image: url(../img/icon/icon-1.png)
			background-size: contain
			z-index: 2
			@apply bg-no-repeat w-full h-full top-0 left-0 absolute 
		&:hover
			@apply bg-white
			img
				filter: brightness(0.3)
			.number,.unit
				@apply text-primary-ED202E
			&::before
				filter: brightness(0.6)
	.title
		@apply mt-15px
	.value
		@apply flex items-center
		span
			line-height: r(50px)
		.number
			font-size: r(40px)
			letter-spacing: r(-0.06px)
			@apply font-extrabold transition
		.unit
			font-size: r(36px)
			margin-top: r(-15px)
			@apply font-bold transition
	.wrap-center
		@apply pt-8
		@screen xl
			@apply py-12