header
	z-index: 150
	@apply fixed left-0 top-0 w-full shadow-lg bg-white
	.button-wrapper
		@apply xl:gap-5 sm:gap-10px gap-0
		.button-2
			.btn.btn-header
				@media (min-width: 1280px)
					@apply bg-primary-ED202E text-white border-transparent
					&:hover
						@apply bg-primary-1D4789
		.button
			@media screen and ( max-width: 576px)
				@apply w-1/2 mx-4
			.btn.btn-header
				@media screen and (min-width: 576px) and (max-width: 1279.98px)
					font-size: 0
					width: 32px
					height: 32px
					@apply bg-transparent border-none
					i
						@apply mr-0 text-xl text-primary-1D4789
						@media screen and ( max-width: 576px)
							@apply text-[24px] text-white
					&:hover
						@apply border-primary-1D4789
						i
							@apply text-primary-1D4789
				@media (max-width: 575px)
					width: auto
					border-color: white
					color: white
					height: r(40px)
					&:hover
						@apply border-primary-1D4789
	.top
		padding-top: r(20px)
		padding-bottom: r(20px)
		@apply flex items-center justify-between
		@media (min-width: 1280px)
			padding-top: r(10px)
			padding-bottom: 0
		.left
			@media (max-width: 1279.98px)
				left: 50%
				top: 50%
				transform: translate(-50%,-50%)
				@apply absolute
			.desktop-show
				width: r(502px)
				height: r(45px)
			.mobile-show
				height: 50px
				@apply object-contain
		.right
			@apply flex items-center

		.search
			width: 25px
			height: 25px
			@media (min-width: 576px)
				width: 32px
				height: 32px
			@apply xl:px-15px xl:ml-5 sm:ml-10px sm:mr-10px xl:mr-15px flex items-center justify-center
			@media (min-width: 1280px)
				width: auto
				height: r(20px)
				border-left: thin solid #ccc
				border-right: thin solid #ccc
			.search-icon
				@apply flex
			i
				@apply text-lg sm:text-xl xl:text-base font-normal text-primary-1D4789
				@media screen and ( max-width: 576px)
					@apply text-[18px]
	.language
		width: auto
		.wpml-ls-legacy-dropdown-click
			width: auto !important
			a
				&:hover
					background: none !important
			.wpml-ls-item-toggle
				padding: 0 !important
				&::after
					@apply hidden
		.wpml-ls-current-language
			&:hover
				.wpml-ls-sub-menu
					.wpml-ls-item
						opacity: 1 !important
						pointer-events: auto !important
						background: white
						.wpml-ls-display
							&::after
								display: none
								
				
		ul
			@apply relative text-primary-1D4789 text-lg sm:text-xl xl:text-sm leading-18px
			a
				@apply flex items-center justify-center
				border: none !important
				color: $blue-1 !important
				span
					@apply flex items-center justify-center w-full
					color: $blue-1 !important
			> li
				@apply transition-all
				&.wpml-ls-current-language
					.wpml-ls-display
						&::after
							@apply hidden
					span
						&::after
							content: "\f078"
							@apply font-Awesome6 ml-5px xl:ml-10px text-xs sm:text-base
							color: $blue-1 !important
				&:not(.wpml-ls-current-language)
					padding: r(5px)
					@apply absolute top-full left-1/2 -translate-x-1/2 opacity-0 pointer-events-none w-full
	.bottom
		margin-top: r(5px)
		nav
			a
				@apply transition-all
			> ul
				@apply flex items-center justify-end
				> li
					padding-bottom: r(11.5px)
					&.active,&:hover,&[class*="current-"]
						> a
							@apply text-primary-1D4789
					& + li
						@apply ml-10
					> a
						@apply font-bold text-sm leading-18px uppercase text-333333
	.burger
		@apply relative
		i
			@apply mr-0 text-2xl text-primary-1D4789 transition-all duration-500
			@media screen and ( max-width: 576px)
				@apply text-[24px]
			&:nth-of-type(2)
				left: 50%
				top: 50%
				transform: translate(-50%,-50%) scale(1.1)
				@apply absolute opacity-0
		&.active
			i
				&:nth-of-type(1)
					@apply opacity-0 scale-75
				&:nth-of-type(2)
					transform: translate(-50%,-50%) scale(1)
					@apply opacity-100

	.nav-mobile
		top: var(--header-height,0)
		height: calc( 100vh - var(--header-height))
		transition: all .75s cubic-bezier(.85,0,.15,1)
		max-width: 320px
		left: -100%
		background: linear-gradient(135deg, #1D4789 0%, #0059E0 99.62%)
		@apply fixed w-full overflow-y-auto
		height: 100vh
		.button-wrapper
			padding-bottom: 30px
		&.active
			border-radius: 0
			@apply left-0
			nav
				li
					@apply opacity-100 scale-100
		.nav-mobile-wrapper
			padding: r(30px) r(15px)
			height: 310px
			overflow-y: auto
			scrollbar-width: thin
			scrollbar-color: red #cccccc
			@apply leading-relaxed text-justify
			&::-webkit-scrollbar
				background: #cccccc
				width: 3px
			&::-webkit-scrollbar-track
				-webkit-box-shadow: inset 0 0 6px #cccccc
				background-color: #cccccc
			&::-webkit-scrollbar-thumb
				background-color: red
		nav
			a
				@apply transition-all
			> ul
				@apply flex flex-col
				> li
					padding-bottom: r(11.5px)
					@apply opacity-0 scale-90 transition-all duration-1500
					&.active,&:hover,&[class*="current-"]
						> a
							@apply text-primary-ED202E
					> a
						@apply text-lg leading-18px uppercase text-white font-bold
.header-site-nav
	.title
		@media screen and ( max-width: 1279.2px)
			@apply wrap-gap w-full
		> a
			@apply font-bold text-sm leading-18px uppercase text-333333
			@media screen and ( max-width: 1279.1px)
				@apply text-lg leading-18px uppercase text-white
		em
			@apply text-white end-item
			width: 40px
			height: 20px
			@screen xl
				@apply hidden
	.drop-down
		@apply relative 
		@media screen and ( max-width: 1279.1px)
			@apply mb-3
		&.active,&:hover,&[class*="current-"]
			.title
				> a
					@screen xl
						@apply text-primary-1D4789
		&:hover
			@screen xl
				.mega-menu-wrap
					@apply opacity-100 pointer-events-auto
		.headline
			@apply uppercase
		.mega-menu-wrap
			@screen xl
				@apply absolute left-0  h-auto  bg-white opacity-0 pointer-events-none 
				width: max-content
				top: 100%
				border-top: 1px solid #CCCCCC
			@media screen and ( max-width: 1279.1px)
				@apply hidden pl-6
			.title
				a
					@apply w-full 
					text-transform: inherit !important
					font-weight: 400 !important
				em
					@apply hidden
			li
				&:hover
					.mega-menu-wrap
						@apply opacity-100 pointer-events-auto
			.mega-menu-wrap
				left: 100%
				top: 0
				border-top: 0
				@apply opacity-0 pointer-events-none
			a
				border-bottom: 1px solid #E5E5E5
				@apply start-item  py-2 pr-12 items-center text-sm
				height: 40px
				@screen xl
					height: 50px
					@apply px-5
					&:hover
							@apply text-primary-ED202E
				@media screen and ( max-width: 1279.1px)
					@apply text-white text-left
	.drop-mega
		&.active,&:hover,&[class*="current-"]
			.title
				> a
					@screen xl
						@apply text-primary-1D4789
		&:hover
			@screen xl
				.mega-menu-wrap
						@apply opacity-100 pointer-events-auto
		.headline
			@apply uppercase
		.mega-menu-wrap
			@screen xl
				@apply fixed left-0 bottom-0 w-full  bg-white py-8 opacity-0 transition pointer-events-none
				border-top: 1px solid #CCCCCC
				border-bottom: 1px solid #CCCCCC
				top:  var(--header-height)
				height: fit-content
			@media screen and ( max-width: 1279.1px)
				@apply hidden pt-4
				ul
					@apply pl-4
			.container
				@apply flex
				@media screen and ( max-width: 1279.1px)
					@apply flex-col p-0
				>ul
					@apply w-full flex flex-wrap
					justify-content: flex-start
					>li
						@apply w-full
						@media screen and ( max-width: 1280px)
							@apply mb-4
						@screen xl
							width: 33.333333%
		
			.row
				@media screen and ( max-width: 1279.1px)
					@apply justify-start py-6
					.col
						@apply mb-5
			.nav-col-menu
				li
					@apply mb-2
					&:hover
						a
							@apply text-primary-ED202E
					a
						@apply text-white
						font-size: r(16px)
						@screen xl
							@apply text-333333
			.img-wrap
				margin-top: auto
				@media screen and ( max-width: 1279.1px)
					@apply hidden
				a
					@apply center-item
					height: 280px
					width: auto
					img
						width: 100%
						height: 100%
						object-fit: contain
			.headline
				@apply mb-3 text-white normal-case
				font-size: r(18px)
				a
					@apply text-white
					font-size: r(18px)
				@screen xl
					font-size: r(20px)
					@apply mb-4 text-primary-1D4789
					a
						font-size: r(20px)
						@apply mb-4 text-primary-1D4789
		
main
	margin-top: var(--header-height)
