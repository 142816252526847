.news-display
	.block-title
		@apply uppercase
	.top-zone-nav
		@apply mb-8
		@screen xl
			@apply mb-10
		@media screen and ( max-width: 576px)
			@apply overflow-x-auto
			ul
				@apply whitespace-nowrap justify-start
	.swiper-pagination
		@media screen and ( max-width: 768px)
			position: static !important
			@apply mt-5
		@screen md
			bottom: r(50px) !important
			right: r(150px) !important
			width: fit-content !important
			left: unset !important
	.swiper-pagination-bullet 
		background: #ccc !important
		border-radius: 0 !important
		width: 10px !important
		height: 5px !important
		&.swiper-pagination-bullet-active
			background: $primary !important
			width: 50px !important
	.news-wrap-bottom
		@apply mt-[30px]
		.news-figure
			&:not(:last-child)
				@apply mb-[30px]
				@media screen and ( max-width: 576px)
					@apply mb-[20px]
.most-view-wrap 
	.sub-title
		@apply text-333333 font-bold uppercase pb-3 
		border-bottom: 1px solid #E5E5E5
		font-family: 'UTM Avo'
		font-size: r(30px)
		@media screen and ( max-width: 576px)
			@apply mb-8	
	.news-figure-side
		@media screen and ( max-width: 576px)
			@apply flex bg-background-F5F5F5 overflow-hidden  mb-8
			flex-direction: column
			border-radius: 10px
			padding: 10px
			.img-src
				flex: 1 0 auto
				@apply w-full
				a
					@apply w-full
					+img-ratio(70/120)
			.txt
				@apply w-full
				flex: 0 1 auto
				padding: r(15px) 0 r(10px)
			time
				@apply mb-2 text-[14px]
			.headline
				@apply text-222222 font-bold
				font-size: r(20px)
				+line(2)
				*
					@apply text-222222 font-bold
					font-size: r(20px)
					+line(2)
				&:hover
					@apply text-primary-ED202E
					*
						@apply text-primary-ED202E
			.caption
				@apply  text-666666 mt-3
				font-size: r(14px)
				+line(2)
				*
					@apply  text-666666

.news-figure-side
	@apply flex py-4 items-center
	border-bottom: 1px solid #E5E5E5
	@screen xl
		@apply py-5
	.img-src
		a
			@apply center-item overflow-hidden
			height: 70px
			width: 120px
			min-width: 120px
			border-radius: 5px
			img
				width: 100%
				height: 100%
				object-fit: cover
	.headline
		a
			font-size: r(14px)
			@apply font-normal leading-normal inline-block
			&:hover
				@apply text-primary-ED202E
	.txt
		@apply ml-3
	time
		font-size: r(12px)
.news-figure-big
	@apply bg-background-F5F5F5 overflow-hidden flex
	border-radius: 10px
	padding: 10px
	@media screen and ( max-width: 768px)
		@apply col-item
	.img-src
		@apply  overflow-hidden w-full
		border-radius: 10px
		@screen md
			width: r(515px)
			min-width: r(515px)
		a
			@media screen and ( max-width: 1280px)
				+img-ratio(290/515)
			@screen xl
				@apply center-item
				height: r(290px)
				width: auto
				img
					width: 100%
					height: 100%
					object-fit: cover
	.txt
		@apply py-6
		@screen md
			@apply p-4 px-6
		@screen xl
			padding: r(10px) r(25px) r(20px) r(30px)
	time
		@apply mb-2
	.headline
		line-height: 1.4
		@apply text-222222 line-clamp-4
		*
			@apply text-222222
		&:hover
			@apply text-primary-ED202E
			*
				@apply text-primary-ED202E
	.caption
		@apply  text-666666 mt-3
		font-size: r(14px)
		+line(3)
		*
			@apply  text-666666
			font-size: r(14px)
.news-figure
	@apply bg-background-F5F5F5 overflow-hidden flex
	border-radius: 10px
	padding: 10px
	@media screen and ( max-width: 576px)
		@apply col-item
	.img-src
		@apply  overflow-hidden
		border-radius: 10px
		@media screen and ( max-width: 576px)
			@apply w-full
		@screen sm
			width: r(370px)
			min-width: r(370px)
		a
			@media screen and ( max-width: 1280px)
				+img-ratio(210/380)
			@screen xl
				@apply center-item
				height: r(210px)
				width: auto
				img
					width: 100%
					height: 100%
					object-fit: cover
	.txt
		@apply p-4
		@screen xl
			padding: r(10px) r(25px) r(20px) r(30px)
	time
		@apply mb-2
	.headline
		line-height: 1.4
		@apply text-222222
		+line(2)
		*
			@apply text-222222
			+line(2)
		&:hover
			@apply text-primary-ED202E
			*
				@apply text-primary-ED202E
	.caption
		@apply  text-666666 mt-3
		font-size: r(14px)
		+line(2)
		*
			@apply  text-666666
			font-size: r(14px)
