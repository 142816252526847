.sticky-nav
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05)
	@apply bg-white
	border-bottom: 1px slid #e5e5e5
	ul
		@apply gap-item pl-0 list-none
	li
		@apply px-4
		@media screen and ( min-width: 1280px)
			@apply px-1
		@media screen and ( min-width: 1440px)
			@apply px-4
		@media screen and ( min-width: 1600px)
			@apply px-6
		&.active
			a
				@apply text-primary-ED202E
				&::after
					@apply opacity-100
	a
		@apply  text-666666 relative center-item relative font-bold uppercase
		height: 50px
		font-size: r(16px)
		&::after
			content: ''
			@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full bg-primary-ED202E opacity-0
			height: 2px
	.container
		@media screen and ( max-width: 1024px)
			@apply overflow-x-auto
			ul
				@apply whitespace-nowrap justify-start
.about-section-wrapper
	.block-title
		@apply uppercase
.about-us
	@apply relative py-14
	@screen xl
		padding: r(80px) 0 r(100px) 
	.row
		@apply justify-between
	&::after
		content: ''
		@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-full opacity-20
		background: linear-gradient(180deg, rgba(29, 71, 137, 0) 0%, #1D4789 100%)
	.caption
		strong
			@apply text-primary-1D4789
	.wrap-left
		@apply mt-8
		@screen xl
			margin-top: r(70px)
	.img-src
		@apply relative
		a
			+img-ratio(400/600)
		&::after
			content: ''
			@apply block absolute pointer-events-none left-1/2 top-1/2 translate-50 z-10  transition
			height: r(90px)
			width: r(90px)
			background: url('../img/icon/play.png')
			background-repeat: no-repeat
			background-size: contain
			background-position: center
	.block-title
		@media screen and ( max-width: 576px)
			@apply text-center
	.wrap-left
		@media screen and ( max-width: 576px)
			@apply justify-center	
.about-vision
	.img-src
		a
			@apply center-item
			height: r(590px)
			width: auto
			img
				width: 100%
				height: 100%
				object-fit: cover
	.item
		@apply relative
		padding: r(50px) r(40px)
		&::after
			content: ''
			@apply block absolute pointer-events-none right-0 bottom-0 z-10  transition
			height: r(220px)
			width: r(290px)
			background: url('../img/icon/icon-2.png')
			background-repeat: no-repeat
			background-size: 100% 100%
			background-position: center
		@screen lg
			height: r(280px) 
		&:first-child
			@apply bg-primary-ED202E 
			margin-bottom: 30px
		&:nth-child(2)
			@apply bg-primary-1D4789
.about-core
	@apply pb-16
	.icon
		@apply center-item mb-4
		height: 60px
		width: auto
		min-width: 60px
		@screen lg 
			@apply mb-0
		img
			width: 100%
			height: 100%
			object-fit: contain
	.txt-wrap
		@apply flex-wrap
	.img-wrap 
		a
			@apply center-item
			height: r(505px)
			width: auto
			img
				width: 100%
				height: 100%
				object-fit: contain
	.item
		@apply flex col-item mb-6 w-1/2
		@screen lg
			@apply flex-row  mb-0 w-full
		
		.headline
			@apply mb-1
			font-size: r(26px)
		.txt
			@apply mr-5
			order: 2
			@screen lg
				order: 1 
	.col
		@apply p-0
		&:first-child
			@screen lg
				.txt-wrap
					@apply col-item  h-full
					justify-content: space-between
					padding: r(80px) 0
			.item
				@apply text-center
				@screen lg
					@apply text-right flex-row-reverse
				&:first-child
					.headline
						@apply text-primary-1D4789
				&:nth-child(2)
					.headline
						@apply text-primary-ED202E
		&:last-child
			@screen lg
				.txt-wrap
					@apply col-item  h-full
					justify-content: space-between
					padding: r(80px) 0
			.item
				@apply text-center
				@screen lg
					@apply    text-left
				.txt
					@apply ml-5 mr-0
				&:first-child
					.headline
						color: #E0A00A
				&:nth-child(2)
					.headline
						color: #006F76
.about-history
	background: url('../img/bg/about-bg.jpg')
	background-repeat: no-repeat
	background-size: cover
	background-position: center
	.img-src
		a
			+img-ratio(526/1920)
	.swiper-history-year
		@apply relative mx-auto mt-10
		width: r(200px)
		&::after
			content: ''
			@apply block absolute pointer-events-none left-1/2 translate-x-50  z-10  transition 
			height: 2px
			background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000000 51.56%, rgba(0, 0, 0, 0) 100%)
			width: 375px
			bottom: -15px
		.years
			font-size: r(60px)
			font-family: 'UTM Avo'
			@apply font-bold text-primary-ED202E leading-none text-center
			@screen xl
				font-size: r(80px)
		.swiper-nav-wrap
			padding: 0 !important
		.nav-arrow-prev,.nav-arrow-next
			height: 60px
			line-height: 60px
			position: absolute !important
			top: 50% !important
			transform: translateY(-50%)
			em
				font-size: r(80px)
				@apply leading-none font-black text-background-CCC
	.swiper-history-txt
		@apply mt-8 text-center
		@screen xl
			@apply mt-9
		.caption
			@apply mt-2
	.swiper-history-pagination
		@apply mt-8
		@screen xl
			@apply mt-10
		.txt
			@apply text-999999 font-bold uppercase text-center pb-2
		.swiper-slide
			@apply relative
			&::after
				content: ''
				@apply block absolute pointer-events-none left-1/2 translate-x-50 bottom-0 z-10  transition opacity-0
				height: 8px
				width: 57px
				background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.83) 0%, rgba(0, 0, 0, 0.1) 55.73%, rgba(0, 0, 0, 0) 100%)
			&.swiper-slide-thumb-active
				&::after
					@apply opacity-100
				.txt
					@apply text-primary-ED202E
.about-produce
	background: url('../img/bg/about-bg-2.jpg')
	background-repeat: no-repeat
	background-size: cover
	background-position: center
	.block-title
		@screen lg
			@apply text-left 
	.caption
		p
			@apply my-3
	ul
		list-style-type: disc
		@apply pl-5
	li	
		@apply mb-2
		display: list-item
		em
			@apply inline-block mr-2 text-primary-1D4789 text-[14px]
			
			height: 14px
			line-height: 14px
		span
			@apply text-[16px] text-primary-1D4789 underline