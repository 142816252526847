.catalogue-list
	.row
		@apply justify-start
.catalogue-item 
	@apply bg-background-F5F5F5 h-full relative
	.img-src
		a
			+img-ratio(400/285,contain)
	.txt
		@apply  px-5 py-4 start-item
	.headline
		a
			@apply text-primary-1D4789
			&:hover
				@apply text-primary-ED202E
	.text-0
		@apply absolute top-0 left-0 right-0  w-full h-full  z-40 opacity-0 pointer-events-auto
		a
			@apply block w-full h-full